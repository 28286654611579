import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import red from "@material-ui/core/colors/red";
const styles = theme => {
  return {
    content: {
      padding: theme.spacing(2),
      "&.alignCenter": {
        textAlign: "center"
      }
    },
    button: {
      width: "100%",
      minHeight: theme.spacing(6),
      borderRadius: 0,
      "&.red": {
        background: red[100]
      }
    },
    title: {
      "&.alignCenter": {
        textAlign: "center"
      }
    }
  };
};

const id = uniqid();
const Diag = props => {
  const {
    classes,
    onClose,
    onSave,
    title,
    content,
    saveBtnTitle,
    alignCenter
  } = props;
  return (
    <Dialog open={true} onClose={onClose} aria-labelledby={id}>
      <DialogTitle
        className={classNames(
          classes.title,
          alignCenter ? "alignCenter" : null
        )}
        id={id}
      >
        {title}
      </DialogTitle>
      <div
        className={classNames(
          classes.content,
          alignCenter ? "alignCenter" : null
        )}
      >
        {content}
      </div>
      {typeof onSave === "function" ? (
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={onSave}
              color="primary"
            >
              {saveBtnTitle || "Salvar"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classNames(classes.button, "red")}
              onClick={onClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Dialog>
  );
};

Diag.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.node,
  onSave: PropTypes.func,
  saveBtnTitle: PropTypes.string,
  alignCenter: PropTypes.bool
};

export default withStyles(styles)(Diag);

import React from "react";
//import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import { DIARY as DIARY_APP } from "../../constants/routes";
import { DIARY_APP_TITLE } from "../../constants/views";
import ptLocale from "date-fns/locale/pt-BR";
import formatDistance from "date-fns/formatDistance";
import AppCard from "./AppCard";
import PropTypes from "prop-types";
const options = {
  locale: ptLocale
};

const styles = theme => ({
  button: {
    padding: `${0} ${theme.spacing(1)}px`,
    flex: 1
  },
  editIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(1)
  }
});

const parseCardContent = (classes, diaryApp) => {
  if (diaryApp.length) {
    return (
      <React.Fragment>
        <p>
          Última entrada feita a{" "}
          {formatDistance(diaryApp[0].createdAt.toDate(), new Date(), options)}.
        </p>
        <p>Seu diário tem um total de {diaryApp.length} entradas.</p>
      </React.Fragment>
    );
  } else {
    return <p>Você ainda não tem entradas no diário.</p>;
  }
};

function DiaryAppCard(props) {
  const { classes, diaryApp, tour } = props;
  return (
    <AppCard
      title={DIARY_APP_TITLE}
      icon={<ImportContactsIcon />}
      content={parseCardContent(classes, diaryApp)}
      subContent="clique para ver mais"
      appRoute={DIARY_APP}
      tour={tour}
    />
  );
}

DiaryAppCard.propTypes = {
  tour: PropTypes.string
};
function mapStateToProps({ diaryApp }) {
  return { diaryApp };
}

export default connect(mapStateToProps)(withStyles(styles)(DiaryAppCard));

export default function(props) {
  return new Date(
    props[0],
    props[1] - 1,
    props[2],
    props[3],
    props[4],
    props[5]
  );
}

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";

import { db } from "../../firebase";
import { connect } from "react-redux";

import { OVERVIEW_APP_TITLE } from "../../constants/views";
import { DASHBOARD } from "../../constants/routes";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

import deepOrange from "@material-ui/core/colors/deepOrange";
import lightBlue from "@material-ui/core/colors/lightBlue";
import lightGreen from "@material-ui/core/colors/lightGreen";
import DefaultTabs from "../UI/Tabs/Default";
import BaseApp from "./BaseApp";
import Tour from "../UI/Tour/Tour";
import StepContent from "../UI/Tour/StepContent";
import { usersDb } from "../../constants/db";
import {
  FEEDBACK_BTN_TOUR,
  OVERVIEW_APP_TABS_TOUR
} from "../../constants/tours";
import FeedbackTourStepContent from "../UI/Tour/Content/Feedback";
import AreaChart from "../UI/AreaChart/AreaChart";
const styles = theme => {
  return {
    overviewContainer: {
      background: theme.palette.common.white,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    value: {
      margin: 0,
      fontSize: 15,
      lineHeight: ".8em",
      fontWeight: 500,
      marginBottom: 2,
      color: theme.palette.common.white
    },
    unit: {
      fontSize: 12,
      color: theme.palette.grey[700],
      fontWeight: 500
    },
    indicator: {
      borderRadius: 0,
      flexDirection: "column",
      "&.medIndicator": {
        background: lightGreen[300]
      },
      "&.maxIndicator": {
        background: deepOrange[300]
      },
      "&.minIndicator": {
        background: lightBlue[300]
      }
    },
    indicatorName: {
      fontSize: 12,

      color: theme.palette.grey[600]
    },
    indicatorNameSub: {
      fontWeight: 500,
      fontSize: 10,
      textTransform: "uppercase"
    },
    listContainer: {
      display: "flex"
    },
    noData: {
      padding: theme.spacing(4)
    }
  };
};

class OverviewApp extends Component {
  //ruleset and rules should be on a global redux state so that we dont have to fetch the rules list everytime controleapp is mounted after we edit a rule.
  state = { currentTab: 0 };
  componentDidMount() {
    if (this.props.user && this.props.user.tours) {
      const { user } = this.props;

      if (!user.tours.overview) {
        //user has not completed tours.dashboard
        this.setState({ tour: true });
      }
    }
  }
  componentDidUpdate() {
    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (!user.tours.overview) {
          //user has not completed tours.dashboard
          this.setState({ tour: true });
        }
      }
    }
  }
  handleTourClose = isFinished => {
    if (isFinished) {
      db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            overview: true
          }
        },
        usersDb
      );
      this.setState({ tour: false, tourCanceled: true });
    } else {
      this.setState({ tour: false, tourCanceled: true });
    }
  };
  parseAnalyticsList = (type, period) => {
    const { analytics, classes } = this.props;
    if (type === "temp") {
      return (
        <React.Fragment>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Temperatura
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Mínima
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "minIndicator")}>
              <p className={classes.value}>{analytics[period].data.minTemp}</p>
              <small className={classes.unit}>graus</small>
            </Avatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Temperatura
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Média
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "medIndicator")}>
              <p className={classes.value}>{analytics[period].data.medTemp}</p>
              <small className={classes.unit}>graus</small>
            </Avatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Temperatura
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Máxima
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "maxIndicator")}>
              <p className={classes.value}>{analytics[period].data.maxTemp}</p>
              <small className={classes.unit}>graus</small>
            </Avatar>
          </ListItem>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Umidade
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Mínima
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "minIndicator")}>
              <p className={classes.value}>{analytics[period].data.minHum}</p>{" "}
              <small className={classes.unit}>%</small>
            </Avatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Umidade
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Média
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "medIndicator")}>
              <p className={classes.value}>{analytics[period].data.medHum}</p>
              <small className={classes.unit}>%</small>
            </Avatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.indicatorName}>
                  Umidade
                </Typography>
              }
              secondary={
                <Typography className={classes.indicatorNameSub}>
                  Máxima
                </Typography>
              }
            />
            <Avatar className={classNames(classes.indicator, "maxIndicator")}>
              <p className={classes.value}>{analytics[period].data.maxHum}</p>
              <small className={classes.unit}>%</small>
            </Avatar>
          </ListItem>
        </React.Fragment>
      );
    }
  };
  handleTabChange = (event, currentTab) => {
    this.setState({ currentTab });
  };

  render() {
    const { classes, analytics } = this.props;
    const oneDayTab =
      typeof analytics === "object" &&
      analytics.daily &&
      analytics.daily.length ? (
        <div key="day" className={classes.listContainer}>
          <Grid item xs={12}>
            <AreaChart data={analytics.daily} />
          </Grid>
        </div>
      ) : (
        <Typography className={classes.noData} key="day">
          Você não tem dados ainda, volte amanha para ver suas estatísticas.
        </Typography>
      );
    const oneWeekTab =
      typeof analytics === "object" &&
      analytics.weekly &&
      analytics.weekly.length ? (
        <div key="week" className={classes.listContainer}>
          <Grid item xs={12}>
            <AreaChart
              data={analytics.weekly}
              xLabel="Dia"
              dateFormat="dd/MM"
            />
          </Grid>
        </div>
      ) : (
        <Typography className={classes.noData} key="week">
          Você não tem dados ainda, volte amanha para ver suas estatísticas.
        </Typography>
      );
    const steps = [
      {
        selector: `[data-tour=${OVERVIEW_APP_TABS_TOUR}]`,
        content: (
          <StepContent title={OVERVIEW_APP_TITLE}>
            <Typography component="p">
              Tenha uma visão geral das <span>condições</span> dentro do seu
              cultivo.
            </Typography>
            <Typography component="p">
              Aqui você tem acesso a estatísticas <span>diárias</span> e{" "}
              <span>semanais.</span>
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${FEEDBACK_BTN_TOUR}]`,
        content: <FeedbackTourStepContent />
      }
    ];
    return (
      <BaseApp title={OVERVIEW_APP_TITLE} backBtnRoute={DASHBOARD}>
        <Grid item container className={classes.overviewContainer} xs={12}>
          {this.state.tour ? (
            <Tour steps={steps} onRequestClose={this.handleTourClose} />
          ) : null}
          <DefaultTabs
            labels={["24 horas", "7 dias"]}
            tabsContent={[oneDayTab, oneWeekTab]}
            handleTabChange={this.handleTabChange}
            currentTab={this.state.currentTab}
            tour={OVERVIEW_APP_TABS_TOUR}
          />
        </Grid>
      </BaseApp>
    );
  }
}
function mapStateToProps({ user, analytics }) {
  //dev todo better consider the use of device_id in the UI
  return { user, analytics };
}
export default connect(mapStateToProps)(withStyles(styles)(OverviewApp));

import { AUTH_USER, MESSAGING_UPDATE } from "../actions/types";
export default function(state = null, action) {
  switch (action.type) {
    case AUTH_USER:
      return action.payload.messaging || state;
    case MESSAGING_UPDATE:
      return action.payload;
    default:
      return state;
  }
}

export const controls = {
  rules: {
    port1: {
      name: "Porta 1",
      type: "manual",
      color: "purple",
      manual: {
        mode: true
      },
      auto: {
        mode: false,
        time: {
          start: "",
          stop: ""
        },
        sensor: {
          temp: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          },
          hum: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          }
        }
      }
    },
    port2: {
      name: "Porta 2",
      type: "manual",
      color: "cyan",
      manual: {
        mode: true
      },
      auto: {
        mode: false,
        time: {
          start: "",
          stop: ""
        },
        sensor: {
          temp: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          },
          hum: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          }
        }
      }
    },
    port3: {
      name: "Porta 3",
      type: "manual",
      color: "pink",
      manual: {
        mode: true
      },
      auto: {
        mode: false,
        time: {
          start: "",
          stop: ""
        },
        sensor: {
          temp: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          },
          hum: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          }
        }
      }
    },
    port4: {
      name: "Porta 4",
      type: "manual",
      color: "orange",
      manual: {
        mode: true
      },
      auto: {
        mode: false,
        time: {
          start: "",
          stop: ""
        },
        sensor: {
          temp: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          },
          hum: {
            active: false,
            value: 0,
            comparator: "",
            action: false
          }
        }
      }
    }
  },
  parsed: ""
};
export const grow = {
  carbonFilter: false,
  co2: false,
  lightSource: "",
  dimensions: {
    height: "",
    width: "",
    length: ""
  },
  exhaustFan: false,
  oscilatorFan: false,
  airConditioner: false,
  humidifier: false,
  growMedium: "",
  plants: ""
};
export const reminder = user_id => ({
  info: "",
  tags: [],
  mode: true,
  oneTimeMode: {
    datetime: new Date()
  },
  recurringMode: {
    time: new Date(),
    frequency: null
  },
  user_id
});
export const note = user_id => ({
  note: "",
  icon: "",
  color: "",
  user_id
});

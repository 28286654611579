import { storage } from "./firebase";
import { DIARY_APP_STORAGE } from "../constants/storage";
import { UNIQID_SIZE } from "../constants/misc";

import { compressImage } from "../components/Helpers/compressImage";
export const doStoreFilesForDiaryEntry = async (files, entry, userId) => {
  //root reference

  const rootRef = storage.ref();

  const tasks = [];
  //gets array of files
  for (const file of files) {
    if (typeof file === "object") {
      const uploadRefName = `${DIARY_APP_STORAGE}/${userId}/${entry}/${(
        Math.random() * 10
      )
        .toString()
        .replace(".", "")
        .substring(0, UNIQID_SIZE)}.jpg`;
      const userUploadRef = rootRef.child(uploadRefName);

      const compressedImage = await compressImage(file);
      var metadata = {
        cacheControl: "private,max-age=31536000",
        contentType: "image/jpeg"
      };

      const uploadTask = userUploadRef.put(compressedImage, metadata);
      tasks.push(uploadTask);
    }
  }

  //for each create a ref, store it
  try {
    await Promise.all(tasks);
    const urlTasks = [];
    tasks.forEach(task => {
      urlTasks.push(task.snapshot.ref.getDownloadURL());
    });

    return Promise.all(urlTasks);
  } catch (err) {
    return false;
  }
};

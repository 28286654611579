import React, { Component } from "react";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Dropzone from "react-dropzone";

import LoadingView from "../../Helpers/LoadingView";
import BaseApp from "../BaseApp";

import TextArea from "../../Forms/TextArea/TextArea";

import Tour from "../../UI/Tour/Tour";
import StepContent from "../../UI/Tour/StepContent";
import { diaryAppTours, ADD_SAVE_FAB_BTN } from "../../../constants/tours";

const styles = theme => {
  return {
    root: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    noteField: {
      marginBottom: theme.spacing(4)
    },
    chip: {
      marginBottom: theme.spacing(2)
    },
    dropzone: {},
    dropzoneImageContent: { justifyContent: "center" },
    dropzoneContent: {},
    dropzoneThumb: { maxWidth: "100%" },
    dropzoneDropArea: {
      padding: theme.spacing(2),
      borderRadius: 5,
      border: `2px ${theme.palette.primary.main} dashed`,
      marginBottom: theme.spacing(1),
      "&.error": {
        borderColor: theme.palette.error.main
      },
      "& small": {
        textAlign: "center",
        display: "block"
      }
    },
    dropzoneDropAreaText: {
      textAlign: "center"
    }
  };
};

//dev todo better way to set this away from UI?
const MAX_IMAGES = 4;
class AddEditEntry extends Component {
  state =
    this.props.mode === "editing"
      ? { ...this.props.entryData, error: {}, thumbs: [] }
      : {
          note: "",
          images: [],
          error: {},
          thumbs: []
        };

  //mode true == recurringMode
  //dev todo maybe change to string property?

  handleFormChange = note => {
    if (note.trim()) {
      this.setState({ note });
    }
  };
  handleDelete = () => {
    this.setState({ deleting: true });
    this.props.handlers.handleDelete(() => {
      //should not come to this.
      this.updateError("delete");
    });
  };
  handleSave = () => {
    //we are creating a new one
    const { note, images, id } = this.state;

    if (note.length > 3 || images.length > 0) {
      //save
      this.setState({ saving: true });
      const entry = { note, images, id };
      this.props.handlers.handleSave(entry, () => {
        this.updateError("save");
      });
    } else {
      this.updateError("content");
    }
  };

  updateError = field => {
    this.setState(prevState => ({
      ...prevState.error,
      error: {
        [field]: true
      }
    }));
  };
  onDrop = files => {
    if (this.state.images.length < MAX_IMAGES) {
      const maxImages =
        this.props.mode === "editing"
          ? MAX_IMAGES - this.props.entryData.images.length
          : MAX_IMAGES;
      const images = files.slice(0, maxImages);

      this.setState({
        images,
        thumbs: images.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      });
    }
  };
  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    if (this.state.thumbs.length)
      this.state.thumbs.forEach(f => URL.revokeObjectURL(f.preview));
  }
  render() {
    if (this.state.deleting) {
      return <LoadingView absolute tooltip="Deletando" />;
    }
    if (this.state.saving) {
      return <LoadingView absolute tooltip="Salvando" />;
    }

    const { classes, title, mode, handlers, handleTourClose } = this.props;
    const maxImages =
      this.props.mode === "editing"
        ? MAX_IMAGES - this.props.entryData.images.length
        : MAX_IMAGES;

    //dev todo movo dropzone to its own component
    const dropzoneContent = this.state.images.length ? (
      <Grid container className={classes.dropzoneImageContent}>
        <Grid className={classes.dropzoneDropArea} item>
          <Typography className={classes.dropzoneDropAreaText}>
            Clique para adicionar imagens
          </Typography>
          <Typography>
            {maxImages - this.state.images.length > 0 ? (
              <small>{`Você ainda pode selecionar mais ${maxImages -
                this.state.thumbs.length} imagens`}</small>
            ) : (
              <small>{`Você já selecionou ${
                maxImages === 0 ? "todas" : maxImages
              } imagens!`}</small>
            )}
          </Typography>
        </Grid>
        <Grid container item className={classes.previews}>
          {this.state.thumbs.map((thumb, i) => {
            return (
              <Grid key={i} item xs={4}>
                <img
                  className={classes.dropzoneThumb}
                  src={thumb.preview}
                  alt="Preview das imagens"
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    ) : (
      <Grid
        className={classNames(
          classes.dropzoneDropArea,
          this.state.error.content ? "error" : null
        )}
        item
      >
        <Typography className={classes.dropzoneDropAreaText}>
          Clique para adicionar imagens
        </Typography>
        <Typography>
          <small>{`Você pode selecionar até ${maxImages} imagens`}</small>
          <small>.jpg ou .png</small>
        </Typography>
      </Grid>
    );
    const steps = [
      {
        selector: `[data-tour=${diaryAppTours.DIARY_ADD_NOTE}]`,
        content: (
          <StepContent title="Nota">
            <Typography component="p">
              O texto da entrada a ser feita ou editada no diário.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${diaryAppTours.DIARY_ADD_PHOTO}]`,
        content: (
          <StepContent title="Fotos">
            <Typography component="p">
              Clique aqui para selecionar ou tirar fotos para anexar a essa
              nota.
            </Typography>
            <Typography component="p">
              Selecione todas fotos que gostaria de adicionar de uma só vez.
            </Typography>
            <Typography component="p">
              Você pode mandar um total de <span>4 fotos</span>.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${ADD_SAVE_FAB_BTN}]`,
        content: (
          <StepContent title="Salvar Nota">
            <Typography component="p">
              Após criar ou editar sua nota e fotos e você precisa{" "}
              <span>salvar o diário</span> clicando nesse botão.
            </Typography>
            <Typography component="p">
              <span>
                Caso esteja editando uma nota, um botão vermelho aparecerá para
                que você possa deletar a nota também.
              </span>
            </Typography>
          </StepContent>
        )
      }
    ];
    return (
      <BaseApp
        title={title}
        onSave={this.handleSave}
        onDelete={mode === "editing" ? this.handleDelete : null}
        backBtnRoute={handlers.handleBackBtn}
        withTour
        bg
      >
        <div className={classes.root}>
          {typeof handleTourClose === "function" ? (
            <Tour
              steps={steps}
              onRequestClose={isFinished =>
                isFinished ? handleTourClose(true, true) : handleTourClose()
              }
            />
          ) : null}
          <TextArea
            rows={8}
            tooltip="O que gostaria de anotar?"
            onChange={e => this.handleFormChange(e.target.value, "note")}
            value={this.state.note}
            label="Nota"
            error={this.state.error.content ? true : null}
            className={classes.noteField}
            tour={
              typeof handleTourClose === "function"
                ? diaryAppTours.DIARY_ADD_NOTE
                : null
            }
          />

          <Dropzone
            className={classes.dropzone}
            accept={["image/jpeg", "image/png"]}
            onDrop={this.onDrop}
            data-tour={
              typeof handleTourClose === "function"
                ? diaryAppTours.DIARY_ADD_PHOTO
                : null
            }
          >
            {dropzoneContent}
          </Dropzone>
        </div>
      </BaseApp>
    );
  }
}

export default withStyles(styles)(AddEditEntry);

import { TELEMETRY_UPDATE } from "../actions/types";
export default function (state = {}, action) {
  switch (action.type) {
    case TELEMETRY_UPDATE:
      const { lastState, pendingAccessories, deviceId, deviceType } =
        action.payload;
      if (lastState) {
        return {
          deviceId,
          deviceType,
          temp: lastState.temp,
          hum: lastState.hum,
          updatedAt: action.payload.updatedAt
            ? action.payload.updatedAt.toDate()
            : new Date(),
          fw_version: lastState.fw_version,
          pendingAccessories,
        };
      }

      return state;
    default:
      return state;
  }
}

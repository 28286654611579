import { db } from "./firebase";
import * as dbNames from "../constants/db";
import { controls, grow } from "./starterConfig";

export const doCreateUser = (id, email, deviceId) => {
  const batch = db.batch();
  batch.set(db.collection(dbNames.usersDb).doc(id), {
    user_id: id,
    email,
    deviceId: deviceId,
  });
  batch.set(db.collection(dbNames.controlsDb).doc(deviceId), {
    ...controls,
    user_id: id,
  });
  batch.set(db.collection(dbNames.growsDb).doc(id), grow);
  return batch.commit();
};
export const doFetchUser = (id) => {
  return db.collection("users").doc(id).get();
};

export const listenForUserUpdate = (onUpdate) => {
  db.collection(dbNames.usersDb)
    .doc(db.app.firebase_.auth().currentUser.uid)
    .onSnapshot((user) => onUpdate(user.data()));
};
export const listenForRemindersUpdate = (onUpdate) => {
  db.collection(dbNames.remindersDb)
    .where("user_id", "==", db.app.firebase_.auth().currentUser.uid)
    .orderBy("createdAt", "desc")
    .onSnapshot((reminders) =>
      onUpdate(reminders.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
};
export const listenForDeviceUpdate = (deviceId, onUpdate) => {
  db.collection(dbNames.telemetryDb)
    .doc(deviceId)
    .onSnapshot((lastState) => onUpdate(lastState.data()));
};
export const listenForMessagingUpdate = (onUpdate) => {
  db.collection(dbNames.messagingTokensDb)
    .doc(db.app.firebase_.auth().currentUser.uid)
    .onSnapshot((messagingTokens) => onUpdate(messagingTokens.data()));
};

export const listenForDiaryUpdate = (onUpdate) => {
  db.collection(dbNames.diaryappDb)
    .where("user_id", "==", db.app.firebase_.auth().currentUser.uid)
    .orderBy("createdAt", "desc")
    .onSnapshot((notes) =>
      onUpdate(notes.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );
};

export const listenForControlPresetsUpdate = (deviceId, onUpdate) => {
  db.collection(dbNames.controlPresetsDb)
    .doc(deviceId)
    .onSnapshot((controlPresets) => onUpdate(controlPresets.data()));
};

export const listenForAnalyticsUpdate = (deviceId, onUpdate) => {
  db.collection(dbNames.analyticsDb)
    .doc(deviceId)
    .onSnapshot((analyticsData) => {
      onUpdate(analyticsData.data());
    });
};

export const doCreateDocInCollection = (data, col, options) => {
  let docData = {
    ...data,
    user_id: db.app.firebase_.auth().currentUser.uid,
    createdAt: db.app.firebase_.firestore.FieldValue.serverTimestamp(),
  };
  if (options) {
    if (options.includeEmail) {
      docData.email = db.app.firebase_.auth().currentUser.email;
    }
  }
  return db.collection(col).add(docData);
};

//dev todo deprecate
export const doUpdateUserData = (data) => {
  return db
    .collection(dbNames.usersDb)
    .doc(db.app.firebase_.auth().currentUser.uid)
    .set(data, { merge: true });
};
export const doUpdateDocWithDataInCollection = (doc, data, col) => {
  return db.collection(col).doc(doc).set(data, { merge: true });
};

//these collections already have the userid as docs name
export const doUpdateUserDocInCollection = (data, col) => {
  return db
    .collection(col)
    .doc(db.app.firebase_.auth().currentUser.uid)
    .set(data, { merge: true });
};

export const doDeleteDocumentFromCollection = (doc, col) => {
  return db.collection(col).doc(doc).delete();
};

//dev todo : maybe we should accept even the local changes to bypass the high loading times we might get... but then, how can we be sure eveything was saved and if not, how to handle it?
export const doFetchUserDocInCollection = (col) => {
  return db
    .collection(col)
    .doc(db.app.firebase_.auth().currentUser.uid)
    .get()
    .then((colUserDoc) => colUserDoc.data())
    .catch((error) => {
      return;
    });
};

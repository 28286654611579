import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { FirebaseAuthContext } from "../../hocs/FirebaseAuthProvider";
import { LOGIN } from "../../constants/routes";
class PrivateRoute extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <FirebaseAuthContext.Consumer>
        {({ isUserSignedIn }) => {
          if (isUserSignedIn) {
            return <Route {...rest}>{children}</Route>;
          }

          if (this.props.location.pathname === this.props.path) {
            //if we are actually trying to navigate to this route and user is not signed in, redirect to /login.
            return <Redirect to={LOGIN} />;
          }
        }}
      </FirebaseAuthContext.Consumer>
    );
  }
}
export default withRouter(PrivateRoute);

import React from "react";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import CssBaseline from "@material-ui/core/CssBaseline";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import Dashboard from "./components/Views/Dashboard";
import ControlApp from "./components/Views/ControlApp/ControlApp";
import DiaryApp from "./components/Views/DiaryApp/DiaryApp";
import OverviewApp from "./components/Views/OverviewApp";
import LembretesApp from "./components/Views/LembretesApp";
import HomeLoginRegister from "./components/Views/HomeLoginRegister";
//auth HOC
import FirebaseAuthProvider from "./hocs/FirebaseAuthProvider";
import { connect } from "react-redux";
import { db } from "./firebase";
import * as actions from "./actions";
//routes consts
import * as routes from "./constants/routes";
import PrivateRoute from "./components/Route/Private";
import { BrowserRouter as Router, Route } from "react-router-dom";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#43a047",
    },
    secondary: {
      main: "#8bc34a",
    },
    background: {
      default: "#eee",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.80)",
    },
    common: {
      white: "#fafafa",
    },
    typography: {
      useNextVariants: true,
    },
  },
});
let listennersSet = false;
const App = (props) => {
  if (
    props.user &&
    props.user.deviceId &&
    props.user.deviceId !== "" &&
    !listennersSet
  ) {
    db.listenForRemindersUpdate(props.updateReminders);
    db.listenForDiaryUpdate(props.updateDiaryEntries);
    db.listenForDeviceUpdate(props.user.deviceId, props.updateTelemetry);
    db.listenForMessagingUpdate(props.updateMessaging);
    db.listenForAnalyticsUpdate(props.user.deviceId, props.updateAnalytics);
    db.listenForControlPresetsUpdate(
      props.user.deviceId,
      props.updateControlPresets
    );

    listennersSet = true;
  }
  return (
    <div style={{ maxWidth: "600px", width: "100%" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <MuiThemeProvider theme={theme}>
          <FirebaseAuthProvider>
            <CssBaseline />
            <Router>
              <React.Fragment>
                <Route
                  exact
                  path={routes.LOGIN}
                  component={HomeLoginRegister}
                />
                <PrivateRoute
                  exact
                  path={routes.DASHBOARD}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  path={routes.DASHBOARDFIX}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  path={routes.OVERVIEW_APP}
                  component={OverviewApp}
                />
                <PrivateRoute
                  exact
                  path={routes.CONTROLS}
                  component={ControlApp}
                />
                <PrivateRoute exact path={routes.DIARY} component={DiaryApp} />
                <PrivateRoute
                  exact
                  path={routes.REMINDERS}
                  component={LembretesApp}
                />
              </React.Fragment>
            </Router>
          </FirebaseAuthProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};
function mapStateToProps({ user }) {
  return { user };
}
export default connect(mapStateToProps, actions)(App);

import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import { db, functions } from "../../../firebase";
import { controlsDb, usersDb } from "../../../constants/db";
import { connect } from "react-redux";
import Diag from "../../UI/Diag/Diag";
import BaseApp from "../BaseApp";
import { CONTROLS_APP_TITLE } from "../../../constants/views";
import { DASHBOARD } from "../../../constants/routes";
import TextInput from "../../Forms/Input/Text";
import Tour from "../../UI/Tour/Tour";
import LoadingView from "../../Helpers/LoadingView";
import StepContent from "../../UI/Tour/StepContent";
import { FEEDBACK_BTN_TOUR, controlsAppTours } from "../../../constants/tours";
import FeedbackTourStepContent from "../../UI/Tour/Content/Feedback";
import RuleEditor from "./RuleEditor/RuleEditor";

import Grid from "@material-ui/core/Grid";
import RuleRow from "./RuleRow";

class ControleApp extends Component {
  //ruleset and rules should be on a global redux state so that we dont have to fetch the rules list everytime controleapp is mounted after we edit a rule.
  state = {
    selected: "",
    editing: false,
    tempRuleName: null
  };

  componentDidMount() {
    if (this.props.user && this.props.user.tours) {
      const { user } = this.props;

      if (!user.tours.controls) {
        //user has not completed tours.dashboard
        this.setState({ tour: true });
      }
    }
  }
  componentDidUpdate() {
    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.controls) {
          if (!user.tours.controls.main) {
            //user has not completed tours.dashboard
            this.setState({ tour: true });
          }
        }
      }
    }
    if (!this.state.editTour && !this.state.editTourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.controls) {
          if (!user.tours.controls.edit) {
            //user has not completed tours.dashboard
            this.setState({ editTour: true });
          }
        }
      }
    }
  }

  handleTourClose = async (isFinished, isEdit) => {
    if (isFinished && !isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            controls: {
              ...this.props.user.tours.controls,
              main: true
            }
          }
        },
        usersDb
      );
      return this.setState({ tour: false, tourCanceled: true });
    } else if (isFinished && isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            controls: {
              ...this.props.user.tours.controls,
              edit: true
            }
          }
        },
        usersDb
      );
      return this.setState({ editTour: false, editTourCanceled: true });
    }
    return this.setState({
      editTour: false,
      tour: false,
      tourCanceled: true,
      editTourCanceled: true
    });
  };
  //manual manual.mode: true === on false === off, type: true auto, false manual
  //will mount fetch data, set state
  handleSave = (rule, onError) => {
    db.doUpdateDocWithDataInCollection(
      this.props.deviceId,
      { rules: { [this.state.selected]: rule }, parsed: "" },
      controlsDb
    )
      .then(() => {
        this.setState({
          editing: false,
          selected: ""
        });
      })
      .catch(error => {
        onError(error);
      });
  };

  handleOnRuleSelected = ruleKey => {
    this.setState({ editing: true, selected: ruleKey });
  };
  handleBackBtn = () => {
    this.setState({ editing: false, selected: "" });
  };
  handleNameSelected = ruleKey => {
    this.setState({
      editingName: true,
      selected: ruleKey,
      tempRuleName: this.props.controlPresets[ruleKey].portName
    });
  };
  getChangeNameError = () => {
    if (
      (this.state.tempRuleName && this.state.tempRuleName.length > 20) ||
      this.state.changeNameError
    ) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { controlPresets, relayState } = this.props;
    //if user is editing present EditRule App with the selected rule
    if (this.state.editing) {
      return (
        <RuleEditor
          preset={controlPresets ? controlPresets[this.state.selected] : null}
          handleBackBtn={this.handleBackBtn}
        />
      );
    }
    const steps = [
      {
        selector: `[data-tour=${controlsAppTours.CONTROL_LIST_NAME}]`,
        content: (
          <StepContent title="Nome">
            <Typography component="p">
              Renomeie suas portas/conexões para melhor identificar os{" "}
              <span>aparelhos conectados</span> ao controlador.
            </Typography>
            <Typography component="p">
              <span>Para editar o nome basta clicar no nome da porta.</span>
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${controlsAppTours.CONTROL_LIST_STATUS}]`,
        content: (
          <StepContent title="Status">
            <Typography component="p">
              Saiba rapidamente se o aparelho conectado a porta esta{" "}
              <span style={{ color: "rgb(104, 179, 107)" }}>ligado</span> ou{" "}
              <span style={{ color: "#f15239" }}>desligado</span>.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${controlsAppTours.CONTROL_LIST_DESC}]`,
        content: (
          <StepContent title="Regra Ativa">
            <Typography component="p">
              Descrição da regra que a porta esta programada.
            </Typography>
            <Typography component="p">
              Pode ser uma regra <span>manual</span> (ligado ou desligado) ou{" "}
              <span>automática</span> que irá reagir de acordo com dados de{" "}
              <span>sensores, horário e tempo.</span>
            </Typography>
            <Typography component="p">
              <span>Para editar uma regra basta clicar na descrição.</span>
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${FEEDBACK_BTN_TOUR}]`,
        content: <FeedbackTourStepContent />
      }
    ];
    const parseRuleList = () => {
      const list = [];
      const whichRuleIndex = ruleKey => {
        let ruleNumber;
        switch (ruleKey) {
          case "port1":
            ruleNumber = 0;
            break;
          case "port2":
            ruleNumber = 1;
            break;
          case "port3":
            ruleNumber = 2;
            break;
          case "port4":
            ruleNumber = 3;
            break;
          default:
            break;
        }
        return ruleNumber;
      };

      for (let port in controlPresets) {
        if (port.indexOf("port") > -1) {
          const tempPortState = parseInt(relayState[whichRuleIndex(port)], 10);

          list.push(
            <RuleRow
              key={port}
              preset={controlPresets[port]}
              onPortNameEdit={this.handleNameSelected}
              onPortSelected={this.handleOnRuleSelected}
              name={port}
              portState={tempPortState}
            />
          );
        }
      }
      return list;
    };
    const editPortNameDiag = portName => {
      return (
        <Diag
          title="Alterar nome da regra"
          content={
            <React.Fragment>
              {this.state.savingName ? (
                <LoadingView tooltip="Alterando nome" absolute />
              ) : null}

              <TextInput
                label="Nome da Regra"
                handleChange={e => {
                  this.setState({
                    tempRuleName:
                      e.target.value.length > 20 ? portName : e.target.value
                  });
                }}
                error={this.getChangeNameError()}
                value={portName}
                endAdornment={20 - portName.length}
              />
            </React.Fragment>
          }
          onClose={() => this.setState({ editingName: false })}
          onSave={async () => {
            if (portName) {
              if (portName.length <= 20) {
                this.setState({ savingName: true });
                const update = {
                  options: {
                    [this.state.selected]: {
                      portName: portName.trim()
                    }
                  },
                  portKey: this.state.selected
                };

                try {
                  await functions.updateControlPreset(update);
                } catch (e) {
                  return;
                }
              } else {
                return this.setState({ changeNameError: true });
              }
            }
            return this.setState({
              tempRuleName: null,
              selected: "",
              savingName: false,
              editingName: false,
              changeNameError: false
            });
          }}
        />
      );
    };
    const ruleList = (
      <Grid container>
        <Grid item xs={12}>
          {parseRuleList()}
          {this.state.editingName
            ? editPortNameDiag(this.state.tempRuleName)
            : null}
        </Grid>
      </Grid>
    );
    return (
      <BaseApp
        title={CONTROLS_APP_TITLE}
        description=""
        backBtnRoute={DASHBOARD}
      >
        {this.state.tour ? (
          <Tour steps={steps} onRequestClose={this.handleTourClose} />
        ) : null}
        {ruleList}
      </BaseApp>
    );
  }
}
function mapStateToProps({ controlPresets, user, relayState }) {
  //dev todo better consider the use of device_id in the UI
  return { controlPresets, deviceId: user.deviceId, user, relayState };
}
export default connect(mapStateToProps)(ControleApp);

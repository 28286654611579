import React, { Component } from "react";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FeedbackPopover from "../../Helpers/FeedbackPopover";
import { FEEDBACK_BTN_TOUR } from "../../../constants/tours";
import tribeLogo from "../../../assets/tribe-logo.png";
const styles = theme => {
  return {
    viewTitle: {
      marginLeft: theme.spacing(2),
      color: theme.palette.common.white,
      flex: 1,
      fontSize: "1.3rem"
    },
    logoContainer: {
      height: 56,
      padding: "8px 16px",
      flexGrow: 1
    },
    logoImg: {
      maxWidth: "100%",
      maxHeight: "100%"
    },
    feedbackBtnContainer: {
      color: theme.palette.common.white,
      position: "relative"
    },
    toolbar: {
      width: "auto",
      margin: 0
    },
    "@media (min-width: 601px)": {
      toolbar: {
        width: 600,
        margin: "0 auto"
      }
    }
  };
};
class CustomAppBar extends Component {
  constructor(props) {
    super(props);
    this.presentFeedbackRef = React.createRef();
  }
  state = {
    loading: false,
    anchor: null
  };
  presentFeedback = () => {
    this.setState({
      feedbackOpen: true
    });
  };
  render() {
    const { children, title, classes, ...rest } = this.props;
    let viewTitleClass;
    if (title) {
      viewTitleClass = classes.viewTitle;
    } else {
      viewTitleClass = classNames(classes.viewTitle, "main");
    }
    return (
      <div>
        <AppBar {...rest} position="fixed">
          <Toolbar className={classes.toolbar}>
            {children}
            {title ? (
              <Typography component="h2" className={viewTitleClass}>
                {title}
              </Typography>
            ) : (
              <div className={classes.logoContainer}>
                <img
                  className={classes.logoImg}
                  src={tribeLogo}
                  alt="Tribe Aplicativo para Cultivadores"
                />
              </div>
            )}
            <div
              ref={this.presentFeedbackRef}
              data-tour={FEEDBACK_BTN_TOUR}
              className={classes.feedbackBtnContainer}
            >
              <IconButton onClick={this.presentFeedback} color="inherit">
                <RecordVoiceOver />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.state.feedbackOpen ? (
          <FeedbackPopover
            onDismiss={() => this.setState({ feedbackOpen: false })}
            anchor={this.presentFeedbackRef.current}
            screenTitle={title ? title : "home"}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(styles)(CustomAppBar);

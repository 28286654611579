import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
//import { photoperiodPreset } from "../../../../../../constants/controlPresets";
import TimePicker from "../../../../../Forms/Pickers/TimePicker";
import { Typography } from "@material-ui/core";
import TextInput from "../../../../../Forms/Input/Text";
import Diag from "../../../../../UI/Diag/Diag";
import LoadingView from "../../../../../Helpers/LoadingView";
import PropTypes from "prop-types";
import { controlPresetLabels, controlPresets } from "../../../../../../constants/controlPresets";
import { isEqual, format, addMinutes, subMinutes } from "date-fns";
import { functions } from "../../../../../../firebase";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      fontSize: theme.typography.h1.fontSize
    },
    input: {
      textAlign: "center"
    }
  };
});
function PresetEmerson(props) {
  //flower or veg
  const { preset, onClose, handleSave } = props;
  const classes = useStyles();
  const [minutesOfLight, setMinutesOfLight] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.EMERSON) {
        return preset.options.minutesOfLight;
      } else {
        return "";
      }
    } else {
      return "";
    }
  });
  const [startTime, setStartTime] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.EMERSON) {
        return new Date(preset.options.startTime);
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });
  const [stopTime, setStopTime] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.EMERSON) {
        return new Date(preset.options.stopTime);
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [presetError, setPresetError] = React.useState({});
  function onSave() {
    setIsSaving(true);

    if (minutesOfLight >= 1 && !isEqual(startTime, stopTime)) {
      const morningSchedule =
        format(subMinutes(startTime, minutesOfLight), "HHmmss") + format(startTime, "HHmmss");
      const sunsetSchedule =
        format(stopTime, "HHmmss") + format(addMinutes(stopTime, minutesOfLight), "HHmmss");

      functions
        .saveEmersonRule({
          portKey: preset.portKey,
          value: controlPresets.EMERSON,
          options: {
            startTime: startTime.toJSON(),
            stopTime: stopTime.toJSON(),
            minutesOfLight: minutesOfLight,
            morningSchedule,
            sunsetSchedule
          }
        })
        .then(results => {
          setIsSaving(false);
          handleSave();
        })
        .catch(e => {
          setIsSaving(false);
        });
    } else {
      if (minutesOfLight < 1) {
        setPresetError(perr => ({ error: true, where: "minutesOfLight" }));
        setIsSaving(false);
        return;
      }
      if (isEqual(startTime, stopTime)) {
        setPresetError(perr => ({ error: true, where: "startStopTime" }));
        setIsSaving(false);
      }
    }
  }
  const mainContent = (
    <Grid spacing={4} container>
      <Grid item xs={12}>
        <TextInput
          label="Quantos minutos de luz por período?"
          type="number"
          value={minutesOfLight}
          handleChange={e => setMinutesOfLight(e.target.value)}
          className={classes.textField}
          inputClasses={{ input: classes.input }}
          helper={
            minutesOfLight
              ? `Exemplo: ${minutesOfLight} minutos antes e ${minutesOfLight} minutos depois das luzes principais`
              : "Exemplo: 15 minutos antes e 15 minutos depois das luzes principais."
          }
          error={presetError.error && presetError.where === "minutesOfLight"}
        />
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          date={startTime}
          handleChange={setStartTime}
          label="Que horas a luz principal liga?"
          error={presetError.error && presetError.where === "startStopTime"}
        />
        <Typography variant="caption" />
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          date={stopTime}
          handleChange={setStopTime}
          label="E que horas ela desliga?"
          error={presetError.error && presetError.where === "startStopTime"}
        />
        <Typography variant="caption" />
      </Grid>
    </Grid>
  );
  return (
    <Diag
      title={controlPresetLabels[controlPresets.EMERSON + "_LABEL"]}
      content={isSaving ? <LoadingView absolute tooltip="Salvando" /> : mainContent}
      onClose={onClose}
      onSave={onSave}
    />
  );
}
PresetEmerson.propTypes = {
  preset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default PresetEmerson;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import EnforceMaxLoadingTime from "./EnforceMaxLoadingTime";
const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,

    "&.absolute": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      padding: 0
    }
  },
  "@keyframes bgFlick": {
    "0%": {
      opacity: 0.5
    },
    "100%": {
      opacity: 0.8
    }
  },
  "@keyframes bgFlickAbsolute": {
    "0%": {
      opacity: 0.8
    },
    "100%": {
      opacity: 1
    }
  },
  loaderRoot: {
    animation: "bgFlick 0.7s ease-in infinite alternate both",
    display: "flex",
    background: theme.palette.common.white,
    alignItems: "center",
    justifyContent: "center",
    "&.absolute": {
      zIndex: "100",
      animation: "bgFlickAbsolute 0.7s ease-in infinite alternate both"
    }
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: theme.spacing(4),
    textAlign: "center"
  },
  tooltip: {
    "&.mini": {
      margin: 0
    }
  }
});
const LoadingView = props => {
  const {
    classes,
    size,
    absolute,
    tooltip,
    mini,
    enforceMaxLoadingTime,
    noTooltip
  } = props;
  let finalTooltip;
  if (tooltip && !noTooltip) {
    finalTooltip = tooltip;
  }
  if (noTooltip) {
    finalTooltip = null;
  }
  if (!noTooltip && !tooltip) {
    finalTooltip = "Carregando";
  }
  return (
    <Grid
      container
      className={classNames(classes.root, absolute ? "absolute" : null)}
    >
      {enforceMaxLoadingTime ? (
        <EnforceMaxLoadingTime onTimeout={enforceMaxLoadingTime} />
      ) : null}
      <Grid
        className={classNames(classes.loaderRoot, absolute ? "absolute" : null)}
        item
        xs={12}
      >
        <Paper className={classes.loaderContainer} elevation={0}>
          <CircularProgress
            className={classes.progress}
            size={size ? size : 50}
          />
          <p className={classNames(classes.tooltip, mini ? "mini" : null)}>
            {finalTooltip}...
          </p>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(LoadingView);

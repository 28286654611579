import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextInput from "../Input/Text";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";
const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column"
  },
  colorPrimaryLight: {
    color: theme.palette.primary.light
  },
  textInput: {
    paddingTop: theme.spacing(1),
    "&:first-child": {
      marginBottom: theme.spacing(1)
    }
  },
  formHelpLinksContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: "right"
  },
  button: {
    marginLeft: "auto",
    marginTop: theme.spacing(1)
  },
  customTitle: {
    marginBottom: theme.spacing(1)
  }
});

function LoginForm(props) {
  const { classes, customTitle, buttonTitle } = props;
  return (
    <div className={props.className}>
      <Paper elevation={4} className={classes.root}>
        {customTitle ? (
          <Typography className={classes.customTitle} component="h3">
            {customTitle}
          </Typography>
        ) : null}
        <div className={classes.textInput}>
          <TextInput
            type="text"
            handleChange={e => props.handleChange(e.target.value, "email")}
            label={props.emailLabel ? props.emailLabel : "Seu email de acesso"}
            value={props.email}
          />
        </div>
        <div className={classes.textInput}>
          <TextInput
            type={props.showPassword ? "password" : "text"}
            handleChange={e => props.handleChange(e.target.value, "password")}
            adornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Mudar visibilidade da senha"
                  onClick={props.handleClickShowPassword}
                  className={classes.colorPrimaryLight}
                >
                  {props.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={props.pass}
            label={props.passLabel ? props.passLabel : "Senha"}
            key="login_pass"
            onKeyPress={event => {
              if (event.key === "Enter") {
                props.handleSubmit();
              }
            }}
          />
        </div>
        <Button
          variant="outlined"
          onClick={props.handleSubmit || null}
          color="primary"
          className={classes.button}
        >
          {buttonTitle ? buttonTitle : "login"}
        </Button>
        {props.returnText ? (
          <div className={classes.formHelpLinksContainer}>
            <ButtonBase onClick={props.handleReturnButton}>
              <Typography>{props.returnText}</Typography>
            </ButtonBase>
          </div>
        ) : null}
      </Paper>
    </div>
  );
}
// dev TOdo, user is missing pass, send to resetpass form
export default withStyles(styles)(LoginForm);

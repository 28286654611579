//dev todo relocate this shit
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "../Forms/Select/Select";
import TextMultiple from "../Forms/Input/Text/Multiline";
import SendIcon from "@material-ui/icons/Send";
import { feedbackTypes } from "../../constants/feedback";
import { feedbackDb } from "../../constants/db";
import LoadingView from "../Helpers/LoadingView";
import { db } from "../../firebase";
const styles = theme => ({
  contentRoot: {
    padding: `${theme.spacing(2)}px`,
    width: 250,
    display: "flex",
    flexDirection: "column"
  },
  titleContainer: {
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: "1rem",
    "&span": {
      fontSize: "1.5rem"
    }
  },
  buttonIcon: {
    marginLeft: theme.spacing(2)
  }
});

class FeedbackPopover extends Component {
  state = {
    saving: false
  };
  handleFormChange = (data, progress) => {
    if (progress === "info") {
      this.setState({ info: data });
    } else {
      this.setState({ type: data });
    }
  };
  handleSubmit = () => {
    if (this.state.info && this.state.info.trim() && this.state.type) {
      this.setState({ saving: true });
      db.doCreateDocInCollection(
        {
          type: this.state.type,
          info: this.state.info,
          screen: this.props.screenTitle
        },
        feedbackDb,
        { includeEmail: true }
      )
        .then(() => {
          this.props.onDismiss();
        })
        .catch(error => {
          return;
        });
    } else {
      this.setState({ error: true });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={true}
        anchorEl={this.props.anchor}
        BackdropProps={{ invisible: false }}
        onBackdropClick={this.props.onDismiss}
      >
        <div className={classes.contentRoot}>
          {this.state.saving ? (
            <LoadingView
              tooltip="Muito Obrigado! Enviando"
              enforceMaxLoadingTime={this.props.onDismiss}
            />
          ) : (
            <React.Fragment>
              <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                  Gostaria de conversar, sugerir, perguntar algo?
                </Typography>
                <Typography className={classes.title}>
                  Ou encontrou um ...bug?{" "}
                  <span role="img" aria-label=":Ooops:">
                    &#128517;
                  </span>
                </Typography>
              </div>
              <Select
                options={feedbackTypes}
                onChange={e => this.handleFormChange(e.target.value, "type")}
                label="Gostaria de falar sobre..."
                error={this.state.error ? true : false}
              />
              <TextMultiple
                rows={3}
                onChange={e => this.handleFormChange(e.target.value, "info")}
                label="O que gostaria de falar?"
                error={this.state.error ? true : false}
              />
              <Button
                onClick={this.handleSubmit}
                variant="outlined"
                color="primary"
              >
                Enviar
                <SendIcon className={classes.buttonIcon} />
              </Button>
            </React.Fragment>
          )}
        </div>
      </Popover>
    );
  }
}
export default withStyles(styles)(FeedbackPopover);

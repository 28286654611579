import React from "react";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
const styles = theme => {
  return {
    root: {
      width: "100%"
    }
  };
};
const parseLabels = labels => {
  return labels.map((label, i) => <Tab key={i} label={label} />);
};
const DefaultTab = props => {
  const { classes, tabsContent, labels, handleTabChange, currentTab, tour } = props;
  return (
    <div className={classes.root} data-tour={tour}>
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {parseLabels(labels)};
        </Tabs>
      </AppBar>
      <SwipeableViews axis="x" index={currentTab} onChangeIndex={handleTabChange}>
        {tabsContent}
      </SwipeableViews>
    </div>
  );
};
DefaultTab.propTypes = {
  tabsContent: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  tour: PropTypes.string
};
export default withStyles(styles)(DefaultTab);

import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
//import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddEditLembrete from "../Views/Form/AddEditLembrete";
import { REMINDERS_APP_TITLE } from "../../constants/views";
import { DASHBOARD } from "../../constants/routes";
import { connect } from "react-redux";
import { db, messaging } from "../../firebase";
import { remindersDb, usersDb } from "../../constants/db";

import { isIOS } from "react-device-detect";
import BaseApp from "./BaseApp";
import Tour from "../UI/Tour/Tour";
import StepContent from "../UI/Tour/StepContent";
import { DateTime } from "luxon";
import {
  FEEDBACK_BTN_TOUR,
  reminderAppTours,
  ADD_SAVE_FAB_BTN
} from "../../constants/tours";
import FeedbackTourStepContent from "../UI/Tour/Content/Feedback";

const styles = theme => {
  return {
    lembreteListContainer: {
      background: theme.palette.common.white,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    },
    lembreteListRoot: {
      paddingBottom: 0,
      "& > li": {
        borderBottom: `1px solid ${theme.palette.grey[400]}`
      }
    },
    lembretesListItem: {
      paddingTop: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`,
      "&:not(last-child)": {},
      "& p.tags": {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: theme.spacing(1),
        color: theme.palette.grey[700]
      }
    },
    lembretesListItemText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      "& p": {
        fontSize: "80%!important",
        lineHeight: "2em",
        color: "darkorange"
      }
    },
    chip: {
      marginBottom: theme.spacing(1)
    },
    expired: {
      color: theme.palette.error.main
    }
  };
};

class LembretesApp extends Component {
  state = {
    editing: false,
    adding: false,
    selected: null
  };

  //mode true recurring
  componentDidMount() {
    //component mounted with messaging already in global state
    if (this.props.messaging && !isIOS) {
      messaging.doAskForPermissioToReceiveNotifications(this.props.messaging);
    }
    if (this.props.user && this.props.user.tours) {
      const { user } = this.props;

      if (!user.tours.reminders) {
        //user has not completed tours.dashboard
        this.setState({ tour: true });
      }
    }
  }
  componentDidUpdate(prevProps) {
    //only ask for permission(once per load) after we fetched the current tokens from the user's profile
    if (prevProps.messaging === null && this.props.messaging) {
      //component mounted without messaging in global state (user probably loaded in this app's url), so we only ask once we are sure we have it
      messaging.doAskForPermissioToReceiveNotifications(this.props.messaging);
    }
    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (!user.tours.reminders) {
          this.setState({ tour: true });
        }
      }
    }

    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.reminders) {
          if (!user.tours.reminders.main) {
            //user has not completed tours.dashboard
            this.setState({ tour: true });
          }
        }
      }
    }
    if (!this.state.editTour && !this.state.editTourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.reminders) {
          if (!user.tours.reminders.edit) {
            //user has not completed tours.dashboard

            this.setState({ editTour: true });
          }
        }
        if (!user.tours.reminders) {
          this.setState({ editTour: true });
        }
      }
    }
  }

  handleTourClose = async (isFinished, isEdit) => {
    if (isFinished && !isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            reminders: {
              ...this.props.user.tours.reminders,
              main: true
            }
          }
        },
        usersDb
      );
      return this.setState({ tour: false, tourCanceled: true });
    } else if (isFinished && isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            reminders: {
              ...this.props.user.tours.reminders,
              edit: true
            }
          }
        },
        usersDb
      );
      return this.setState({ editTour: false, editTourCanceled: true });
    }
    return this.setState({
      editTour: false,
      tour: false,
      tourCanceled: true,
      editTourCanceled: true
    });
  };

  handleSave = (reminder, onError) => {
    //get good stuff, update our list in the db and get the new list as callback
    //mock saving direct to state

    if (this.state.editing) {
      const { id, ...data } = reminder;
      db.doUpdateDocWithDataInCollection(
        id,
        { ...data, active: true },
        remindersDb
      )
        .then(() => {
          //notify
          this.setState({ editing: false, adding: false, selected: "" });
        })
        .catch(error => {
          //dev todo notify
          onError(error);
        });
    } else {
      db.doCreateDocInCollection(
        {
          ...reminder,
          active: true,
          last_notification: reminder.recurringMode.time
        },
        remindersDb
      )
        .then(() => {
          //notify
          this.setState({ editing: false, adding: false, selected: "" });
        })
        .catch(error => {
          //dev todo notify
          onError(error);
          //dont pop addedit view, just "send" the control back to it
        });
    }
  };
  handleDelete = onError => {
    db.doDeleteDocumentFromCollection(
      this.props.reminders[this.state.selected].id,
      remindersDb
    )
      .then(() => {
        //notify
        this.setState({ editing: false, adding: false, selected: "" });
      })
      .catch(error => {
        //dev todo notify
        onError(error);
        //dont pop addedit view, just "send" the control back to it
      });
  };
  handleBackBtn = () => {
    this.setState({ editing: false, adding: false, selected: "" });
  };
  renderReminders = () => {
    const { reminders, classes } = this.props;
    return reminders.map((lembrete, i) => {
      const timeUntil = (time, unit, active) =>
        active ? `O próximo aviso será em ${time} ${unit}.` : "";
      let currentConfig = "";

      let time;
      let unit;
      let warnDate;
      let diffInDays;
      //gzus fuck... dev todo rewrite this mess, create proper components for reminder list, use only luxon for parsing dates in the UI.  NOte: we also have date-fns for datetime form pickers since they have better compatibility but it should be limited to pickers.
      if (lembrete.mode) {
        //recurring "proximo aviso em X day/hour"
        // everytime we set a recurring event the time property it is set to the same day of creation and to the time the user selected. we then add the frequency to this date(.time) and compare how long until notification
        warnDate = DateTime.fromJSDate(
          lembrete.last_notification.toDate()
        ).plus({
          days: parseInt(lembrete.recurringMode.frequency, 10)
        });

        currentConfig = `Avisar ${
          lembrete.recurringMode.frequency > 1
            ? "a cada " + lembrete.recurringMode.frequency
            : "todos"
        } dias as ${warnDate
          .setLocale("pt-BR")
          .toLocaleString(DateTime.TIME_SIMPLE)}`;
      } else {
        //one time "o evento ocorrera em x day/hour"
        warnDate = DateTime.fromJSDate(lembrete.oneTimeMode.datetime.toDate());
        warnDate.setLocale("pt-BR");
        let dateHuge = warnDate
          .setLocale("pt-BR")
          .toLocaleString(DateTime.DATE_HUGE);
        dateHuge = dateHuge.charAt(0).toUpperCase() + dateHuge.slice(1);
        currentConfig = `${dateHuge} as ${warnDate
          .setLocale("pt-BR")
          .toLocaleString(DateTime.TIME_SIMPLE)}`;
      }

      diffInDays = Math.round(
        warnDate.diff(DateTime.fromJSDate(new Date()), "days").toObject().days
      );

      if (diffInDays >= 1) {
        time = diffInDays;
        unit = diffInDays > 1 ? "dias" : "dia";
      } else {
        time = warnDate
          .diff(DateTime.fromJSDate(new Date()), "hours")
          .toObject().hours;
        unit = time > 1 ? "horas" : "hora";
      }

      return (
        <ListItem
          key={lembrete.id}
          dense
          button
          onClick={() => {
            this.setState({ editing: true, selected: i });
          }}
          className={classes.lembretesListItem}
          data-tour={i === 0 ? reminderAppTours.REMINDER_LIST_ITEM : null}
        >
          <ListItemText
            secondary={timeUntil(time, unit, lembrete.active)}
            primary={
              <div>
                {lembrete.active ? null : (
                  <Typography className={classes.expired} component="p">
                    EXPIRADO: Esse lembrete não será notificado novamente.
                  </Typography>
                )}

                <Typography className="tags" component="p">
                  INFO:
                </Typography>
                {lembrete.info}
                <br />
                <Typography className="tags" component="p">
                  PROGRAMAÇÃO DE AVISO:
                </Typography>
                {currentConfig}
                {lembrete.tags.length >= 1 ? (
                  <div>
                    <Typography className="tags" component="p">
                      TAGS:
                    </Typography>
                    {lembrete.tags.map(tag => (
                      <Chip key={tag} label={tag} className={classes.chip} />
                    ))}
                  </div>
                ) : null}
              </div>
            }
            className={classes.lembretesListItemText}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Lembrete"
              onClick={() => {
                this.setState({ editing: true, selected: i });
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, reminders } = this.props;
    if (this.state.editing || this.state.adding) {
      const handlers = {
        handleSave: this.handleSave,
        handleBackBtn: this.handleBackBtn,
        handleDelete: this.handleDelete
      };
      let title;
      let mode;
      if (this.state.editing) {
        title = "Editando lembrete";
        mode = "editing";
      } else if (this.state.adding) {
        title = "Adicionando lembrete";
        mode = "adding";
      }
      return (
        <AddEditLembrete
          mode={mode}
          lembreteData={
            this.state.editing
              ? this.props.reminders[this.state.selected]
              : null
          }
          handlers={handlers}
          title={title}
          handleTourClose={this.state.editTour ? this.handleTourClose : null}
        />
      );
    }
    const steps = [
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_LIST_ITEM}]`,
        content: (
          <StepContent title="Lembretes">
            <Typography component="p">
              Aqui você pode ver e criar novos lembretes.
            </Typography>
            <Typography component="p">
              Para ver e/ou deletar basta clicar em <span>qualquer lugar</span>{" "}
              do lembrete desejado.
            </Typography>
            <Typography component="p">
              <span>Nota:</span> Lembretes não podem ser editados, caso não
              precise de um lembrete você pode deletar os velhos e criar novos
              quando desejar.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${ADD_SAVE_FAB_BTN}]`,
        content: (
          <StepContent title="Adicionar Lembrete">
            <Typography component="p">
              Você pode adicionar novos lembretes clicando aqui.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${FEEDBACK_BTN_TOUR}]`,
        content: <FeedbackTourStepContent />
      }
    ];

    return (
      <BaseApp
        title={REMINDERS_APP_TITLE}
        backBtnRoute={DASHBOARD}
        onAdd={() => {
          this.setState({ adding: true });
        }}
        withTour={true}
      >
        {reminders.length ? (
          <Grid className={classes.lembreteListContainer} item xs={12}>
            {this.state.tour ? (
              <Tour steps={steps} onRequestClose={this.handleTourClose} />
            ) : null}
            <List className={classes.lembreteListRoot}>
              {this.renderReminders()}
            </List>
          </Grid>
        ) : (
          <Typography className={classes.noEntries}>
            Procurando seus lembretes...
          </Typography>
        )}
      </BaseApp>
    );
  }
}

function mapStateToProps({ reminders, messaging, user }) {
  return { reminders, messaging, user };
}
export default connect(mapStateToProps)(withStyles(styles)(LembretesApp));

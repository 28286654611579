import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: "100%"
    }
  };
});
function DatePicker(props) {
  const { label, date, handleChange } = props;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        label={label ? label : "Escolha o dia"}
        format="MM/dd/yyyy"
        value={date ? date : new Date()}
        onChange={handleChange}
        KeyboardButtonProps={{
          "aria-label": "mudar data"
        }}
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  );
}
DatePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.object,
  label: PropTypes.string
};
export default DatePicker;

/*
  dev todo //
  paginate entries, preload images and only load images in view or 'next' to it, only load posts in view(and next to it) for performance
*/

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { db, storage } from "../../../firebase";
import { DIARY_APP_TITLE } from "../../../constants/views";
import { diaryappDb, usersDb } from "../../../constants/db";
import DiaryEntry from "./DiaryEntry/DiaryEntry";
import { DASHBOARD } from "../../../constants/routes";
import AddEditEntry from "./AddEditEntry";
import BaseApp from "../BaseApp";
import Tour from "../../UI/Tour/Tour";
import StepContent from "../../UI/Tour/StepContent";
import {
  FEEDBACK_BTN_TOUR,
  ADD_SAVE_FAB_BTN,
  diaryAppTours
} from "../../../constants/tours";
import FeedbackTourStepContent from "../../UI/Tour/Content/Feedback";

const styles = theme => {
  return {
    entry: {
      marginBottom: theme.spacing(2)
    }
  };
};
class DiaryApp extends Component {
  state = {
    editing: false,
    adding: false,
    selected: "",
    showEntriesFrom: 0,
    showEntriesUpTo: 2 //  including
  };

  componentDidMount() {
    if (this.props.user && this.props.user.tours) {
      const { user } = this.props;

      if (!user.tours || !user.tours.diary || !user.tours.diary.main) {
        //user has not completed tours.dashboard
        this.setState({ tour: true });
      }
    }
  }
  componentDidUpdate() {
    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.diary) {
          //has already began at some point on componentdidmount
          if (!user.tours.diary.main) {
            //user has not completed tours.diary.main
            this.setState({ tour: true });
          }
        }
      }
    }
    if (!this.state.editTour && !this.state.editTourCanceled) {
      //we not touring already
      if (this.props.user && this.props.user.tours) {
        //got the user
        const { user } = this.props;
        if (user.tours.diary) {
          if (!user.tours.diary.edit) {
            //user has not completed tours.diary.edit (edit post tour)
            this.setState({ editTour: true });
          }
        } else if (!user.tours.diary || !user.tours.diary.edit) {
          this.setState({ editTour: true });
        }
      }
    }
  }
  handleTourClose = async (isFinished, isEdit) => {
    if (isFinished && !isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            diary: {
              ...this.props.user.tours.diary,
              main: true
            }
          }
        },
        usersDb
      );
      return this.setState({ tour: false, tourCanceled: true });
    } else if (isFinished && isEdit) {
      await db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            diary: {
              ...this.props.user.tours.diary,
              edit: true
            }
          }
        },
        usersDb
      );
      return this.setState({ editTour: false, editTourCanceled: true });
    }
    return this.setState({
      editTour: false,
      tour: false,
      tourCanceled: true,
      editTourCanceled: true
    });
  };

  handleSave = async (entry, onError) => {
    let shouldUpdateDb = false;
    const imagesChanged = items => {
      return items.reduce((sum, item) => {
        return typeof item === "object";
      }, 0);
    };
    const curEntry = this.props.diaryApp[this.state.selected];
    if (this.state.editing) {
      const { id, note, images } = entry;
      //dev todo maybe only update if when something changes and not only when save is pressed.(server side)
      const imagesChanged = items => {
        return items.reduce((sum, item) => {
          return typeof item === "object";
        }, 0);
      };
      const noteChanged = note => {
        if (note !== curEntry.note) {
          return true;
        }
        return false;
      };
      //images wi]ll either be an array of strings straight from db or an array of file obj if the user decided to update images.
      if (imagesChanged(images)) {
        const urls = await storage.doStoreFilesForDiaryEntry(
          images,
          entry.id,
          this.props.user.user_id
        );

        shouldUpdateDb = true;

        //add all urls
        urls.forEach(url => curEntry.images.push(url));
      }

      if (noteChanged(entry.note)) {
        shouldUpdateDb = true;
      }
      if (shouldUpdateDb) {
        db.doUpdateDocWithDataInCollection(
          id,
          { note, images: curEntry.images },
          diaryappDb
        )
          .then(() => {
            this.resetState();
          })
          .catch(error => {
            //dev todo notify
            onError(error);
          });
      } else {
        this.resetState();
      }
    } else {
      const { note, images } = entry;

      try {
        const docRef = await db.doCreateDocInCollection(
          { note, images: [] },
          diaryappDb
        );

        if (imagesChanged(images)) {
          const urls = await storage.doStoreFilesForDiaryEntry(
            images,
            docRef.id,
            this.props.user.user_id
          );
          //add all urls

          await db.doUpdateDocWithDataInCollection(
            docRef.id,
            { images: urls },
            diaryappDb
          );
          this.resetState();
        }
        this.resetState();
      } catch (e) {
        this.resetState();
      }
    }
  };
  handleDelete = onError => {
    //delete state.selected entry from db
    //sending its dbId to the backend
    db.doDeleteDocumentFromCollection(
      this.props.diaryApp[this.state.selected].id,
      diaryappDb
    )
      .then(() => {
        //notify
        this.resetState();
      })
      .catch(error => {
        //dev todo notify
        onError(error);
        //dont pop addedit view, just "send" the control back to it
      });
  };
  resetState = () => {
    this.setState({ editing: false, adding: false, selected: "" });
  };
  entryEnteredViewport = entryIndex => {
    const { diaryApp } = this.props;
    let showEntriesFrom = 0;
    let showEntriesUpTo = 2;
    if (entryIndex === 1 || entryIndex === 0) {
      if (
        this.state.showEntriesFrom !== 0 ||
        this.state.showEntriesUpTo !== 2
      ) {
        return this.setState({ showEntriesFrom, showEntriesUpTo });
      } else {
        return;
      }
    }
    if (entryIndex > 1) {
      if (entryIndex < diaryApp.length - 1) {
        showEntriesFrom = entryIndex - 2;
        showEntriesUpTo = entryIndex + 1;
      } else {
        //its last entry// maybe load more? but if its absolute last... show 2 previous to the last(entryIndex)
        showEntriesFrom = entryIndex - 2;
        showEntriesUpTo = entryIndex;
      }
      return this.setState({ showEntriesFrom, showEntriesUpTo });
    }
  };

  render() {
    const { classes, diaryApp } = this.props;
    if (this.state.editing === true || this.state.adding === true) {
      const handlers = {
        handleSave: this.handleSave,
        handleBackBtn: this.resetState,
        handleDelete: this.handleDelete
      };
      let title;
      let mode;
      if (this.state.editing) {
        title = "Editando nota";
        mode = "editing";
      } else if (this.state.adding) {
        title = "Adicionando nota";
        mode = "adding";
      }
      return (
        <AddEditEntry
          mode={mode}
          entryData={this.state.editing ? diaryApp[this.state.selected] : {}}
          handlers={handlers}
          title={title}
          handleTourClose={this.state.editTour ? this.handleTourClose : null}
        />
      );
    }
    const steps = [
      {
        selector: `[data-tour=${diaryAppTours.DIARY_ENTRY_IMAGE}]`,
        content: (
          <StepContent title="Imagens">
            <Typography component="p">
              Aqui você pode ver as imagens da nota feita no diário.
            </Typography>
            <Typography component="p">
              Você pode <span>arrastar</span> para passar as imagens ou usar os{" "}
              <span>botões</span>.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${diaryAppTours.DIARY_EDIT_BTN}]`,
        content: (
          <StepContent title="Editar Nota">
            <Typography component="p">
              Você pode editar ou deletar suas notas ou adicionar mais fotos
              clicando aqui.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${ADD_SAVE_FAB_BTN}]`,
        content: (
          <StepContent title="Adicionar Nota">
            <Typography component="p">
              Você pode adicionar novas entradas no seu diário clicando aqui.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${FEEDBACK_BTN_TOUR}]`,
        content: <FeedbackTourStepContent />
      }
    ];
    return (
      <BaseApp
        title={DIARY_APP_TITLE}
        backBtnRoute={DASHBOARD}
        onAdd={() => {
          this.setState({ adding: true });
        }}
        withTour={true}
      >
        <div className={classes.entriesContainer}>
          {this.props.diaryApp.length ? (
            this.props.diaryApp
              .map((entry, i) => {
                if (
                  i >= this.state.showEntriesFrom &&
                  i <= this.state.showEntriesUpTo
                ) {
                  return (
                    <div key={i} className={classes.entry}>
                      <DiaryEntry
                        entry={entry}
                        onSelect={() =>
                          this.setState({ editing: true, selected: i })
                        }
                        elevation={2}
                        square={true}
                        withTour={i === 0 ? true : null}
                        onEnterViewport={() => this.entryEnteredViewport(i)}
                      />
                      {this.state.tour && i === 0 ? (
                        <Tour
                          steps={steps}
                          onRequestClose={this.handleTourClose}
                        />
                      ) : null}
                    </div>
                  );
                }
                return null;
              })
              .filter(item => item !== null && item !== undefined)
          ) : (
            <Typography className={classes.noEntries}>
              Procurando suas notas...
            </Typography>
          )}
        </div>
      </BaseApp>
    );
  }
}
function mapStateToProps({ diaryApp, user }) {
  return { diaryApp, user };
}
export default connect(mapStateToProps)(withStyles(styles)(DiaryApp));

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = theme => ({
  root: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 500,
    borderRadius: 5,
    position: "relative",
    width: "100%",
    "&.saving": {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    }
  },
  progress: {
    color: theme.palette.common.white
  }
});
const TourCloseBtn = props => {
  const { classes, isSaving } = props;
  return (
    <Typography
      component="div"
      className={classNames(classes.root, isSaving ? "saving" : null)}
    >
      {isSaving ? (
        <CircularProgress className={classes.progress} size={20} />
      ) : (
        "Fechar e Continuar"
      )}
    </Typography>
  );
};
TourCloseBtn.propTypes = {
  isSaving: PropTypes.bool
};
export default withStyles(styles)(TourCloseBtn);

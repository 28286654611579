import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import WbIncandescent from "@material-ui/icons/WbIncandescent";
import Waves from "@material-ui/icons/Waves";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { makeStyles } from "@material-ui/core/styles";
import { sensorPreset } from "../../../../../../constants/controlPresets";
import Switch from "../../../../../Forms/Switch/Switch";
import TextInput from "../../../../../Forms/Input/Text";

import Button from "../../../../../UI/Button/Button/Button";
import Diag from "../../../../../UI/Diag/Diag";
import LoadingView from "../../../../../Helpers/LoadingView";
import PropTypes from "prop-types";
import {
  controlPresetLabels,
  controlPresets
} from "../../../../../../constants/controlPresets";
import { functions } from "../../../../../../firebase";
const useStyles = makeStyles(theme => {
  return {
    photoperiodModeBtnGroup: {
      borderColor: theme.palette.grey[200]
    },
    textField: {
      fontSize: theme.typography.h1.fontSize
    },
    input: {
      textAlign: "center"
    },
    buttonLabel: {
      fontSize: 10
    }
  };
});
function PresetSensor(props) {
  const { preset, onClose, handleSave } = props;
  const classes = useStyles();
  const [sensorType, setSensorType] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.SENSOR) {
        return preset.options.sensorType;
      } else {
        return "temp";
      }
    } else {
      return "temp";
    }
  });
  const [comparator, setComparator] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.SENSOR) {
        return preset.options.comparator;
      } else {
        return "more_than";
      }
    } else {
      return "more_than";
    }
  });
  const [value, setValue] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.SENSOR) {
        return preset.options.value;
      } else {
        return "";
      }
    } else {
      return "";
    }
  });
  const [action, setAction] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.SENSOR) {
        return preset.options.action;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [presetError, setPresetError] = React.useState({});
  function parseTextInputLabel() {
    if (sensorType && comparator) {
      return (
        sensorPreset[sensorType].comparator +
        " " +
        sensorPreset.comparators[comparator].label.toLowerCase()
      );
    } else {
      return "Valor para ativar a regra";
    }
  }
  function onSave() {
    setIsSaving(true);

    if (
      sensorType &&
      comparator &&
      (action !== null) & (action !== undefined) &&
      value > 0 &&
      value < 100
    ) {
      //save

      functions
        .saveSensorRule({
          portKey: preset.portKey,
          value: controlPresets.SENSOR,
          options: {
            sensorType,
            comparator,
            action,
            value
          }
        })
        .then(results => {
          setIsSaving(false);
          handleSave();
        })
        .catch(e => {
          setIsSaving(false);
        });
    } else {
      if (!sensorType) {
        setPresetError({ error: true, where: "sensorType" });
        setIsSaving(false);
        return;
      }
      if (!comparator) {
        setPresetError({ error: true, where: "comparator" });
        setIsSaving(false);
        return;
      }
      if (!value > 0 || !value < 100) {
        setPresetError({ error: true, where: "value" });
        setIsSaving(false);
        return;
      }
      if ((action !== null) & (action !== undefined)) {
        setPresetError({ error: true, where: "action" });
        setIsSaving(false);
        return;
      }
      if ((action !== null) & (action !== undefined)) {
        setPresetError({ error: true, where: "sensorType" });
        setIsSaving(false);
        return;
      }
    }
  }
  const mainContent = (
    <Grid spacing={2} container>
      <Grid item container xs={12}>
        <ButtonGroup
          classes={{ root: classes.root }}
          size={"small"}
          aria-label="large contained secondary button group"
          fullWidth
        >
          <Button
            label="Temperatura"
            color="green"
            selected={sensorType === "temp"}
            icon={<WbIncandescent fontSize="small" />}
            onClick={() => setSensorType("temp")}
            className={classes.buttonLabel}
          />
          <Button
            label="Umidade"
            color="blue"
            selected={sensorType === "hum"}
            icon={<Waves fontSize="small" />}
            onClick={() => setSensorType("hum")}
            className={classes.buttonLabel}
          />
        </ButtonGroup>
      </Grid>
      <Grid item container xs={12}>
        <ButtonGroup
          classes={{ root: classes.root }}
          size="small"
          aria-label="large contained secondary button group"
          fullWidth
        >
          <Button
            label={sensorPreset.comparators.more_than.label}
            color="red"
            selected={comparator === "more_than"}
            icon={<ArrowUpward fontSize="small" />}
            onClick={() => setComparator("more_than")}
            className={classes.buttonLabel}
          />
          <Button
            label={sensorPreset.comparators.less_than.label}
            color="orange"
            selected={comparator === "less_than"}
            icon={<ArrowDownward fontSize="small" />}
            onClick={() => setComparator("less_than")}
            className={classes.buttonLabel}
          />
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={parseTextInputLabel()}
          type="number"
          value={value + ""}
          handleChange={e => setValue(parseInt(e.target.value, 10))}
          className={classes.textField}
          inputClasses={{ input: classes.input }}
          endAdornment={sensorType === "temp" ? "°C" : "%"}
          error={presetError.error && presetError.where === "value"}
        />
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={action ? "Manter Ligado" : "Manter Desligado"}
          value={action}
          onChange={value => setAction(value)}
          tooltip={
            action
              ? "Aparelhos ficarão ligados"
              : "Aparelhos ficarão desligados"
          }
        />
      </Grid>
    </Grid>
  );

  return (
    <Diag
      title={controlPresetLabels[controlPresets.SENSOR + "_LABEL"]}
      content={
        isSaving ? <LoadingView absolute tooltip="Salvando" /> : mainContent
      }
      onClose={onClose}
      onSave={onSave}
    />
  );
}
PresetSensor.propTypes = {
  preset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default PresetSensor;

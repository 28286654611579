import React from "react";
//import PropTypes from 'prop-types';

import { connect } from "react-redux";
import GamesIcon from "@material-ui/icons/Games";
import { CONTROLS as CONTROLS_APP } from "../../constants/routes";
import { CONTROLS_APP_TITLE } from "../../constants/views";
import AppCard from "./AppCard";
import PropTypes from "prop-types";
import { controlPresets as constControlPresets } from "../../constants/controlPresets";

function ControlsAppCard(props) {
  const { controlPresets, tour } = props;
  function parseCardContent() {
    let autoCounter = 0;
    let manualCounter = 0;
    for (let port in controlPresets) {
      if (port.indexOf("port") === 0) {
        if (controlPresets[port].value === constControlPresets.MANUAL) {
          manualCounter++;
        } else {
          autoCounter++;
        }
      }
    }
    const template = (total, type) =>
      total
        ? `Você tem ${total} ${total > 1 ? "regras" : "regra"} do tipo ${type} ${
            total > 1 ? "ativas" : "ativa"
          }.`
        : null;
    return (
      <React.Fragment>
        <p>{template(autoCounter, "automática")}</p>
        <p>{template(manualCounter, "manual")}</p>
      </React.Fragment>
    );
  }

  return (
    <AppCard
      title={CONTROLS_APP_TITLE}
      icon={<GamesIcon />}
      content={parseCardContent()}
      subContent="clique em qualquer lugar para controlar"
      appRoute={CONTROLS_APP}
      tour={tour}
    />
  );
}

ControlsAppCard.propTypes = {
  tour: PropTypes.string
};
function mapStateToProps({ controlPresets }) {
  return {
    controlPresets
  };
}
export default connect(mapStateToProps)(ControlsAppCard);

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import reminderReducer from "./reminderReducer";
import diaryAppReducer from "./diaryAppReducer";
import telemetryReducer from "./telemetryReducer";

import profileAppReducer from "./profileAppReducer";
import analyticsReducer from "./analyticsReducer";
import relayStateReducer from "./relayStateReducer";
import messagingReducer from "./messagingReducer";
import controlPresetsReducer from "./controlPresetsReducer";

export default combineReducers({
  user: authReducer,
  diaryApp: diaryAppReducer,
  reminders: reminderReducer,
  sensors: telemetryReducer,
  profileApp: profileAppReducer,
  messaging: messagingReducer,
  analytics: analyticsReducer,
  relayState: relayStateReducer,
  controlPresets: controlPresetsReducer,
});

import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "../../../../../Forms/Switch/Switch";
import TextInput from "../../../../../Forms/Input/Text";
import TimePicker from "../../../../../Forms/Pickers/TimePicker";
import DatetimePicker from "../../../../../Forms/Pickers/Datetime/Datetime";
import Select from "../../../../../Forms/Select/Selectv2";
import parseTimeScale from "../../../../../Helpers/parseTimeScale";
import Diag from "../../../../../UI/Diag/Diag";
import LoadingView from "../../../../../Helpers/LoadingView";
import PropTypes from "prop-types";
import {
  controlPresetLabels,
  controlPresets,
  timeFormatString
} from "../../../../../../constants/controlPresets";

import { functions } from "../../../../../../firebase";
import { isDate, isPast, format } from "date-fns";
import parseDate from "../../../../../Helpers/parseDate";
const useStyles = makeStyles(theme => {
  return {
    photoperiodModeBtnGroup: {
      borderColor: theme.palette.grey[200]
    },
    textField: {
      fontSize: theme.typography.h1.fontSize
    },
    input: {
      textAlign: "center"
    }
  };
});
function PresetAutowater(props) {
  const { preset, onClose, handleSave } = props;
  const classes = useStyles();
  const [duration, setDuration] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER) {
        return preset.options.duration;
      }
    }
    return "";
  });
  const [repeat, setRepeat] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER) {
        if (
          typeof preset.options.frequency === "number" &&
          preset.options.frequency > 0
        ) {
          return true;
        }
      }
    }
    return false;
  });
  const [startDate, setStartDate] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER && preset.options.start) {
        return parseDate(preset.options.start.split(/[-+ :T]/));
      }
    }
    return new Date();
  });
  const [startTime, setStartTime] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER && preset.options.start) {
        return parseDate(preset.options.start.split(/[-+ :T]/));
      }
    }
    return new Date();
  });
  const [frequencyTimeScale, setFrequencyTimeScale] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER) {
        return preset.options.frequencyTimeScale || "day"; //user may sabe a non reepeating rule, so timescale wouldnt be saved, in that case we also default to day
      }
    }
    return "day";
  });
  const [frequency, setFrequency] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.AUTOWATER) {
        return preset.options.frequency;
      }
    }
    return "";
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [presetError, setPresetError] = React.useState({});
  function onSave() {
    setIsSaving(true);
    const options = {};
    if (
      !isNaN(duration) &&
      duration > 0 &&
      isDate(startTime) &&
      !isPast(startTime)
    ) {
      options.duration = parseInt(duration, 10);
      options.start = format(startTime, timeFormatString);
      options.frequency = 0; //reset

      if (repeat) {
        if (frequency && frequencyTimeScale) {
          if (frequencyTimeScale === "sec" && frequency < 3) {
            //dev todo repeating code
            setPresetError({ error: true, where: "repeat" });
            setIsSaving(false);
            return;
          }
          options.frequency = parseInt(frequency, 10);
          options.frequencyTimeScale = frequencyTimeScale;
        } else {
          setPresetError({ error: true, where: "repeat" });
          setIsSaving(false);
          return;
        }
      }
      functions
        .saveAutowaterRule({
          portKey: preset.portKey,
          value: controlPresets.AUTOWATER,
          options
        })
        .then(results => {
          setIsSaving(false);
          handleSave();
        })
        .catch(e => {
          setPresetError({ error: true, where: "start" });
          setIsSaving(false);
        });
    } else {
      if (isNaN(duration) || duration <= 0 || !duration) {
        setPresetError({ error: true, where: "duration" });
        setIsSaving(false);
        return;
      }
      if (!isDate(startTime) || isPast(startTime)) {
        setPresetError({ error: true, where: "start" });
        setIsSaving(false);
        return;
      }
    }
  }
  const mainContent = (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <TextInput
          label="Duração da rega em segundos"
          type="number"
          value={duration + ""}
          handleChange={e => setDuration(e.target.value)}
          className={classes.textField}
          inputClasses={{ input: classes.input }}
          helper="Por quantos segundos a bomba d'agua deve ficar ligada."
          error={presetError.error && presetError.where === "duration"}
        />
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={repeat ? "Rega será repetida" : "Rega não será repetida"}
          value={repeat}
          onChange={value => setRepeat(value)}
          tooltip={
            setRepeat
              ? "Rega será repetida automáticamente"
              : "Rega será repetida manualmente"
          }
        />
      </Grid>
      {repeat ? (
        <Grid item container xs={12}>
          <Grid item>
            <TextInput
              label={`Rega deverá ser repetida a cada quant${
                frequencyTimeScale === "hour" ? "a" : "o"
              }s ${parseTimeScale(frequencyTimeScale).toLowerCase()}?`}
              type="number"
              value={frequency + ""}
              handleChange={e => setFrequency(e.target.value)}
              className={classes.textField}
              inputClasses={{ input: classes.input }}
              helper={
                presetError.error && presetError.where === "repeat"
                  ? "A frequencia deve ser maior que 2 segundos"
                  : "A contagem inicia após o término da rega anterior"
              }
              error={presetError.error && presetError.where === "repeat"}
            />
          </Grid>
          <Grid item>
            <Select
              value={frequencyTimeScale}
              onChange={e => setFrequencyTimeScale(e.target.value)}
            >
              <option value="sec">{parseTimeScale("sec")}</option>
              <option value="min">{parseTimeScale("min")}</option>
              <option value="hour">{parseTimeScale("hour")}</option>
              <option value="day">{parseTimeScale("day")}</option>
            </Select>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <DatetimePicker
          handleChange={date => {
            setStartDate(date);
            setStartTime(date);
          }}
          date={startDate}
          label="Quando a rega deve acontecer?"
          error={presetError.error && presetError.where === "start"}
        />
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          date={startTime}
          handleChange={date => {
            setStartDate(date);
            setStartTime(date);
          }}
          label="A que horas a rega deve iniciar?"
          error={presetError.error && presetError.where === "start"}
          helperText={
            presetError.error && presetError.where === "start"
              ? "Escolha uma data no futuro"
              : ""
          }
        />
      </Grid>
    </Grid>
  );
  return (
    <Diag
      title={controlPresetLabels[controlPresets.AUTOWATER + "_LABEL"]}
      content={
        isSaving ? <LoadingView absolute tooltip="Salvando" /> : mainContent
      }
      onClose={onClose}
      onSave={onSave}
    />
  );
}

PresetAutowater.propTypes = {
  preset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default PresetAutowater;

import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TimePicker from "../../../../../../../Forms/Pickers/TimePicker";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";
const useStyles = makeStyles(theme => {
  return {
    ruleRow: {},
    newlyAddedRuleRow: {
      animation: "$newlyAddedRow 0.5s ease 5"
    },
    ruleNumberContainer: {
      background: theme.palette.grey[300],
      padding: theme.spacing(1),
      height: "100%"
    },
    ruleNumberText: {
      fontWeight: 700
    },
    ruleRemoveButton: {
      color: theme.palette.error.light,
      padding: 0
    },
    "@keyframes newlyAddedRow": {
      "0%": { backgroundColor: theme.palette.common.white },
      "50%": { backgroundColor: theme.palette.grey[300] },
      "100%": { backgroundColor: theme.palette.common.white }
    }
  };
});
function DailyRuleRow(props) {
  const classes = useStyles();
  const {
    newlyAdded,
    className,
    rule,
    ruleNumber,
    onDelete,
    onChange,
    error
  } = props;
  return (
    <Grid
      item
      className={classNames(
        classes.ruleRow,
        newlyAdded ? classes.newlyAddedRuleRow : null,
        className ? className : null
      )}
      alignItems="center"
      xs={12}
      spacing={1}
      container
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.ruleNumberContainer}
        item
        xs={1}
      >
        <Typography className={classes.ruleNumberText}>{ruleNumber}</Typography>
      </Grid>
      <Grid item xs={5}>
        <TimePicker
          date={moment(rule.start)
            .tz("America/Sao_Paulo")
            .toDate()}
          className={classes.rulePicker}
          label="Inicio"
          handleChange={date => onChange({ ...rule, start: date.toJSON() })}
          error={error}
          helperText={
            error ? "Os horários não devem ser iguais ou se sobreporem" : null
          }
        />
      </Grid>
      <Grid item xs={5}>
        <TimePicker
          date={moment(rule.stop)
            .tz("America/Sao_Paulo")
            .toDate()}
          className={classes.rulePicker}
          label="Fim"
          handleChange={date => onChange({ ...rule, stop: date.toJSON() })}
          error={error}
          helperText={
            error ? "Os horários não devem ser iguais ou se sobreporem" : null
          }
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onDelete} className={classes.ruleRemoveButton}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
DailyRuleRow.propTypes = {
  newlyAdded: PropTypes.bool,
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  rule: PropTypes.object.isRequired,
  ruleNumber: PropTypes.number,
  error: PropTypes.bool
};
export default DailyRuleRow;

import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import InputAdornment from "@material-ui/core/InputAdornment";

import Red from "@material-ui/core/colors/red";
import FormListGenerator from "../../Helpers/FormListGenerator";

import LoadingView from "../../Helpers/LoadingView";
import ptLocale from "date-fns/locale/pt-BR";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import addMinutes from "date-fns/addMinutes";
import BaseApp from "../BaseApp";
import Tour from "../../UI/Tour/Tour";
import StepContent from "../../UI/Tour/StepContent";
import { reminderAppTours, ADD_SAVE_FAB_BTN } from "../../../constants/tours";
const options = {
  locale: ptLocale
};
const styles = theme => {
  return {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(9)
    },

    screenTitle: {
      color: "#555",
      fontSize: 20
    },
    infoPaper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: "linear-gradient(45deg, #66bb6a, #43a047)",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      boxShadow: theme.shadows[6],
      "& p": {
        color: "white"
      }
    },
    addEntryButton: {
      position: "fixed",
      zIndex: 1250,
      bottom: theme.spacing(6),
      right: theme.spacing(2),
      "&.editing": {
        bottom: theme.spacing(15)
      }
    },
    deleteEntryButton: {
      position: "fixed",
      zIndex: 1250,
      bottom: theme.spacing(6),
      right: theme.spacing(2),
      backgroundColor: Red[400],
      color: "white"
    },
    formContainer: {
      boxShadow: theme.shadows[6]
    }
  };
};

class AddEditLembrete extends Component {
  state =
    this.props.mode === "editing"
      ? {
          ...this.props.lembreteData,
          recurringMode: {
            ...this.props.lembreteData.recurringMode,
            time: this.props.lembreteData.recurringMode.time.toDate()
          },
          oneTimeMode: {
            ...this.props.lembreteData.oneTimeMode,
            datetime: this.props.lembreteData.oneTimeMode.datetime.toDate()
          },
          errors: {},
          datetimeEdited: true
        }
      : {
          info: "",
          tags: [],
          mode: true,
          oneTimeMode: {
            datetime: new Date()
          },
          recurringMode: {
            time: new Date(),
            frequency: null
          },
          notificationType: "",
          datetimeEdited: false,

          errors: {}
        };

  //mode true == recurringMode
  //dev todo maybe change to string property?

  handleFormChange = (data, progress) => {
    this.setState({ errors: {} });
    switch (progress) {
      case "info":
        if (data.trim()) {
          this.setState({ info: data });
        } else {
          this.setState({ info: "" });
        }
        break;
      case "tags":
        const tags = [...this.state.tags];
        tags.push(data);
        this.setState({ tags });
        break;
      case "notificationType":
        this.setState({ notificationType: data });
        break;
      case "mode":
        this.setState({ mode: data });
        break;
      case "frequency":
        this.setState(prevState => {
          return {
            recurringMode: {
              ...prevState.recurringMode,
              frequency: data
            }
          };
        });
        break;
      case "time":
        this.setState(prevState => {
          return {
            recurringMode: {
              ...prevState.recurringMode,
              time: data
            }
          };
        });
        break;
      case "datetime":
        this.setState(prevState => {
          return {
            datetimeEdited: true,
            oneTimeMode: {
              ...prevState.oneTimeMode,
              datetime: data
            }
          };
        });
        break;
      default:
        return;
    }
  };
  handleDelete = () => {
    this.setState({ deleting: true });
    this.props.handlers.handleDelete(() => {
      //we shouldnt reach this point if delete was ok since the calling app(lembretes) will dismiss this view.
      this.setState(prevState => ({
        errors: { ...prevState.errors, deleteError: true },
        deleting: false
      }));
    });
  };
  handleSave = () => {
    //we are creating a new one

    const errors = this.validateForm();

    if (errors.error) {
      //notify the user we cant save (form errors)
      this.setState({ errors });
    } else {
      this.setState({ saving: true });
      //hey its alright as far as adding/editing (all fields present)
      //so handle it to papa so that papa can save its new, validated state to the db,
      const {
        datetimeEdited,
        errors,
        deleting,
        saving,
        ...reminderData
      } = this.state;
      this.props.handlers.handleSave(reminderData, () => {
        this.setState(prevState => ({
          errors: { ...prevState.errors, saveError: true },
          saving: false
        }));
      });
    }
  };
  validateForm = () => {
    const errors = {
      error: false,
      infoError: false,
      tagsError: false,
      frequencyError: false,
      timeError: false,
      datetimeError: false,
      notificationTypeError: false
    };

    if (!this.state.info) {
      errors.error = true;
      errors.infoError = "Informação sobre o lembrete é obrigatória";
    }
    if (!this.state.notificationType) {
      errors.error = true;
      errors.notificationTypeError = "Selecione um tipo de notificação";
    }
    if (this.state.mode) {
      //recurring
      if (!this.state.recurringMode.frequency) {
        errors.error = true;
        errors.frequencyError = "A frequencia dos avisos é obrigatória";
      }
      if (!this.state.recurringMode.time) {
        errors.error = true;
        errors.timeError = "O horário do aviso é obrigatório";
      }
    } else {
      //onetime

      if (!isValid(this.state.oneTimeMode.datetime)) {
        errors.error = true;
        errors.datetimeError = "Forneça uma data para o aviso";
      }

      if (isBefore(this.state.oneTimeMode.datetime, new Date(), options)) {
        //datetime is before now?
        //cant warn you in  the past
        errors.error = true;
        errors.datetimeError = "Forneça uma data futura para o aviso";
      }
    }
    return errors;
  };

  handleTagDelete = tag => {
    const tags = this.state.tags.filter(t => {
      return t !== tag;
    });
    this.setState({ tags });
  };

  generateFormConfig = () => {
    let formConfig;
    let formState = { ...this.state };
    const { handleTourClose } = this.props;
    formConfig = [
      {
        formType: "text:multiline",
        name: "info",
        rowsMax: 5,
        rows: 1,
        label: "Informação",
        idForLabel: "edit-lembrete-info-form",
        tooltip: formState.errors.infoError
          ? formState.errors.infoError
          : "Tudo que você gostaria de ser lembrad@",
        initialState: formState.info,
        onChange: e => this.handleFormChange(e.target.value, "info"),
        dataProp: "data-addeditlembrete",
        error: formState.errors.infoError ? true : false,
        tour:
          typeof handleTourClose === "function"
            ? reminderAppTours.REMINDER_INFO
            : null
      },
      {
        formType: "tagbox",
        name: "tags-tagbox",
        title: "Tags",
        placeholder: "Digite uma tag e aperte Enter",
        initialState: formState.tags,
        onChange: tag => this.handleFormChange(tag, "tags"),
        onTagDelete: tag => this.handleTagDelete(tag),
        tooltip: "Ex. Poda, Rega, Semear, Limpar",
        tour:
          typeof handleTourClose === "function"
            ? reminderAppTours.REMINDER_TAGS
            : null
      },
      {
        formType: "select",
        name: "notificationType",
        options: [
          {
            value: "email_only",
            label: "Email (Todas plataformas)",
            selected: formState.notificationType === "email_only"
          },
          {
            value: "push_only",
            label: "Notificações (Android, Web)",
            selected: formState.notificationType === "push_only"
          },
          {
            value: "both",
            label: "Ambos",
            selected: formState.notificationType === "both"
          }
        ],
        tour:
          typeof handleTourClose === "function"
            ? reminderAppTours.REMINDER_NOTIFICATION_TYPE
            : null,
        onChange: e =>
          this.handleFormChange(e.target.value, "notificationType"),
        value: formState.notificationType ? formState.notificationType : "",
        selected: formState.notificationType ? true : false,
        tooltip: formState.errors.notificationTypeError
          ? formState.errors.notificationTypeError
          : "Como deseja receber a notificação",
        label: "Tipo de notificação",
        error: formState.errors.notificationTypeError ? true : false
      },
      {
        formType: "switch",
        tour:
          typeof handleTourClose === "function"
            ? reminderAppTours.REMINDER_TYPE
            : null,
        initialState: formState.mode,
        onChange: e => this.handleFormChange(e.target.checked, "mode"),
        label: formState.mode
          ? "Me lembre com uma frequencia"
          : "Me lembre uma vez",
        name: "mode",
        tooltip: formState.mode
          ? "Ex. A cada 2 dias"
          : "Ex. Dia 25/12/18 as 00:00",
        dataProp: "data-addeditlembrete"
      }
    ];
    if (formState.mode) {
      //adding recurring type
      const dayOptions = [];
      for (let i = 1; i < 31; i++) {
        dayOptions[i - 1] = {
          value: i,
          label: i === 1 ? "Todos" : i
        };
      }
      formConfig.push({
        formType: "select",
        name: "frenquency",
        options: dayOptions,
        onChange: event =>
          this.handleFormChange(event.target.value, "frequency"),
        label: "Me avisar a cada",
        dataProp: "data-configport",
        endAdornment: <InputAdornment position="end">dia(s)</InputAdornment>,
        error: formState.errors.frequencyError ? true : false,
        tooltip: formState.errors.frequencyError
          ? formState.errors.frequencyError
          : "",
        selected: this.props.mode === "editing" ? true : false,
        value:
          this.props.mode === "editing"
            ? formState.recurringMode.frequency
            : null
      });
      formConfig.push({
        formType: "text:time",
        name: "time",
        label: "Horário do aviso",
        initialState: formState.recurringMode.time,
        onChange: time => this.handleFormChange(time, "time"),
        extraProps: { cancelLabel: "Cancelar" }
      });
    } else {
      //adding one-time type
      formConfig.push({
        formType: "text:datetime",
        name: "datetime",
        label: "Data e hora",
        initialState: formState.datetimeEdited
          ? formState.oneTimeMode.datetime
          : addMinutes(formState.oneTimeMode.datetime, 15, options),
        onChange: datetime => this.handleFormChange(datetime, "datetime"),
        extraProps: {
          disablePast: true,
          cancelLabel: "Cancelar",
          minDate: new Date(),
          minDateMessage: "Forneça uma data futura para o aviso"
        }
      });
    }
    //dev todo bug where if we are ed

    return formConfig;
  };

  render() {
    if (this.state.deleting) {
      return <LoadingView absolute tooltip="Deletando" />;
    }
    if (this.state.saving) {
      return <LoadingView absolute tooltip="Salvando" />;
    }

    const { classes, title, handlers, mode, handleTourClose } = this.props;
    const steps = [
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_INFO}]`,
        content: (
          <StepContent title="Informação">
            <Typography component="p">
              Informação que gostaria de anexar ao lembrete.
            </Typography>
            <Typography component="p">
              <span>Ex. Aguar e podar plantas do Vegetativo</span>
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_TAGS}]`,
        content: (
          <StepContent title="Tags">
            <Typography component="p">
              Tags te ajudam a lembrar tudo que você precisa fazer para cada
              lembrete.
            </Typography>
            <Typography component="p">
              Para adicionar uma Tag digite o que gostaria de lembrar{" "}
            </Typography>
            <Typography component="p">
              <span>Ex. Planta#1, Galhos inferiores</span> e depois aperte{" "}
              <span>Enter</span>
            </Typography>
            <Typography component="p">
              Você pode adicionar quantas Tags quiser.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_NOTIFICATION_TYPE}]`,
        content: (
          <StepContent title="Tipo de Notificação">
            <Typography component="p">
              Você pode receber seus lembretes via{" "}
              <span>Notificação(Android e Computadores)</span>,{" "}
              <span>Emails (todas plataformas)</span> ou ambos.
            </Typography>
          </StepContent>
        )
      },
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_TYPE}]`,
        content: (
          <StepContent title="Lembrete: Repetido">
            <Typography component="p">
              Você pode escolher para ser notificado em frequência e horários
              específicos.
            </Typography>
            <Typography component="p">
              <span>Ex. A cada 2 dias as 16:20</span>
            </Typography>
          </StepContent>
        ),
        action: () =>
          this.state.mode === true ? null : this.setState({ mode: true })
      },
      {
        selector: `[data-tour=${reminderAppTours.REMINDER_TYPE}]`,
        content: (
          <StepContent title="Lembrete: Único">
            <Typography component="p">
              E você pode escolher para ser notificado em uma data e horário
              específico.
            </Typography>
            <Typography component="p">
              <span>Ex. No dia 20/04/2020 as 16:20</span>
            </Typography>
            <Typography component="p">
              Uma vez notificado um lembrete único jamais será disparado de
              novo.
            </Typography>
          </StepContent>
        ),
        action: () =>
          this.state.mode === false ? null : this.setState({ mode: false })
      },
      {
        selector: `[data-tour=${ADD_SAVE_FAB_BTN}]`,
        content: (
          <StepContent title="Salvar Lembrete">
            <Typography component="p">
              Após fazer alterações você deve <span>salvar o lembrete</span>{" "}
              para que você possa receber as notificações.
            </Typography>
          </StepContent>
        )
      }
    ];
    return (
      <BaseApp
        title={title}
        onSave={mode === "editing" ? null : this.handleSave}
        onDelete={mode === "editing" ? this.handleDelete : null}
        backBtnRoute={handlers.handleBackBtn}
        withTour
      >
        {typeof handleTourClose === "function" ? (
          <Tour
            steps={steps}
            onRequestClose={isFinished =>
              isFinished ? handleTourClose(true, true) : handleTourClose()
            }
          />
        ) : null}
        <Grid className={classes.formContainer} item xs={12}>
          <FormListGenerator fields={this.generateFormConfig()} />
        </Grid>
      </BaseApp>
    );
  }
}

export default withStyles(styles)(AddEditLembrete);

import {
  AUTH_USER,
  USER_UPDATE,
  REMINDERS_UPDATE,
  DIARYAPP_UPDATE,
  TELEMETRY_UPDATE,
  MESSAGING_UPDATE,
  ANALYTICS_UPDATE,
  CONTROLS_PRESETS_UPDATE
} from "./types";

//we logged in and got the user obj, lets update the main state
export const authUser = fullUserData => {
  return {
    type: AUTH_USER,
    payload: fullUserData || null
  };
};
export const updateUser = user => {
  return {
    type: USER_UPDATE,
    payload: user || null
  };
};
export const updateMessaging = messagingTokens => {
  return {
    type: MESSAGING_UPDATE,
    payload: messagingTokens || null
  };
};
export const updateReminders = reminders => {
  return {
    type: REMINDERS_UPDATE,
    payload: reminders
  };
};
export const updateDiaryEntries = entries => {
  return {
    type: DIARYAPP_UPDATE,
    payload: entries
  };
};
export const updateTelemetry = telemetry => {
  return {
    type: TELEMETRY_UPDATE,
    payload: telemetry
  };
};
export const updateControlPresets = presets => {
  return {
    type: CONTROLS_PRESETS_UPDATE,
    payload: presets
  };
};
export const updateAnalytics = analytics => {
  return {
    type: ANALYTICS_UPDATE,
    payload: analytics
  };
};

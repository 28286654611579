import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDiag from "../../../Helpers/ConfirmDiag";
import PropTypes from "prop-types";
import { ADD_SAVE_FAB_BTN } from "../../../../constants/tours";
const styles = theme => {
  return {
    fab: {
      position: "fixed",
      zIndex: 1250,
      bottom: "25%",
      right: theme.spacing(2)
    }
  };
};

class SaveItemFAB extends Component {
  state = {
    diag: false
  };
  onSave = () => {
    if (this.props.withConfirmation) {
      //show Dialog
      return this.setState({ diag: true });
    } else {
      this.props.onSave();
    }
  };
  onDiagConfirm = accept => {
    if (accept) {
      this.props.onSave();
    } else {
      this.setState({ diag: false });
    }
  };
  render() {
    const { className, classes, type, onSave, withTour } = this.props;
    return (
      <div>
        <Fab
          aria-label={type === "add" ? "Adicionar" : "Salvar"}
          className={classNames(classes.fab, className ? className : null)}
          onClick={onSave}
          color="primary"
          data-tour={withTour ? ADD_SAVE_FAB_BTN : null}
        >
          {type === "add" ? <AddIcon /> : <SaveIcon />}
        </Fab>
        {this.state.diag ? (
          <ConfirmDiag
            open={this.state.diag}
            title="Tem certeza que deseja salvar?"
            onConfirm={this.onDiagConfirm}
          />
        ) : null}
      </div>
    );
  }
}

SaveItemFAB.propTypes = {
  type: PropTypes.oneOf(["add", "save"]).isRequired,
  onSave: PropTypes.func.isRequired,
  withTour: PropTypes.bool
};
export default withStyles(styles)(SaveItemFAB);

import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker as MuiTimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
const useStyles = makeStyles(theme => {
  return {
    root: {
      width: "100%"
    }
  };
});
function TimerPicker(props) {
  const { label, date, handleChange, className, error, helperText } = props;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiTimePicker
        ampm={false}
        openTo="hours"
        views={["hours", "minutes", "seconds"]}
        format="HH:mm:ss"
        label={label ? label : "Escolha o horário"}
        value={date ? date : new Date()}
        onChange={handleChange}
        error={error}
        className={classNames(classes.root, className ? className : null)}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );
}
TimerPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string
};
export default TimerPicker;

import Compressor from "compressorjs";

export const compressImage = file => {
  return new Promise(function(resolve, reject) {
    new Compressor(file, {
      quality: 0.65,
      maxWidth: 1200,
      maxHeight: 1200,
      convertSize: 20000, //20kb
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      }
    });
  });
};

//deprecates select.js dev todo

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
const useStyles = makeStyles(theme => ({}));

export default function NativeSelects(props) {
  const { onChange, value, label, children, helper } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="filled-select-native-simple">{label}</InputLabel>
        <Select
          native
          value={value}
          onChange={onChange}
          input={<FilledInput name="age" id="filled-select-native-simple" />}
        >
          <option value="" />
          {children}
        </Select>
      </FormControl>
      {helper ? <FormHelperText>{helper}</FormHelperText> : null}
    </Grid>
  );
}

NativeSelects.propTypes = {
  children: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  helper: PropTypes.string
};

import { auth } from "./firebase";

//signup
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};
//signin
//async returns promise
export const doSignInWithEmailAndPassword = async (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};
export const doSignOut = () => {
  return auth.signOut();
};
//get that resetpass mail
export const doPasswordReset = email => {
  return auth.sendPasswordResetEmail(email);
};
//change pass
export const doPasswordUpdate = password => {
  return auth.currentUser.updatePassword(password);
};

export const renderFirebaseSignInError = (error, cb) => {
  let newError = "Não foi possível verificar seu acesso, tente novamente.";
  switch (error.code) {
    case "auth/invalid-email":
      newError = "Email de acesso inválido.";
      break;
    case "auth/user-not-found":
      newError = "Usuário não encontrado.";
      break;
    case "auth/wrong-password":
      newError = "Senha incorreta para o usuário.";
      break;
    default:
      break;
  }
  return cb(rendeFirebaseGeneralError(error, newError));
};
export const renderFirebaseSignUpError = (error, cb) => {
  let newError = "Não foi possível verificar a conta. Tente novamente.";
  switch (error.code) {
    case "auth/email-already-in-use":
      newError = "Esse email de acesso já está em uso.";
      break;
    case "auth/invalid-email":
      newError = "Por favor forneça um email válido.";
      break;
    case "auth/weak-password":
      newError = "Por favor escolha uma senha mais longa.";
      break;
    default:
      break;
  }
  return cb(rendeFirebaseGeneralError(error, newError));
};
const rendeFirebaseGeneralError = (error, newError) => {
  if (error.code === "auth/network-request-failed") {
    return "Não foi possível conectar ao serviço de contas. Tente novamente.";
  }
  return newError;
};

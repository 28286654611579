import { AUTH_USER, REMINDERS_UPDATE } from "../actions/types";
export default function(state = [], action) {
  switch (action.type) {
    case AUTH_USER:
      return action.payload.reminders || state;
    case REMINDERS_UPDATE:
      return action.payload;
    default:
      return state;
  }
}

import * as React from "react";
import * as actions from "../actions";
import { firebase, db } from "../firebase";
import LoadingView from "../components/Helpers/LoadingView";
import { connect } from "react-redux";

const defaultFirebaseContext = {
  authStatusReported: false,
  isUserSignedIn: false
};

export const FirebaseAuthContext = React.createContext(defaultFirebaseContext);

class FirebaseAuthProvider extends React.Component {
  state = defaultFirebaseContext;

  componentDidMount() {
    firebase.auth.onAuthStateChanged(user => {
      if (user) {
        this.props.updateUser(user.providerData[0]);
        db.listenForUserUpdate(this.props.updateUser);
      }

      this.setState({
        authStatusReported: true,
        isUserSignedIn: !!user
      });
    });
  }

  render(): React.Node {
    const { children } = this.props;
    const { authStatusReported, isUserSignedIn } = this.state;
    return (
      <FirebaseAuthContext.Provider
        value={{ isUserSignedIn, authStatusReported }}
      >
        {authStatusReported ? children : <LoadingView />}
      </FirebaseAuthContext.Provider>
    );
  }
}

export default connect(
  null,
  actions
)(FirebaseAuthProvider);

//take string -> sec, min, day, hour
//return pt-br string 'human formated'
export default function parseTimeScale(scale) {
  switch (scale) {
    case "sec":
      return "Segundos";
    case "min":
      return "Minutos";
    case "hour":
      return "Horas";
    case "day":
      return "Dias";
    default:
      return "Dias";
  }
}

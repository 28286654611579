import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { controlsAppTours } from "../../../constants/tours";
import { controlPresets } from "../../../constants/controlPresets";
import PropTypes from "prop-types";
import classNames from "classnames";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import parseTimeScale from "../../Helpers/parseTimeScale";
import { ptBR } from "date-fns/locale";
import moment from "moment-timezone";
import {
  format,
  addHours,
  subMinutes,
  addMinutes,
  getUnixTime,
  isPast,
  formatDistanceToNow,
  fromUnixTime
} from "date-fns";
import parseDate from "../../Helpers/parseDate";
const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: 0,
      background: "#e6e6e6",
      borderRadius: theme.shape.borderRadius,
      margin: `${theme.spacing(2)}px 0px`,
      boxShadow: theme.shadows[2]
    },
    portNameContainer: {
      background:
        "linear-gradient(0deg, rgba(67,160,71,1) 0%, rgba(139,195,74,1) 100%)"
    },
    ruleListItem: {
      background: theme.palette.common.white,
      borderRadius: 0,
      flex: 1,
      padding: theme.spacing(2),
      boxShadow: "none",
      textAlign: "left",
      "& p": {
        "& > span": {
          fontWeight: 500
        }
      }
    },
    ruleSubheader: {
      borderBottom: "1px solid #666"
    },
    rulesListLine: {
      padding: 0,
      minHeight: "100%"
    },

    ruleRootContainer: {
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.grey[400]}`
      }
    },
    portListRoot: {
      flex: 1,
      borderRadius: theme.shape.borderRadius,

      textAlign: "center",

      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&.purple": {
        background: "linear-gradient(60deg, #ab47bc, #ab47bc)"
      },
      "&.orange": {
        background: "linear-gradient(60deg, #fb8c00, #fb8c00)"
      },
      "&.cyan": {
        background: "linear-gradient(60deg, #00acc1, #00acc1)"
      },
      "&.pink": {
        background: "linear-gradient(60deg, #f73378, #f73378)"
      },
      "& h3": {
        marginBottom: 0
      },
      "& p": {
        marginTop: 0,
        fontSize: "75%"
      }
    },
    ruleName: {
      fontSize: "18px !important",
      padding: theme.spacing(1),
      maxWidth: "100%",
      marginBottom: 0,
      wordWrap: "break-word",
      fontWeight: 900,
      color: theme.palette.common.white
    },
    ruleDescriptionButton: {
      flex: 1
    },
    deviceStatusContainer: {
      padding: theme.spacing(1)
    },
    deviceOnline: {
      background:
        "linear-gradient(60deg, rgb(143, 216, 57),rgb(104, 179, 107))",
      flex: 1,
      borderRadius: 0,
      boxShadow: "none"
    },
    deviceOffline: {
      flex: 1,
      borderRadius: 0,
      background: "linear-gradient(60deg,#f15239,#ec8364)",
      boxShadow: "none"
    }
  };
});
const RuleRow = props => {
  const { preset, onPortNameEdit, onPortSelected, portState } = props;

  const classes = useStyles();
  function calcNextExecution() {
    const sec = 1;
    const min = sec * 60;
    const hour = min * 60;
    const day = hour * 24;
    const times = {
      sec,
      min,
      hour,
      day
    };

    const hourNow = getUnixTime(new Date());

    const programDuration =
      preset.options.duration +
      preset.options.frequency * times[preset.options.frequencyTimeScale];
    let baseStart = parseDate(preset.options.start.split(/[-+ :T]/));
    if (isPast(baseStart)) {
      baseStart = getUnixTime(baseStart) + programDuration; //the stop of the first ever program for this rule + its frequency
    } else {
      baseStart = getUnixTime(baseStart);
    }
    const timeGap = hourNow - baseStart;
    const programsSinceStart = Math.ceil(timeGap / programDuration);
    if (programsSinceStart > 1) {
      baseStart = baseStart + programsSinceStart * programDuration;
    }

    return formatDistanceToNow(fromUnixTime(baseStart), {
      locale: ptBR
    });
  }
  function renderTypography() {
    let presetText;
    switch (preset.value) {
      case controlPresets.MANUAL:
        if (preset.options.mode) {
          presetText = (
            <React.Fragment>
              <Typography>Modo Manual</Typography>
              <Typography>
                Manter sempre <span>ligado.</span>
              </Typography>
            </React.Fragment>
          );
        } else {
          presetText = (
            <Typography>
              Manter sempre <span>desligado.</span>
            </Typography>
          );
        }
        break;
      case controlPresets.PHOTOPERIOD:
        const photoperiodStartTime = new Date(preset.options.startTime);
        presetText = (
          <React.Fragment>
            <Typography>
              Modo Fotoperíodo para{" "}
              <span>
                {preset.options.cycle === "veg" ? "Vegetativo" : "Floração"}
              </span>
            </Typography>

            <Typography>
              {preset.options.cycleValue === 24
                ? "Ligado o tempo todo, 24/7."
                : `Ligado diariamente das ${format(
                    photoperiodStartTime,
                    "HH:mm:ss"
                  )} as ${format(
                    addHours(photoperiodStartTime, preset.options.cycleValue),
                    "HH:mm:ss"
                  )} `}
            </Typography>
          </React.Fragment>
        );
        break;
      case controlPresets.EMERSON:
        const emersonStart = new Date(preset.options.startTime);
        const emersonStop = new Date(preset.options.stopTime);
        const emersonMinutesOfLight = preset.options.minutesOfLight;
        const emersonParsedStart = {
          start: format(
            subMinutes(emersonStart, emersonMinutesOfLight),
            "HH:mm:ss"
          ),
          stop: format(emersonStart, "HH:mm:ss")
        };
        const emersonParsedStop = {
          start: format(emersonStop, "HH:mm:ss"),
          stop: format(
            addMinutes(emersonStop, emersonMinutesOfLight),
            "HH:mm:ss"
          )
        };
        presetText = (
          <React.Fragment>
            <Typography>
              Modo
              <span> Efeito Emerson</span>
            </Typography>
            <Typography>Ligado Diariamente antes da luz principal:</Typography>
            <Typography>{`Das ${emersonParsedStart.start} até ${emersonParsedStart.stop}`}</Typography>
            <Typography>E após a luz principal:</Typography>
            <Typography>{`Das ${emersonParsedStop.start} até ${emersonParsedStop.stop}`}</Typography>
          </React.Fragment>
        );
        break;
      case controlPresets.SENSOR:
        const sensorType =
          preset.options.sensorType === "temp" ? "Temperatura" : "Umidade";
        const sensorComparator =
          preset.options.comparator === "more_than" ? "maior que" : "menor que";
        const sensorUnit = preset.options.sensorType === "temp" ? "°" : "%";
        const sensorAction =
          preset.options.action === true ? "ligado" : "desligado";
        presetText = (
          <React.Fragment>
            <Typography>
              Modo
              <span> Controle de Ambiente</span>
            </Typography>
            <Typography>
              Controlando a <span>{sensorType}</span>
            </Typography>
            <Typography>{`Se a ${sensorType.toLowerCase()} for ${sensorComparator} ${
              preset.options.value
            }${sensorUnit} manter ${sensorAction}.`}</Typography>
          </React.Fragment>
        );
        break;
      case controlPresets.AUTOWATER:
        if (preset.options.frequency > 0) {
          //repeat
          const autowaterRepeatScale = preset.options.frequencyTimeScale
            ? parseTimeScale(preset.options.frequencyTimeScale).toLowerCase()
            : null;

          presetText = (
            <React.Fragment>
              <Typography>
                Modo
                <span> Rega Automática</span>
              </Typography>
              <Typography>
                <span>Duração:</span> {preset.options.duration} segundos
              </Typography>
              <Typography>
                Se repete automaticamente a cada {preset.options.frequency}{" "}
                {autowaterRepeatScale}
              </Typography>
              <Typography>{`A próxima rega será em ${calcNextExecution()}`}</Typography>
            </React.Fragment>
          );
        } else {
          //one time
          presetText = (
            <React.Fragment>
              <Typography>
                Modo <span> Rega Automática</span>
              </Typography>
              <Typography>
                <span>Duração:</span> {preset.options.duration} segundos
              </Typography>
              <Typography>
                {isPast(new Date(preset.options.start))
                  ? `Essa rega executou a ${formatDistanceToNow(
                      new Date(preset.options.start),
                      {
                        locale: ptBR
                      }
                    )}`
                  : `Essa rega irá executar em ${formatDistanceToNow(
                      new Date(preset.options.start),
                      { locale: ptBR }
                    )}`}
              </Typography>
              <Typography>
                E <span>não</span> se repetirá automaticamente.
              </Typography>
            </React.Fragment>
          );
        }

        break;
      case controlPresets.CUSTOM:
        if (preset.options.type === "freq") {
          if (preset.options.frequency > 0) {
            const customRepeatScale = preset.options.frequencyTimeScale
              ? parseTimeScale(preset.options.frequencyTimeScale).toLowerCase()
              : null;

            presetText = (
              <React.Fragment>
                <Typography>
                  Modo <span> Customizado</span>
                </Typography>
                <Typography>
                  <span>Duração:</span> {preset.options.duration}{" "}
                  {parseTimeScale(
                    preset.options.durationTimeScale
                  ).toLowerCase()}
                </Typography>
                <Typography>
                  Se repete automaticamente a cada {preset.options.frequency}{" "}
                  {customRepeatScale}
                </Typography>
                <Typography>{`A próxima execução será em ${calcNextExecution()}`}</Typography>
              </React.Fragment>
            );
          } else {
            presetText = (
              <React.Fragment>
                <Typography>
                  Modo <span> Customizado</span>
                </Typography>
                <Typography>
                  <span>Duração:</span> {preset.options.duration}{" "}
                  {parseTimeScale(
                    preset.options.durationTimeScale
                  ).toLowerCase()}
                </Typography>
                <Typography>
                  {isPast(new Date(preset.options.start))
                    ? `Essa regra executou a ${formatDistanceToNow(
                        new Date(preset.options.start),
                        {
                          locale: ptBR
                        }
                      )}`
                    : `Essa regra irá executar em ${formatDistanceToNow(
                        new Date(preset.options.start),
                        { locale: ptBR }
                      )}`}
                </Typography>
                <Typography>
                  E <span>não</span> se repetirá automaticamente.
                </Typography>
              </React.Fragment>
            );
          }
        } else if (preset.options.type === "daily") {
          const rangeList = preset.options.rules.map(rule => {
            return (
              <Typography key={rule.id}>{`Das ${format(
                moment(rule.start)
                  .tz("America/Sao_Paulo")
                  .toDate(),
                "HH:mm:ss"
              )} até ${format(
                moment(rule.stop)
                  .tz("America/Sao_Paulo")
                  .toDate(),
                "HH:mm:ss"
              )}`}</Typography>
            );
          });
          presetText = (
            <React.Fragment>
              <Typography>
                Modo <span> Customizado</span>
              </Typography>
              <Typography>Ligado Diariamente:</Typography>
              <React.Fragment>{rangeList}</React.Fragment>
            </React.Fragment>
          );
        }
        break;
      default:
        break;
    }
    return presetText;
  }

  return (
    <Grid container className={classes.root}>
      <Grid
        xs={4}
        item
        container
        className={classes.portNameContainer}
        data-tour={
          preset.value === "port1" ? controlsAppTours.CONTROL_LIST_NAME : null
        }
      >
        <ButtonBase
          onClick={() => onPortNameEdit(preset.portKey)}
          className={classNames(classes.portListRoot)}
        >
          <div className={classNames(classes.portListRoot)}>
            <p className={classes.ruleName}>{preset.portName}</p>
          </div>
        </ButtonBase>
      </Grid>
      <Grid
        className={classes.deviceStatusContainer}
        xs={1}
        item
        container
        data-tour={
          preset.portKey === "port1"
            ? controlsAppTours.CONTROL_LIST_STATUS
            : null
        }
      >
        <div
          className={
            portState === 1 ? classes.deviceOnline : classes.deviceOffline
          }
        >
          &nbsp;
        </div>
      </Grid>
      <Grid
        xs={7}
        item
        container
        data-tour={
          preset.portKey === "port1" ? controlsAppTours.CONTROL_LIST_DESC : null
        }
      >
        <ButtonBase
          onClick={() => onPortSelected(preset.portKey)}
          className={classes.ruleDescriptionButton}
        >
          <div className={classes.ruleListItem}>{renderTypography()}</div>
        </ButtonBase>
      </Grid>
    </Grid>
  );
};
RuleRow.propTypes = {
  preset: PropTypes.object.isRequired,
  onPortNameEdit: PropTypes.func.isRequired,
  onPortSelected: PropTypes.func.isRequired,
  portState: PropTypes.number
};
export default RuleRow;

import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import purple from "@material-ui/core/colors/purple";

import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import classNames from "classnames";

const Button = styled(MuiButton)({
  color: "white"
});

const useStyles = makeStyles(theme => {
  return {
    root: {
      borderColor: theme.palette.grey[200]
    },
    green: {
      background: green[200],
      "&.selected": {
        background: green[500]
      }
    },
    red: {
      background: red[100],
      "&.selected": {
        background: red[300]
      }
    },
    purple: {
      background: purple[100],
      "&.selected": {
        background: purple[300]
      }
    },
    orange: {
      background: orange[200],
      "&.selected": {
        background: orange[300]
      }
    }
  };
});
function ButtonGroup(props) {
  const { size, items } = props;
  const classes = useStyles();
  function parseButtons() {
    return items.map((item, i) => {
      return (
        <Button
          className={classNames(
            item.color ? classes[item.color] : null,
            item.selected ? "selected" : null
          )}
          key={i}
          onClick={item.onClick}
        >
          {item.icon ? item.icon : null}
          {item.label}
        </Button>
      );
    });
  }
  return (
    <MuiButtonGroup
      classes={{ root: classes.root }}
      size={size ? size : "large"}
      aria-label="large contained secondary button group"
      fullWidth
    >
      {parseButtons()}
    </MuiButtonGroup>
  );
}

ButtonGroup.propTypes = {
  items: PropTypes.array.isRequired,
  size: PropTypes.string,
  styles: PropTypes.object
};
export default ButtonGroup;

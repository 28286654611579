import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import OverviewAppCard from "../AppCard/OverviewAppCard";
import LembreteAppCard from "../AppCard/LembreteAppCard";
import DiarioAppCard from "../AppCard/DiarioAppCard";
import ControleAppCard from "../AppCard/ControleAppCard";
import { usersDb } from "../../constants/db";
import { appCardTours, FEEDBACK_BTN_TOUR } from "../../constants/tours";
import * as views from "../../constants/views";
import { connect } from "react-redux";
import LoadingView from "../Helpers/LoadingView";
import BaseApp from "./BaseApp";
import Tour from "../UI/Tour/Tour";
import StepContent from "../UI/Tour/StepContent";
import { db, functions } from "../../firebase";

import Diag from "../UI/Diag/Diag";

const styles = (theme) => {
  return {
    gridcols: {
      "&.firstGridCol": {
        padding: 0,
        paddingRight: theme.spacing(1),
      },
      "&.secondGridCol": {
        padding: 0,
        paddingLeft: theme.spacing(1),
      },
    },
    screenTitle: {
      margin: 0,
      color: "#555",
      fontSize: 20,
    },
  };
};
const tourSteps = (classes) => [
  {
    selector: `[data-tour=${appCardTours.OVERVIEW_APP_TOUR}]`,
    content: (
      <StepContent title={views.OVERVIEW_APP_TITLE}>
        <Typography component="p">
          Aqui você pode acompanhar os <span>sensores</span> do seu cultivo em{" "}
          <span>tempo real</span>.
        </Typography>
        <Typography component="p">
          Clicando em qualquer lugar no App você pode <span>ver mais</span>{" "}
          dados relevantes!
        </Typography>
      </StepContent>
    ),
  },
  {
    selector: `[data-tour=${appCardTours.CONTROLS_APP_TOUR}]`,
    content: (
      <StepContent title={views.CONTROLS_APP_TITLE}>
        <Typography component="p">
          Aqui você pode controlar seu cultivo <span>de onde estiver</span>.
        </Typography>
        <Typography component="p">
          Você também pode programar seu para funcionar{" "}
          <span>automaticamente</span>!
        </Typography>
      </StepContent>
    ),
  },
  {
    selector: `[data-tour=${appCardTours.DIARY_APP_TOUR}]`,
    content: (
      <StepContent title={views.DIARY_APP_TITLE}>
        <Typography component="p">
          Aqui você pode recordar sua jornada como cultivador.{" "}
        </Typography>
        <Typography component="p">
          <span>Envies fotos e crie notas</span> sobre todos seus cultivos e
          experimentos.
        </Typography>
      </StepContent>
    ),
  },
  {
    selector: `[data-tour=${appCardTours.REMINDERS_APP_TOUR}]`,
    content: (
      <StepContent title={views.REMINDERS_APP_TITLE}>
        <Typography component="p">
          Aqui você pode <span>programar avisos</span> frequentes ou de execução
          única.
        </Typography>{" "}
        <Typography component="p">
          Você receberá seus avisos via <span>email e/ou notificações.</span>
        </Typography>
      </StepContent>
    ),
  },
  {
    selector: `[data-tour=${FEEDBACK_BTN_TOUR}]`,
    content: (
      <StepContent title="Feedback">
        <Typography component="p">
          Aqui você pode reportar <span>problemas</span>, <span>ideias</span>,{" "}
          <span>melhorias</span>, <span>dúvidas</span>, o que der na telha!
        </Typography>
        <Typography component="p">
          Que tal <span>mandar uma mensagem</span> pra gente pra comemorar que
          você terminou esse tutorial?! É facil! Clica no botão... clica?
        </Typography>
        <Typography component="p">
          <span>Estamos esperando :)</span>
        </Typography>
      </StepContent>
    ),
  },
];
class DashboardApp extends Component {
  state = {
    savingPendingDevice: false,
    savingPendingAccessory: false,
  };
  componentDidMount() {
    if (this.props.user && this.props.user.tours) {
      const { user } = this.props;
      if (!user.tours.dashboard || !user.tours) {
        //user has not completed tours.dashboard
        this.setState({ tour: true });
      }
    }
  }
  componentDidUpdate() {
    if (!this.state.tour && !this.state.tourCanceled) {
      //we not touring already
      if (this.props.user) {
        //got the user
        const { user } = this.props;
        if (
          user.deviceId &&
          user.deviceId !== "" &&
          (!user.tours || !user.tours.dashboard)
        ) {
          //user has not completed tours.dashboard
          this.setState({ tour: true });
        }
      }
    }
  }
  handleTourClose = (isFinished) => {
    if (isFinished) {
      db.doUpdateUserDocInCollection(
        {
          tours: {
            ...this.props.user.tours,
            dashboard: true,
          },
        },
        usersDb
      );
      this.setState({ tour: false, tourCanceled: true });
    } else {
      this.setState({ tour: false, tourCanceled: true });
    }
  };
  renderAccessoryDialog = (sensors) => {
    console.log(sensors);
    const response = {
      allow: true,
      accessoryDeviceType: sensors.pendingAccessories.deviceType,
      accessoryDeviceId: sensors.pendingAccessories.deviceId,
      deviceId: sensors.deviceId,
      deviceType: sensors.deviceType,
      accessoryAddr: sensors.pendingAccessories.addr,
    };
    return (
      <Diag
        title={`O aparelho ${sensors.pendingAccessories.deviceId.toUpperCase()} do tipo ${
          sensors.pendingAccessories.deviceType === "hgbmeble"
            ? "Sensor Bluetooth"
            : "desconhecido"
        } quer acessar sua conta`}
        alignCenter
        content={
          this.state.savingPendingAccessory ? (
            <LoadingView absolute tooltip="Salvando" />
          ) : (
            `Um novo acessório Bluetooth de Temperatura e Umidade quer se associar a conta Tribe, se você reconhece o nome do aparelho(${sensors.pendingAccessories.deviceId.toUpperCase()}) clique em autorizar para começar a receber dados.`
          )
        }
        onClose={async () => {
          this.setState({ savingPendingAccessory: true });
          try {
            response.allow = false;
            await functions.managePendingAccessories(response);
            this.setState({ savingPendingAccessory: false });
          } catch (e) {
            this.setState({ savingPendingAccessory: false });
          }
        }}
        onSave={async () => {
          this.setState({ savingPendingAccessory: true });
          try {
            await functions.managePendingAccessories(response);
            this.setState({ savingPendingAccessory: false });
          } catch (e) {
            this.setState({ savingPendingAccessory: false });
          }
        }}
        saveBtnTitle="Autorizar"
      />
    );
  };
  renderNewLeaferDialog = (user) => {
    return (
      <Diag
        title={`O aparelho ${user.pendingDevices[0].deviceName} quer acessar sua conta`}
        alignCenter
        content={
          this.state.savingPendingDevice ? (
            <LoadingView absolute tooltip="Salvando" />
          ) : (
            `Um novo aparelho quer se associar a conta Tribe, se você reconhece o nome do aparelho(${user.pendingDevices[0].deviceName}) clique em autorizar para adicioná-lo a sua conta.`
          )
        }
        onClose={async () => {
          this.setState({ savingPendingDevice: true });
          try {
            await functions.managePendingDevices("deny");
          } catch (e) {
            this.setState({ savingPendingDevice: false });
          }
        }}
        onSave={async () => {
          this.setState({ savingPendingDevice: true });
          try {
            await functions.managePendingDevices("allow");
          } catch (e) {
            this.setState({ savingPendingDevice: false });
          }
        }}
        saveBtnTitle="Autorizar"
      />
    );
  };
  parseContent = () => {
    const { user, classes, sensors } = this.props;

    if (user.pendingDevices && user.pendingDevices.length) {
      return this.renderNewLeaferDialog(user);
    }
    if (
      sensors &&
      sensors.pendingAccessories &&
      sensors.pendingAccessories.deviceId
    ) {
      return this.renderAccessoryDialog(sensors);
    }
    if (user.deviceId) {
      if (
        sensors &&
        sensors.fw_version &&
        sensors.fw_version.indexOf("1") === 0
      ) {
        return (
          <LoadingView
            tooltip="Seu aparelho está desatualizado! Entre em contato com a Homegrown para atualizar sua versão sem custos."
            absolute
          />
        );
      }
      return (
        <React.Fragment>
          <Grid
            item
            xs={6}
            className={classNames(classes.gridcols, "firstGridCol")}
          >
            <Grid container direction="column">
              <Grid item>
                <OverviewAppCard tour={appCardTours.OVERVIEW_APP_TOUR} />
              </Grid>
              <Grid item>
                <DiarioAppCard tour={appCardTours.DIARY_APP_TOUR} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            className={classNames(classes.gridcols, "secondGridCol")}
          >
            <Grid container direction="column">
              <Grid item>
                <ControleAppCard tour={appCardTours.CONTROLS_APP_TOUR} />
              </Grid>
              <Grid item>
                <LembreteAppCard tour={appCardTours.REMINDERS_APP_TOUR} />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return <LoadingView tooltip="Aguarde. Buscando seu aparelho." absolute />;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <BaseApp>
        {this.state.tour ? (
          <Tour
            steps={tourSteps(classes)}
            onRequestClose={this.handleTourClose}
          />
        ) : null}
        <Grid container>{this.parseContent()}</Grid>
      </BaseApp>
    );
  }
}

function mapStateToProps({ user, sensors }) {
  return { user, sensors };
}
export default connect(mapStateToProps)(withStyles(styles)(DashboardApp));

import React from "react";
import PropTypes from "prop-types";
import PresetPhotoperiod from "./PresetPhotoperiod";
import PresetEmerson from "./PresetEmerson";
import PresetSensor from "./PresetSensor";
import PresetAutowater from "./PresetAutowater";
import PresetCustom from "./PresetCustom/PresetCustom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import {
  controlPresets,
  controlPresetImgs,
  controlPresetLabels,
  controlPresetInfo
} from "../../../../../../constants/controlPresets";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => {
  return {
    resetPresetBtn: {
      textAlign: "right"
    },
    resetPresetBtnText: {
      margin: 0
    }
  };
});
function AutoRule(props) {
  const { preset, handleSave } = props;
  const classes = useStyles();
  const [presetValue, setPresetValue] = React.useState(() => {
    if (preset && preset.value && preset.value !== controlPresets.MANUAL) {
      return preset.value;
    } else {
      return null;
    }
  });
  const [editingPreset, setEditingPreset] = React.useState(false);
  function parsePreset() {
    switch (presetValue) {
      case controlPresets.PHOTOPERIOD:
        return (
          <PresetPhotoperiod
            onClose={() => setEditingPreset(false)}
            handleSave={handleSave}
            preset={preset}
          />
        );
      case controlPresets.EMERSON:
        return (
          <PresetEmerson
            handleSave={handleSave}
            preset={preset}
            onClose={() => setEditingPreset(false)}
          />
        );
      case controlPresets.SENSOR:
        return (
          <PresetSensor
            handleSave={handleSave}
            preset={preset}
            onClose={() => setEditingPreset(false)}
          />
        );
      case controlPresets.AUTOWATER:
        return (
          <PresetAutowater
            handleSave={handleSave}
            preset={preset}
            onClose={() => setEditingPreset(false)}
          />
        );
      case controlPresets.CUSTOM:
        return (
          <PresetCustom
            handleSave={handleSave}
            preset={preset}
            onClose={() => setEditingPreset(false)}
          />
        );
      default:
        return;
    }
  }

  function parsePresetList() {
    const presetList = [];
    for (let cPreset in controlPresets) {
      if ((presetValue && cPreset !== presetValue) || cPreset === controlPresets.MANUAL) {
        //if there is a selected preset and were not parsing it or if its the manual preset, do nothing.
        continue;
      }
      presetList.push(
        <ListItem
          button
          key={cPreset}
          alignItems="flex-start"
          onClick={() => {
            setPresetValue(cPreset);
            setEditingPreset(true);
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt={controlPresetLabels[cPreset + "_LABEL"]}
              src={controlPresetImgs[cPreset + "_IMG"]}
            />
          </ListItemAvatar>
          <ListItemText
            primary={controlPresetLabels[cPreset + "_LABEL"]}
            secondary={
              <React.Fragment>
                <Typography component="span">{controlPresetInfo[cPreset + "_INFO"]}</Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      );
    }
    const formatedPresetList = [];
    presetList.forEach((item, i) => {
      formatedPresetList.push(item);
      if (i < presetList.length - 1) {
        formatedPresetList.push(<Divider key={i} variant="inset" component="li" />);
      }
    });
    return formatedPresetList;
  }
  function resetNewPreset() {
    setPresetValue("");
    setEditingPreset(false);
  }
  return (
    <Grid container>
      {editingPreset ? parsePreset() : null}
      <Grid container item xs={12}>
        <Grid item xs={presetValue ? 6 : 12}>
          <Typography>Modo Automático</Typography>
          <Typography variant="caption">
            {presetValue
              ? controlPresetLabels[presetValue + "_LABEL"]
              : "Escolha como quer configurar"}
          </Typography>
        </Grid>
        {presetValue ? (
          <Grid item container justify="flex-end" xs={6}>
            <ButtonBase onClick={resetNewPreset} component="div" className={classes.resetPresetBtn}>
              <Grid container>
                <Grid item xs={12}>
                  <p className={classes.resetPresetBtnText}>Quer mudar o tipo?</p>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.resetPresetBtnText}>Clique aqui!</p>
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <List>{parsePresetList()}</List>
        </Grid>
      </Grid>
    </Grid>
  );
}
AutoRule.propTypes = {
  preset: PropTypes.object,
  handleSave: PropTypes.func.isRequired
};
export default AutoRule;

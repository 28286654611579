//general feedback button content to appear in all app's walkthought
import React from "react";
import StepContent from "../StepContent";
import Typography from "@material-ui/core/Typography";

export default function(props) {
  return (
    <StepContent title="Feedback">
      <Typography component="p">
        Assim como em todos nossos Apps, caso tenha <span>ideias</span>,{" "}
        <span>melhorias</span>, <span>problemas</span>, <span>dúvidas</span>{" "}
        basta usar esse botão.
      </Typography>
      <Typography component="p">
        Nós lemos <span>todas mensagens.</span> Pode confiar! :)
      </Typography>
      <Typography component="p">
        É com a <span>sua participação</span> que podemos <span>melhorar</span>{" "}
        sempre!
      </Typography>
    </StepContent>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import Button from "../../../../../../UI/Button/Button/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CustomDailyRule from "./CustomDaily/CustomDaily";
import CustomFrequencyRule from "./CustomFrequency";
import Diag from "../../../../../../UI/Diag/Diag";
import LoadingView from "../../../../../../Helpers/LoadingView";
import PropTypes from "prop-types";
import {
  controlPresetLabels,
  controlPresets,
  timeFormatString
} from "../../../../../../../constants/controlPresets";
import { isValid, isPast, isEqual, format } from "date-fns";
import { functions } from "../../../../../../../firebase";
import parseDate from "../../../../../../Helpers/parseDate";
import uniqid from "uniqid";

import timeRangesOverlap from "./helpers/timeRangesOverlap";

const useStyles = makeStyles(theme => {
  return {
    photoperiodModeBtnGroup: {
      borderColor: theme.palette.grey[200]
    },
    textField: {
      fontSize: theme.typography.h1.fontSize
    },
    input: {
      textAlign: "center"
    },
    ruleContainer: {
      paddingLeft: theme.spacing(2) + "px !important",
      paddingRight: theme.spacing(2) + "px !important"
    },
    dailyRulePicker: {
      padding: theme.spacing(1)
    },
    dailyRuleNumberContainer: {
      background: theme.palette.grey[300],
      padding: theme.spacing(1),
      height: "100%"
    },
    dailyRuleNumberText: {
      fontWeight: 700
    },
    dailyRuleAddButton: {
      color: theme.palette.primary.main,
      fontSize: "2 rem"
    }
  };
});
function PresetCustom(props) {
  const { preset, onClose, handleSave } = props;
  const classes = useStyles();
  const [isSaving, setIsSaving] = React.useState(false);
  const [currentType, setCurrentType] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM) {
        return preset.options.type || "daily";
      }
    }
    return "daily";
  });
  const [dailyRules, setDailyRules] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM) {
        return preset.options.rules || [];
      }
    }
    return [];
  });

  const [freqRepeat, setFreqRepeat] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM) {
        if (
          typeof preset.options.frequency === "number" &&
          preset.options.frequency > 0
        ) {
          return true;
        }
      }
    }
    return false;
  });
  const [freqStartDate, setFreqStartDate] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM && preset.options.start) {
        return parseDate(preset.options.start.split(/[-+ :T]/));
      }
    }
    return new Date();
  });
  const [freqStartTime, setFreqStartTime] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM && preset.options.start) {
        return parseDate(preset.options.start.split(/[-+ :T]/));
      }
    }
    return new Date();
  });
  const [freqFrequencyTimeScale, setFreqFrequencyTimeScale] = React.useState(
    () => {
      if (preset && preset.options) {
        if (preset.value === controlPresets.CUSTOM) {
          return preset.options.frequencyTimeScale || "day";
          //user may save a non reepeating rule, so timescale wouldnt be saved, in that case we also default to 'day'
        }
      }
      return "day";
    }
  );
  const [freqFrequency, setFreqFrequency] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM) {
        return String(preset.options.frequency);
      }
    }
    return "";
  });
  const [freqDurationTimeScale, setFreqDurationTimeScale] = React.useState(
    () => {
      if (preset && preset.options) {
        if (preset.value === controlPresets.CUSTOM) {
          return preset.options.durationTimeScale || "sec";
          //user may save a non reepeating rule, so timescale wouldnt be saved, in that case we also default to 'sec'
        }
      }
      return "sec";
    }
  );
  const [freqDuration, setFreqDuration] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.CUSTOM) {
        return String(preset.options.duration);
      }
    }
    return "";
  });
  const [presetError, setPresetError] = React.useState({});
  function onFreqSave() {
    if (currentType === "freq") {
      setIsSaving(true);
      const options = {};

      if (isEqual(new Date(preset.options.start), freqStartTime)) {
        setPresetError({ error: true, where: "freq-start" });
        setIsSaving(false);
        return;
      }
      if (
        freqDuration &&
        freqDuration > 0 &&
        isValid(freqStartTime) &&
        !isPast(freqStartTime) &&
        freqDurationTimeScale
      ) {
        options.type = currentType;
        options.duration = parseInt(freqDuration, 10);
        options.durationTimeScale = freqDurationTimeScale;
        options.start = format(freqStartTime, timeFormatString);
        options.frequency = 0; //reset

        if (freqRepeat) {
          if (freqFrequency && freqFrequencyTimeScale) {
            if (freqFrequencyTimeScale === "sec" && freqFrequency < 3) {
              setPresetError({ error: true, where: "freq-repeat" });
              setIsSaving(false);
              return;
            }
            options.frequency = parseInt(freqFrequency, 10);
            options.frequencyTimeScale = freqFrequencyTimeScale;
          } else {
            setPresetError({ error: true, where: "freq-repeat" });
            setIsSaving(false);
            return;
          }
        }

        functions
          .saveCustomFreqRule({
            portKey: preset.portKey,
            value: controlPresets.CUSTOM,
            options
          })
          .then(results => {
            setIsSaving(false);
            handleSave();
          })
          .catch(e => {
            setPresetError({ error: true, where: "freq-save" });
            setIsSaving(false);
          });
      } else {
        if (isNaN(freqDuration) || freqDuration <= 2 || !freqDuration) {
          setPresetError({ error: true, where: "freq-duration" });
          setIsSaving(false);
          return;
        }
        if (!isValid(freqStartTime) || isPast(freqStartTime)) {
          setPresetError({ error: true, where: "freq-start" });
          setIsSaving(false);
          return;
        }
      }
    }
  }
  function onDailySave() {
    if (currentType === "daily") {
      setPresetError({});
      setIsSaving(true);
      //if there are rules
      if (dailyRules.length) {
        //if said rules contain valid start and stop time in them
        //if they contain an id prop
        for (let j = 0; j < dailyRules.length; j++) {
          if (
            !isValid(new Date(dailyRules[j].start)) ||
            !isValid(new Date(dailyRules[j].stop)) ||
            !dailyRules[j].id ||
            isEqual(new Date(dailyRules[j].start), new Date(dailyRules[j].stop))
          ) {
            setIsSaving(false);
            setPresetError({
              error: true,
              where: "daily-dates",
              data: [dailyRules[j].id]
            });
            return;
          }
        }
        const timeRangesDoOverlap = timeRangesOverlap(dailyRules);
        if (timeRangesDoOverlap.errors.length) {
          setIsSaving(false);
          setPresetError({
            error: true,
            where: "daily-overlap",
            data: timeRangesDoOverlap.errors
          });
          return;
        }
        //process save
        functions
          .saveCustomDailyRule({
            portKey: preset.portKey,
            value: controlPresets.CUSTOM,
            options: {
              type: currentType,
              rules: dailyRules.map(rule => {
                return {
                  start: format(new Date(rule.start), timeFormatString),
                  stop: format(new Date(rule.stop), timeFormatString),
                  id: rule.id
                };
              })
            }
          })
          .then(results => {
            setIsSaving(false);
            handleSave();
          })
          .catch(e => {
            //setPresetError({ error: true, where: "daily-save" });
            setIsSaving(false);
          });
      } else {
        setPresetError({ error: true, where: "daily-rules" });
        setIsSaving(false);
        return;
      }
    }
  }
  function onSave() {
    if (currentType === "daily") {
      onDailySave();
    } else if (currentType === "freq") {
      onFreqSave();
    }
  }
  const mainContent = (
    <Grid spacing={2} justify="center" container>
      <Grid item xs={12}>
        <ButtonGroup
          size="large"
          aria-label="large contained secondary button group"
          fullWidth
        >
          <Button
            label="Diário"
            color="green"
            selected={currentType === "daily"}
            icon={<LocalFloristIcon />}
            onClick={() => setCurrentType("daily")}
          />
          <Button
            label="Outros"
            color="orange"
            selected={currentType === "freq"}
            icon={<FilterVintageIcon />}
            onClick={() => setCurrentType("freq")}
          />
        </ButtonGroup>
      </Grid>
      {currentType === "daily" ? (
        <Grid
          item
          alignItems="center"
          xs={12}
          className={classes.ruleContainer}
          container
        >
          <CustomDailyRule
            rules={dailyRules}
            error={presetError}
            onAdd={() =>
              setDailyRules(dailyRules => [
                ...dailyRules,
                {
                  id: uniqid(),
                  start: new Date().toJSON(),
                  stop: new Date().toJSON()
                }
              ])
            }
            onChange={newRule => {
              setDailyRules(dailyRules => [
                ...dailyRules.map(rule =>
                  rule.id === newRule.id ? newRule : rule
                )
              ]);
            }}
            onDelete={id => {
              setDailyRules(dailyRules => [
                ...dailyRules.filter(rule => rule.id !== id)
              ]);
            }}
          />
        </Grid>
      ) : (
        <Grid
          item
          alignItems="center"
          xs={12}
          className={classes.ruleContainer}
          container
        >
          <CustomFrequencyRule
            onChange={{
              setFreqRepeat,
              setFreqStartDate,
              setFreqStartTime,
              setFreqFrequencyTimeScale,
              setFreqFrequency,
              setFreqDurationTimeScale,
              setFreqDuration
            }}
            config={{
              freqRepeat,
              freqStartDate,
              freqStartTime,
              freqFrequencyTimeScale,
              freqFrequency,
              freqDurationTimeScale,
              freqDuration
            }}
            error={presetError}
          />
        </Grid>
      )}
    </Grid>
  );
  return (
    <Diag
      title={controlPresetLabels[controlPresets.CUSTOM + "_LABEL"]}
      content={
        isSaving ? <LoadingView absolute tooltip="Salvando" /> : mainContent
      }
      onClose={onClose}
      onSave={onSave}
    />
  );
}
PresetCustom.propTypes = {
  preset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default PresetCustom;

import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import orange from "@material-ui/core/colors/orange";
import purple from "@material-ui/core/colors/purple";

import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import classNames from "classnames";

const Button = styled(MuiButton)({
  color: "white"
});

const useStyles = makeStyles(theme => {
  return {
    root: {
      borderColor: theme.palette.grey[200]
    },
    green: {
      background: green[200],
      "&.selected": {
        background: green[500]
      }
    },
    red: {
      background: red[100],
      "&.selected": {
        background: red[300]
      }
    },
    purple: {
      background: purple[100],
      "&.selected": {
        background: purple[300]
      }
    },
    orange: {
      background: orange[200],
      "&.selected": {
        background: orange[300]
      }
    },
    blue: {
      background: blue[200],
      "&.selected": {
        background: blue[500]
      }
    },
    iconContainer: {
      marginRight: theme.spacing(1),
      display: "flex"
    }
  };
});
function CustomButton(props) {
  const { color, selected, onClick, label, className, icon, ...rest } = props;
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={classNames(
        color ? classes[color] : null,
        selected ? "selected" : null,
        className ? className : null
      )}
      onClick={onClick}
    >
      {icon ? <div className={classes.iconContainer}>{icon}</div> : null}
      {label}
    </Button>
  );
}

CustomButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  className: PropTypes.string
};
export default CustomButton;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
  formControl: {
    flex: 1
  }
});

const TextMultiline = props => {
  const {
    classes,
    error,
    label,
    rowsMax,
    rows,
    value,
    onChange,
    tooltip
  } = props;
  return (
    <FormControl error={error ? true : false} className={classes.formControl}>
      <TextField
        label={label}
        multiline
        rowsMax={rowsMax ? rowsMax : rows}
        rows={rows}
        value={value}
        fullWidth={true}
        onChange={onChange}
        error={error ? true : false}
      />
      <FormHelperText>{tooltip}</FormHelperText>
    </FormControl>
  );
};
export default withStyles(styles)(TextMultiline);

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import Red from "@material-ui/core/colors/red";
import ConfirmDiag from "../../../Helpers/ConfirmDiag";
import PropTypes from "prop-types";
import { REMOVE_FAB_BTN } from "../../../../constants/tours";
const styles = theme => {
  return {
    fab: {
      position: "fixed",
      zIndex: 1250,
      bottom: "13%",
      right: theme.spacing(2),
      backgroundColor: Red[400],
      color: "white"
    }
  };
};

class RemoveItemButton extends Component {
  state = {
    diag: false
  };
  onRemove = () => {
    if (this.props.withConfirmation) {
      //show Dialog
      return this.setState({ diag: true });
    } else {
      this.props.onRemove();
    }
  };
  onDiagConfirm = accept => {
    if (accept) {
      this.props.onRemove();
    } else {
      this.setState({ diag: false });
    }
  };
  render() {
    const { className, classes, withTour } = this.props;
    return (
      <div>
        <Fab
          aria-label="Delete"
          className={classNames(classes.fab, className ? className : null)}
          onClick={this.onRemove}
          data-tour={withTour ? REMOVE_FAB_BTN : null}
        >
          <DeleteIcon />
        </Fab>
        {this.state.diag ? (
          <ConfirmDiag
            open={this.state.diag}
            title="Tem certeza que deseja deletar?"
            content="Essa é uma ação final. Uma vez deletado, o documento nunca mais poderá ser acessado."
            onConfirm={this.onDiagConfirm}
            isRemove={true}
          />
        ) : null}
      </div>
    );
  }
}
RemoveItemButton.propTypes = {
  withConfirmation: PropTypes.bool,
  withTour: PropTypes.bool,
  onRemove: PropTypes.func.isRequired
};
export default withStyles(styles)(RemoveItemButton);

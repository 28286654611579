import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from "prop-types";
import { controlPresets } from "../../../../../constants/controlPresets";
const useStyles = makeStyles(theme => {
  return {
    formLabelRoot: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      flexBasis: "100%",
      margin: 0
    },
    formHelperText: {
      margin: 0
    }
  };
});
function ManualRule(props) {
  const { preset, onChange } = props;
  const classes = useStyles();
  const [mode, setMode] = React.useState(
    preset && preset.value === controlPresets.MANUAL ? preset.options.mode : false
  );

  function parseLabel() {
    if (mode) {
      return "Manter Ligado";
    } else {
      return "Manter Desligado";
    }
  }
  function parseToolTip() {
    if (mode) {
      return "Aparelhos ficarão ligados.";
    } else {
      return "Aparelhos ficarão desligados.";
    }
  }
  function onModeChange(value) {
    onChange(value);
    setMode(value);
  }
  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.formLabelRoot}
        control={
          <Switch checked={mode} onChange={e => onModeChange(e.target.checked)} color="primary" />
        }
        label={parseLabel()}
      />
      <FormHelperText className={classes.formHelperText}>{parseToolTip()}</FormHelperText>
    </FormGroup>
  );
}

ManualRule.propTypes = {
  preset: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
export default ManualRule;

import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const styles = theme => ({
  cardBodyButton: {
    width: "100%",
    textAlign: "left"
  },
  appCard: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    flex: 1,
    "&:not(last-child)": {
      marginBottom: theme.spacing(2)
    }
  },
  appContent: {
    padding: theme.spacing(2),
    position: "relative",
    display: "flex",
    justifyContent: "space-between"
  },
  infoPanel: {
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 12,
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    flex: 1,
    "& p": {
      margin: 0
    },
    "& p:not(:last-child)": {
      marginBottom: theme.spacing(1)
    },
    "&:last-child": {
      marginBottom: 0
    }
  },
  appAvatar: {
    background: "rgba(255, 255, 255, 0.2)",
    color: "white"
  },
  appChip: {
    background: "linear-gradient(60deg, #66bb6a, #43a047)",
    boxShadow:
      "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
    color: "white",
    width: "100%",
    borderRadius: 0
  },
  appActionTooltip: {
    fontSize: "0.625rem",
    textAlign: "center",
    color: theme.palette.grey[600]
  },
  appAction: {
    borderTop: "1px solid #eee",
    padding: theme.spacing(1),
    flexDirection: "column"
  }
});

const AppCard = props => {
  const { classes, title, content, icon, subContent, appRoute, tour } = props;

  return (
    <ButtonBase
      data-tour={tour}
      className={classes.cardBodyButton}
      component={appRoute ? Link : null}
      to={appRoute ? appRoute : null}
    >
      <Card className={classes.appCard}>
        <Chip
          className={classes.appChip}
          avatar={<Avatar className={classes.appAvatar}>{icon}</Avatar>}
          label={title}
        />
        <CardContent className={classes.appContent}>
          <div className={classes.infoPanel}>{content}</div>
        </CardContent>

        <CardActions className={classes.appAction}>
          <Typography className={classes.appActionTooltip}>
            {subContent}
          </Typography>
        </CardActions>
      </Card>
    </ButtonBase>
  );
};

AppCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  subContent: PropTypes.string,
  appRoute: PropTypes.string,
  tour: PropTypes.string
};
export default withStyles(styles)(AppCard);

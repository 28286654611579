import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import PropTypes from "prop-types";
const styles = theme => {
  return {
    root: {
      width: "100%"
    }
  };
};

const TextArea = props => {
  const {
    classes,
    className,
    error,
    label,
    onChange,
    rows,
    rowsMax,
    tooltip,
    tour,
    value
  } = props;
  return (
    <FormControl
      error={error ? true : false}
      className={classNames(classes.root, className)}
      data-tour={tour}
    >
      <TextField
        error={error ? true : false}
        fullWidth={true}
        label={label}
        margin="none"
        multiline
        onChange={onChange}
        rows={rows}
        rowsMax={rowsMax}
        value={value}
      />
      <FormHelperText>{tooltip}</FormHelperText>
    </FormControl>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  rowsMax: PropTypes.number,
  rows: PropTypes.number,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  tooltip: PropTypes.string,
  tour: PropTypes.string
};

export default withStyles(styles)(TextArea);

import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TimePicker from "../../../../../../Forms/Pickers/TimePicker";
import Typography from "@material-ui/core/Typography";
import parseTimeScale from "../../../../../../Helpers/parseTimeScale";
import TextInput from "../../../../../../Forms/Input/Text";
import Select from "../../../../../../Forms/Select/Selectv2";
import Switch from "../../../../../../Forms/Switch/Switch";
import DatePicker from "../../../../../../Forms/Pickers/Datetime/Datetime";

import PropTypes from "prop-types";
const useStyles = makeStyles(theme => {
  return {
    textField: {
      fontSize: theme.typography.h1.fontSize
    },
    input: {
      textAlign: "center"
    }
  };
});
function PresetCustomFrequency(props) {
  const classes = useStyles();
  const { config, onChange, error } = props;
  const repeat = config.freqRepeat;
  const startDate = config.freqStartDate;
  const startTime = config.freqStartTime;
  const frequencyTimeScale = config.freqFrequencyTimeScale;
  const frequency = config.freqFrequency;
  const durationTimeScale = config.freqDurationTimeScale;
  const duration = config.freqDuration;
  const setRepeat = onChange.setFreqRepeat;
  const setStartDate = onChange.setFreqStartDate;
  const setStartTime = onChange.setFreqStartTime;
  const setFrequencyTimeScale = onChange.setFreqFrequencyTimeScale;
  const setFrequency = onChange.setFreqFrequency;
  const setDurationTimeScale = onChange.setFreqDurationTimeScale;
  const setDuration = onChange.setFreqDuration;

  return (
    <Grid spacing={2} className={classes.root} justify="center" container>
      <Grid item xs={12}>
        <Typography>
          Configure um evento em qualquer data, decida se esse evento deve se
          repetir, como e quando!
        </Typography>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextInput
            label={`Duração da regra em ${parseTimeScale(
              durationTimeScale
            ).toLocaleLowerCase()}`}
            type="number"
            value={duration}
            handleChange={e => setDuration(e.target.value)}
            className={classes.textField}
            inputClasses={{ input: classes.input }}
            helper={
              error.where === "freq-duration"
                ? "Informe uma duração"
                : `Quanto tempo em ${parseTimeScale(
                    durationTimeScale
                  ).toLocaleLowerCase()} essa regra vai durar `
            }
            error={error.where === "freq-duration"}
          />

          <Select
            value={durationTimeScale}
            onChange={e => setDurationTimeScale(e.target.value)}
            helper="Executar regra em segundos, minutos, horas ou dias?"
          >
            <option value="sec">{parseTimeScale("sec")}</option>
            <option value="min">{parseTimeScale("min")}</option>
            <option value="hour">{parseTimeScale("hour")}</option>
            <option value="day">{parseTimeScale("day")}</option>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Switch
            label={repeat ? "Regra será repetida" : "Regra não será repetida"}
            value={repeat}
            onChange={value => setRepeat(value)}
            tooltip={
              repeat
                ? "Regra será repetida automáticamente"
                : "Rega será repetida manualmente"
            }
          />
        </Grid>
        {repeat ? (
          <Grid item container xs={12}>
            <Grid item>
              <TextInput
                label={`Regra será repetida a cada quant${
                  frequencyTimeScale === "hour" ? "a" : "o"
                }s ${parseTimeScale(frequencyTimeScale).toLocaleLowerCase()}?`}
                type="number"
                value={frequency}
                handleChange={e => setFrequency(e.target.value)}
                className={classes.textField}
                inputClasses={{ input: classes.input }}
                helper={
                  error.where === "freq-repeat"
                    ? "Frequencia deve ter pelo menos 3 segundos"
                    : "Contagem inicia após término da regra anterior."
                }
                error={error.where === "freq-repeat"}
              />
            </Grid>
            <Grid item>
              <Select
                value={frequencyTimeScale}
                onChange={e => setFrequencyTimeScale(e.target.value)}
                helper="Repetir em segundos, minutos, horas ou dias?"
              >
                <option value="sec">{parseTimeScale("sec")}</option>
                <option value="min">{parseTimeScale("min")}</option>
                <option value="hour">{parseTimeScale("hour")}</option>
                <option value="day">{parseTimeScale("day")}</option>
              </Select>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <DatePicker
            handleChange={date => {
              setStartDate(date);
              setStartTime(date);
            }}
            date={startDate}
            label="Que dia a regra deve iniciar?"
          />
        </Grid>
        <Grid item xs={12}>
          <TimePicker
            // if there is date AND TIME, use it, else use as base the date set by the DatePicker (defaults to now)
            date={startTime ? startTime : startDate}
            handleChange={date => {
              setStartTime(date);
              setStartDate(date);
            }}
            label="Que horas a regra deve iniciar?"
            error={error.where === "freq-start"}
            helperText={
              error.where === "freq-start"
                ? "Escolha um horário mais recente ou pelo menos diferente do antigo"
                : null
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
PresetCustomFrequency.propTypes = {
  onChange: PropTypes.objectOf(PropTypes.func),
  config: PropTypes.shape({
    freqRepeat: PropTypes.bool,
    freqStartDate: PropTypes.object,
    freqStartTime: PropTypes.object,
    freqFrequencyTimeScale: PropTypes.string,
    freqFrequency: PropTypes.string,
    freqDurationTimeScale: PropTypes.string,
    freqDuration: PropTypes.string
  }),
  error: PropTypes.shape({
    where: PropTypes.string,
    error: PropTypes.bool
  })
};
export default PresetCustomFrequency;

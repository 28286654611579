import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import DailyRuleRow from "./DailyRuleRow";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => {
  return {
    rulesContainer: {
      maxHeight: 200,
      overflowX: "hidden",
      overflowY: "scroll"
    },
    ruleRow: {
      marginBottom: theme.spacing(2)
    },
    ruleNumberContainer: {
      background: theme.palette.grey[300],
      padding: theme.spacing(1),
      height: "100%"
    },
    ruleNumberText: {
      fontWeight: 700
    },
    ruleAddButton: {
      color: theme.palette.primary.main
    },
    ruleRemoveButton: {
      color: theme.palette.error.light,
      padding: 0
    }
  };
});
function PresetCustomDaily(props) {
  const { rules, onChange, onDelete, onAdd, error } = props;
  const classes = useStyles();
  const ruleListRef = React.useRef(null);
  console.log(rules);
  React.useEffect(() => {
    // scroll to the last element of the list
    if (ruleListRef.current)
      ruleListRef.current.scrollTop = ruleListRef.current.scrollHeight;
  });
  function parseRules() {
    if (!rules.length) {
      return onAdd();
    }
    return rules.map((rule, i) => {
      return (
        <DailyRuleRow
          onDelete={() => onDelete(rule.id)}
          className={classes.ruleRow}
          newlyAdded={i === rules.length - 1}
          onChange={onChange}
          rule={rule}
          ruleNumber={i + 1}
          key={i}
          error={error && error.error && error.data.includes(rule.id)}
        />
      );
    });
  }

  return (
    <Grid spacing={2} className={classes.root} justify="center" container>
      <Grid item xs={12}>
        <Typography>
          Você pode adicionar quantas regras quiser! Elas serão executadas todo
          dia.
        </Typography>
      </Grid>
      <Grid ref={ruleListRef} container className={classes.rulesContainer} item>
        {parseRules()}
      </Grid>
      <Grid container justify="center" item xs={12}>
        <IconButton onClick={onAdd} className={classes.ruleAddButton}>
          <AddCircleOutlineIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
PresetCustomDaily.propTypes = {
  rules: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  error: PropTypes.object
};
export default PresetCustomDaily;

export const appCardTours = {
  CONTROLS_APP_TOUR: "controls-tour",
  DIARY_APP_TOUR: "diary-tour",
  REMINDERS_APP_TOUR: "reminders-tour",
  OVERVIEW_APP_TOUR: "overview-tour"
};
export const FEEDBACK_BTN_TOUR = "feedback-btn-tour";
export const OVERVIEW_APP_TABS_TOUR = "overview-app-tabs-tour";

export const controlsAppTours = {
  CONTROL_LIST_NAME: "control-list-name",
  CONTROL_LIST_STATUS: "control-list-status",
  CONTROL_LIST_DESC: "control-list-desc",
  CONTROL_EDIT_MODE: "control-edit-mode",
  CONTROL_EDIT_AUTO_MODE: "control-edit-auto-mode",
  CONTROL_AUTO_SENSOR: "control-auto-sensor",
  CONTROL_AUTO_TIME: "control-auto-time",
  CONTROL_AUTO_FREQ: "control-auto-freq"
};

export const diaryAppTours = {
  DIARY_ENTRY_IMAGE: "diary-entry-image",
  DIARY_EDIT_BTN: "diary-edit-btn",
  DIARY_ADD_NOTE: "diary-add-note",
  DIARY_ADD_PHOTO: "diary-add-photo"
};

export const reminderAppTours = {
  REMINDER_LIST_ITEM: "reminder-list-item",
  REMINDER_INFO: "reminder-info",
  REMINDER_TAGS: "reminder-tags",
  REMINDER_NOTIFICATION_TYPE: "reminder-notification-type",
  REMINDER_TYPE: "reminder-type"
};

export const ADD_SAVE_FAB_BTN = "base-app-add-save-btn";
export const REMOVE_FAB_BTN = "base-app-remove-btn";

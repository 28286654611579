import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tour from "reactour";
import PropTypes from "prop-types";
import TourCloseBtn from "./TourCloseBtn";

const styles = theme => ({
  root: {
    "--reactour-accent": theme.palette.primary.main,
    lineHeight: 1.3,
    color: theme.palette.text.primary
  }
});

class CustomTour extends Component {
  state = {};

  handleClose = () => {
    if (this.state.currentStep === this.props.steps.length - 1) {
      this.setState({ isSaving: true });
      return this.props.onRequestClose(true);
    }

    return this.props.onRequestClose();
  };
  getTourStep = step => {
    this.setState(prevState =>
      prevState.currentStep !== step ? { currentStep: step } : null
    );
  };
  render() {
    const { classes, steps } = this.props;
    const { isSaving } = this.state;

    return (
      <Tour
        isOpen={true}
        steps={steps}
        rounded={5}
        getCurrentStep={this.getTourStep}
        onRequestClose={this.handleClose}
        lastStepNextButton={
          <TourCloseBtn isSaving={isSaving ? isSaving : null} />
        }
        className={classes.root}
        showNumber={false}
      />
    );
  }
}
CustomTour.propTypes = {
  steps: PropTypes.array.isRequired,
  onRequestClose: PropTypes.func.isRequired
};
export default withStyles(styles)(CustomTour);

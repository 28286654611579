import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import LoginForm from "../Forms/LoginForm/LoginForm";
import { Redirect } from "react-router-dom";
import { FirebaseAuthContext } from "../../hocs/FirebaseAuthProvider";
import Notification from "../UI/Notification/Notification";
import { DASHBOARD } from "../../constants/routes";
import { auth, functions } from "../../firebase";
import LoadingView from "../Helpers/LoadingView";
import hgLogo from "../../assets/hg-logo.png";

const styles = theme => {
  return {
    root: {
      flexGrow: 1,
      width: "100%",
      height: "100vh",
      padding: theme.spacing(2),
      background: "linear-gradient(to right, #8DC26F, #76b852)"
    },
    mainGrid: {
      backgroundColor: "transparent",
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
      minHeight: "75vh",
      display: "flex",
      flexDirection: "column",
      alignSelf: "center"
    },
    header: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      "& h1": {
        color: theme.palette.common.white,
        fontSize: "6.5rem",
        lineHeight: "1em"
      }
    },
    headerSubheading: {
      color: theme.palette.grey[200],
      fontSize: 16
    },
    loginHomeButton: {
      color: theme.palette.common.white,
      fontSize: 24
    },
    homeSubLabel: {
      fontSize: 20,
      color: theme.palette.primary.dark,
      maxWidth: "50%",
      textAlign: "left"
    },

    formContainer: { marginTop: "auto", paddingBottom: theme.spacing(8) },
    loginForm: {
      margin: "auto 0"
    },
    loginFormContainer: {
      position: "relative"
    },
    logo: {
      maxWidth: "100%",
      margin: "0 16px"
    }
  };
};

class HomeLoginRegister extends Component {
  state = {
    login: false,
    register: false,
    registerForm: { email: "", password: "" },
    loginForm: { email: "", password: "" },
    showPassword: true,
    error: []
  };

  handleClickShowPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };
  //dev todo deprecate
  handleLoginSubmit = async () => {
    //this.setState({ isRedirect: false });
    //some spooky action right'ire
    const { email, password } = this.state.loginForm;
    if (email.trim() && password) {
      this.setState({ authing: true, authStatusMsg: "Buscando sua conta" });
      try {
        await auth.doSignInWithEmailAndPassword(email.trim(), password);
      } catch (error) {
        auth.renderFirebaseSignInError(error, newError =>
          this.setState({ error: [newError], authing: false })
        );
      }
    } else {
      this.setState({ error: ["Por favor informe um Email e Senha"] });
    }
  };
  handleRegisterSubmit = async () => {
    //dev todo move to backend so that we can .then after its all created
    const { email, password } = this.state.registerForm;
    if (email.trim() && password) {
      this.setState({ authing: true, authStatusMsg: "Semeando Registros" });
      try {
        await auth.doCreateUserWithEmailAndPassword(email, password);
        this.setState({ authStatusMsg: "Colhendo sua conta" });
        //call function to populate db user, controls and other basic user configs
        await functions.onAccountCreation();
        this.setState({ accountCreated: true });
      } catch (error) {
        auth.renderFirebaseSignUpError(error, newError =>
          this.setState({ error: [newError], authing: false })
        );
      }
    } else {
      this.setState({ error: ["Por favor informe um Email e Senha"] });
    }
  };
  resetError = () => {
    this.setState({ error: [] });
  };
  renderLoginForm = () => {
    if (this.state.login) {
      return (
        <div className={this.props.classes.loginFormContainer}>
          {this.state.authing ? (
            <LoadingView absolute tooltip={this.state.authStatusMsg} />
          ) : null}
          <LoginForm
            className={this.props.classes.loginForm}
            showPassword={this.state.showPassword}
            handleClickShowPassword={this.handleClickShowPassword}
            handleChange={(data, progress) =>
              this.setState(prevState => ({
                ...prevState,
                loginForm: { ...prevState.loginForm, [progress]: data }
              }))
            }
            customTitle="Faça login para acessar!"
            handleSubmit={this.handleLoginSubmit}
            email={this.state.loginForm.email}
            pass={this.state.loginForm.password}
            returnText="Ainda não tem uma conta? Clique aqui!"
            handleReturnButton={() => this.setState({ login: false })}
          />
        </div>
      );
    } else if (this.state.register) {
      return (
        <div className={this.props.classes.loginFormContainer}>
          {this.state.authing ? (
            <LoadingView absolute tooltip={this.state.authStatusMsg} />
          ) : null}
          <LoginForm
            className={this.props.classes.loginForm}
            showPassword={this.state.showPassword}
            handleClickShowPassword={this.handleClickShowPassword}
            handleChange={(data, progress) =>
              this.setState(prevState => ({
                ...prevState,
                registerForm: { ...prevState.registerForm, [progress]: data }
              }))
            }
            handleSubmit={this.handleRegisterSubmit}
            email={this.state.registerForm.email}
            pass={this.state.registerForm.password}
            customTitle="Registre uma conta para acessar!"
            buttonTitle="Registrar"
            returnText="Já tem uma conta? Clique aqui!"
            handleReturnButton={() => this.setState({ register: false })}
            emailLabel="Qual é o seu email?"
            passLabel="Uma senha forte"
          />
        </div>
      );
    } else {
      return (
        <div className={this.props.classes.formContainer}>
          <ButtonBase
            onClick={() => this.setState({ login: true, register: false })}
          >
            <Typography
              className={this.props.classes.loginHomeButton}
              component="h1"
            >
              Fazer login
            </Typography>
          </ButtonBase>
          <Typography className={this.props.classes.homeSubLabel} component="p">
            <i>ou</i>
          </Typography>
          <ButtonBase
            onClick={() => this.setState({ register: true, login: false })}
          >
            <Typography
              className={this.props.classes.loginHomeButton}
              component="h1"
            >
              Primeiro uso?
            </Typography>
          </ButtonBase>
        </div>
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <FirebaseAuthContext.Consumer>
        {({ isUserSignedIn, authStatusReported }) => {
          if (!authStatusReported) {
            return (
              <Grid container className={classes.root}>
                <LoadingView absolute tooltip="Verificando seu status" />
              </Grid>
            );
          }
          if (isUserSignedIn) {
            if (
              this.state.login ||
              (!this.state.login && !this.state.register) ||
              this.state.accountCreated
            ) {
              return <Redirect to={DASHBOARD} />;
            }
          }
          return (
            <Grid container className={classes.root}>
              <Notification
                type="error"
                open={this.state.error.length > 0}
                messages={this.state.error}
                handleClose={() => this.setState({ error: [] })}
              />

              <Grid className={classes.mainGrid} item xs={12}>
                <div className={classes.header}>
                  <img
                    className={classes.logo}
                    src={hgLogo}
                    alt="Homegrown Cultivo"
                  />
                  <Typography
                    className={classes.headerSubheading}
                    component="h3"
                  >
                    ferramentas para amantes do cultivo
                  </Typography>
                </div>
                {this.renderLoginForm()}
              </Grid>
            </Grid>
          );
        }}
      </FirebaseAuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(HomeLoginRegister);

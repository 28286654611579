import React from "react";

import MuiListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
const useStyles = makeStyles(theme => {
  return {
    listItemRoot: {
      background: theme.palette.grey[300],
      marginBottom: theme.spacing(1)
    },
    listItemText: {
      textAlign: "center"
    },
    avatar: {
      margin: "auto"
    }
  };
});
function ListItem(props) {
  const { className, onSelect, avatar, label, info, selected, badge } = props;
  const classes = useStyles();

  return (
    <MuiListItem
      className={classNames(classes.listItemRoot, className ? className : null)}
      button
      onClick={onSelect}
    >
      <Grid justify="center" container>
        <Grid item>
          {avatar ? (
            <ListItemAvatar>
              <Avatar
                className={classes.avatar}
                alt={label}
                src={avatar.length > 3 ? avatar : null}
              >
                {avatar.length <= 3 ? avatar : null}
              </Avatar>
            </ListItemAvatar>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            className={classes.listItemText}
            primary={label}
            secondary={
              <React.Fragment>
                <Typography component="span">{info}</Typography>
              </React.Fragment>
            }
          />
        </Grid>
        <Grid item>{selected ? <CheckIcon /> : null}</Grid>
        <Grid container justify="center" item xs={12}>
          {badge ? badge : null}
        </Grid>
      </Grid>
    </MuiListItem>
  );
}
ListItem.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  avatar: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.string,
  selected: PropTypes.bool,
  badge: PropTypes.node
};
export default ListItem;

import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";

import TimelineIcon from "@material-ui/icons/Timeline";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { connect } from "react-redux";
import { functions } from "../../firebase";
import LoadingView from "../Helpers/LoadingView";
import ptLocale from "date-fns/locale/pt-BR";
import formatDistance from "date-fns/formatDistance";

import { OVERVIEW_APP } from "../../constants/routes";
import { OVERVIEW_APP_TITLE } from "../../constants/views";
import AppCard from "./AppCard";
import PropTypes from "prop-types";
const options = {
  locale: ptLocale,
};

const styles = (theme) => ({
  dataSensorContainer: {
    textAlign: "center",
    width: "50%",
    display: "inline-block",
  },
  dataSensor: {
    color: green[700],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",

    justifyContent: "flex-start",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 20,
    marginBottom: theme.spacing(1),
    textDecoration: "none",
    "& p": {
      fontSize: 10,
      margin: 0,
    },
    "&.danger": {
      color: red[300],
    },
  },
  low: {
    color: "#ef5350",
  },
  high: {
    color: theme.palette.primary.main,
  },
  labelSensor: {
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 10,
    marginBottom: "3px",
    textDecoration: "none",
  },
});

class OverviewCardApp extends Component {
  state = {
    loading: false,
  };
  parseCardContent = () => {
    const { classes, sensors } = this.props;
    if (sensors) {
      return (
        <React.Fragment>
          <div className={classes.dataSensorContainer}>
            <div className={classes.labelSensor}>Temperatura</div>
            <div className={classes.dataSensor}>
              {sensors && sensors.temp ? sensors.temp.toFixed(2) : ""}°
            </div>
          </div>
          <div className={classes.dataSensorContainer}>
            <div className={classes.labelSensor}>Umidade</div>
            <div className={classes.dataSensor}>
              {sensors && sensors.hum ? sensors.hum.toFixed(2) : ""}%
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <LoadingView mini size={25} tooltip="Pedindo novos dados" absolute />
      );
    }
  };
  loadSensors = () => {
    if (!this.state.loading) {
      functions
        .getSensorUpdate(this.props.deviceId)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch((error) => {
          //dev todo error notification?
          this.setState({ loading: false });
        });
    }
    this.setState({ loading: true });
  };
  parseSubContent = () => {
    if (typeof this.props.sensors.updatedAt === "undefined") {
      return "Buscando dados...";
    } else {
      return `atualizado a ${formatDistance(
        new Date(this.props.sensors.updatedAt),
        new Date(),
        options
      )}, clique para ver mais`;
    }
  };
  render() {
    const { tour } = this.props;
    return (
      <AppCard
        title={OVERVIEW_APP_TITLE}
        icon={<TimelineIcon />}
        content={this.parseCardContent()}
        subContent={this.parseSubContent()}
        appRoute={OVERVIEW_APP}
        tour={tour}
      />
    );
  }
}
OverviewCardApp.propTypes = {
  tour: PropTypes.string,
};
function mapStateToProps({ sensors, user }) {
  return { sensors, deviceId: user.deviceId };
}
export default connect(mapStateToProps)(withStyles(styles)(OverviewCardApp));

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import AppBar from "../UI/AppBar/AppBar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import RemoveItemButton from "../UI/Button/FAB/RemoveItem";
import SaveItemButton from "../UI/Button/FAB/SaveItem";
import Typography from "@material-ui/core/Typography";

const styles = theme => {
  return {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(9),
      "&.noDescription": {
        paddingTop: theme.spacing(1) * (9 + 2)
      }
    },
    infoPaper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      background: "linear-gradient(45deg, #66bb6a, #43a047)",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      "& p": {
        color: "white"
      }
    },
    content: {
      background: "none",
      "&.bg": {
        background: theme.palette.common.white
      }
    }
  };
};

const BaseApp = props => {
  let {
    classes,
    title,
    description,
    children,
    onSave,
    onAdd,
    onDelete,
    backBtnRoute,
    bg,
    withTour
  } = props;
  description = null;
  return (
    <div>
      {typeof onSave === "function" ? (
        <SaveItemButton withTour={withTour} type="save" onSave={onSave} />
      ) : null}
      {typeof onAdd === "function" ? (
        <SaveItemButton withTour={withTour} type="add" onSave={onAdd} />
      ) : null}
      {typeof onDelete === "function" ? (
        <RemoveItemButton
          withTour={withTour}
          onRemove={onDelete}
          withConfirmation
        />
      ) : null}

      <AppBar title={title ? title : null}>
        {typeof backBtnRoute === "function" ? (
          <IconButton color="inherit" aria-label="Menu" onClick={backBtnRoute}>
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        {typeof backBtnRoute === "string" ? (
          <IconButton
            color="inherit"
            aria-label="Menu"
            component={Link}
            to={backBtnRoute}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : null}
      </AppBar>
      <Grid
        className={classNames(
          classes.root,
          description ? null : "noDescription"
        )}
        container
      >
        {description ? (
          <Grid item xs={12}>
            <Paper className={classes.infoPaper} elevation={1}>
              <Typography component="p">{description}</Typography>
            </Paper>
          </Grid>
        ) : null}
        <Grid
          className={classNames(classes.content, bg ? "bg" : null)}
          item
          xs={12}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

BaseApp.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onSave: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  backBtnRoute: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired
  ]),
  bg: PropTypes.bool,
  withTour: PropTypes.bool
};
export default withStyles(styles)(BaseApp);

import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import { makeStyles } from "@material-ui/core/styles";
import {
  photoperiodPresets,
  controlPresetLabels,
  controlPresets
} from "../../../../../../constants/controlPresets";
import List from "@material-ui/core/List";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
import Chip from "../../../../../UI/Chip/Chip";
import TimePicker from "../../../../../Forms/Pickers/TimePicker";
import ListItem from "../../../../../UI/List/ListItem";
import { Typography } from "@material-ui/core";
import Button from "../../../../../UI/Button/Button/Button";
import PropTypes from "prop-types";
import Diag from "../../../../../UI/Diag/Diag";
import { functions } from "../../../../../../firebase";
import LoadingView from "../../../../../Helpers/LoadingView";
import { format, addHours } from "date-fns";
const useStyles = makeStyles(theme => {
  return {
    photoperiodModeBtnGroup: {
      borderColor: theme.palette.grey[200]
    },
    recommended: {
      border: `4px solid ${yellow[500]}`
    },
    selected: {
      background: green[100] + "!important"
    },
    error: {
      border: "2px solid red",
      borderRadius: theme.shape.borderRadius
    }
  };
});
function PresetPhotoperiod(props) {
  //flower or veg
  const { preset, onClose, handleSave } = props;
  const [currentCycle, setCurrentCycle] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.PHOTOPERIOD) {
        return preset.options.cycle;
      } else {
        return "veg";
      }
    } else {
      return "veg";
    }
  });
  const [selectedCycleValue, setSelectedCycleValue] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.PHOTOPERIOD) {
        //ok preset is actually for me
        return preset.options.cycleValue;
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  const [startTime, setStartTime] = React.useState(() => {
    if (preset && preset.options) {
      if (preset.value === controlPresets.PHOTOPERIOD) {
        //ok preset is actually for me
        return new Date(preset.options.startTime);
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [presetError, setPresetError] = React.useState({});

  const classes = useStyles();
  function parsePresets() {
    const listItems = [];
    photoperiodPresets[currentCycle].forEach((cycle, i) => {
      let presetSelected = selectedCycleValue === cycle.value;

      if (
        photoperiodPresets[currentCycle].length === 1 &&
        selectedCycleValue === null
      ) {
        //if the current cycle has only one option and the user has no preferences already, set this only option to selected.
        setSelectedCycleValue(cycle.value);
        presetSelected = true;
      }
      listItems.push(
        <ListItem
          key={i}
          label={cycle.label}
          avatar={cycle.value.toString()}
          info={cycle.info}
          onSelect={() => setSelectedCycleValue(cycle.value)}
          selected={presetSelected}
          badge={cycle.recommended ? <Chip label="Recomendado" /> : null}
          className={presetSelected ? classes.selected : null}
        />
      );
    });
    return listItems;
  }

  function onSave() {
    setIsSaving(true);
    let parsedStart = startTime;
    let parsedStop;

    if (currentCycle && startTime && selectedCycleValue) {
      if (selectedCycleValue !== 24) {
        parsedStart = format(parsedStart, "HHmmss");
        try {
          parsedStop = format(
            addHours(startTime, parseInt(selectedCycleValue, 10)),
            "HHmmss"
          );
        } catch (e) {
          return;
        }
      }

      functions
        .savePhotoperiodRule({
          portKey: preset.portKey,
          value: controlPresets.PHOTOPERIOD,
          options: {
            startTime: startTime.toJSON(),
            start: parsedStart,
            stop: parsedStop,
            cycleValue: selectedCycleValue,
            cycle: currentCycle
          }
        })
        .then(results => {
          setIsSaving(false);
          handleSave();
        })
        .catch(e => {
          setIsSaving(false);
        });
    } else {
      if (!currentCycle) {
        const error = { error: true, where: "cycle" };
        setIsSaving(false);
        return setPresetError(error);
      }
      if (!selectedCycleValue) {
        const error = { error: true, where: "cycleValue" };
        setIsSaving(false);
        return setPresetError(error);
      }
      if (!startTime) {
        const error = { error: true, where: "startTime" };
        setIsSaving(false);
        return setPresetError(error);
      }
    }
  }

  const mainContent = (
    <Grid container>
      <Grid item container xs={12}>
        <ButtonGroup
          classes={{ root: classes.root }}
          size="small"
          aria-label="large contained secondary button group"
          fullWidth
          className={
            presetError.error && presetError.where === "cycle"
              ? classes.error
              : null
          }
        >
          <Button
            label="Vegetativo"
            color="green"
            selected={currentCycle === "veg"}
            icon={<LocalFloristIcon />}
            onClick={() => setCurrentCycle("veg")}
          />
          <Button
            label="Flora"
            color="orange"
            selected={currentCycle === "flower"}
            icon={<FilterVintageIcon />}
            onClick={() => setCurrentCycle("flower")}
          />
        </ButtonGroup>
      </Grid>
      <Grid
        className={
          presetError.error && presetError.where === "cycleValue"
            ? classes.error
            : null
        }
        item
        xs={12}
      >
        <List>{parsePresets()}</List>
      </Grid>
      <Grid item xs={12}>
        <TimePicker
          date={startTime}
          error={presetError.error && presetError.where === "startTime"}
          handleChange={date => setStartTime(date)}
          label="Que horas as luzes devem ligar?"
        />
        <Typography variant="caption">
          O horário de desligar é automáticamente calculado
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <Diag
      title={controlPresetLabels[controlPresets.PHOTOPERIOD + "_LABEL"]}
      content={
        isSaving ? <LoadingView absolute tooltip="Salvando" /> : mainContent
      }
      onClose={onClose}
      onSave={onSave}
    />
  );
}
PresetPhotoperiod.propTypes = {
  preset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};
export default PresetPhotoperiod;

import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import BaseApp from "../../../Views/BaseApp";
import { EDIT_RULE_APP_TITLE } from "../../../../constants/views";
import ButtonGroup from "../../../UI/Button/ButtonGroup/ButtonGroup";
import AutoRule from "./Rules/Auto/Auto";
import ManualRule from "./Rules/Manual";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNewOutlined";
import BlurOn from "@material-ui/icons/BlurOn";
import { functions } from "../../../../firebase";
import LoadingView from "../../../Helpers/LoadingView";
import { controlPresets } from "../../../../constants/controlPresets";
const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2)
    },
    ruleControlItem: {
      marginBottom: theme.spacing(4)
    }
  };
});
function RuleEditor(props) {
  const { handleBackBtn, preset } = props;
  const [manualMode, setManualMode] = React.useState(() => {
    if (preset.value === controlPresets.MANUAL) {
      return preset.options.mode || false;
    } else {
      return false;
    }
  });
  const [currentMode, setCurrentMode] = React.useState(() => {
    if (preset.value) {
      if (preset.value === controlPresets.MANUAL) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });
  const classes = useStyles();
  const [isSaving, setIsSaving] = React.useState(false);

  function onSave() {
    setIsSaving(true);
    functions
      .saveManualRule({
        portKey: preset.portKey,
        value: controlPresets.MANUAL,
        options: {
          mode: manualMode
        }
      })
      .then(results => {
        setIsSaving(false);
        handleBackBtn();
      })
      .catch(e => {
        setIsSaving(false);
      });
  }

  if (isSaving) {
    return <LoadingView absolute tooltip="Salvando" />;
  }
  return (
    <BaseApp
      title={EDIT_RULE_APP_TITLE}
      backBtnRoute={handleBackBtn}
      onSave={currentMode ? null : onSave}
      withTour
    >
      <Grid className={classes.root} container>
        <Grid
          className={classes.ruleControlItem}
          item
          xs={12}
          container
          justify="center"
        >
          <ButtonGroup
            size={currentMode ? "small" : null}
            items={[
              {
                label: "Manual",
                color: "orange",
                selected: !currentMode,
                icon: <PowerSettingsNew />,
                onClick: () => setCurrentMode(false)
              },
              {
                label: "Automático",
                color: "purple",
                selected: currentMode,
                icon: <BlurOn />,
                onClick: () => setCurrentMode(true)
              }
            ]}
          />
        </Grid>
        {currentMode ? (
          <Grid item xs={12}>
            {<AutoRule handleSave={() => handleBackBtn()} preset={preset} />}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ManualRule
              onChange={mode => setManualMode(mode)}
              preset={{ ...preset, options: { mode: manualMode } }}
            />
          </Grid>
        )}
      </Grid>
    </BaseApp>
  );
}
RuleEditor.propTypes = {
  handleBackBtn: PropTypes.func.isRequired,
  preset: PropTypes.object.isRequired
};
export default RuleEditor;

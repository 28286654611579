export const remindersDb = "reminders";
export const diaryappDb = "diaryapp";
export const usersDb = "users";
export const telemetryDb = "devices";
export const growsDb = "grows";
export const analyticsDb = "analytics";
export const controlsDb = "controls";
export const messagingTokensDb = "messagingTokens";
export const feedbackDb = "feedback";
export const relayStateDb = "growboxRelayState";
export const controlPresetsDb = "controlPresets";

export const feedbackTypes = [
  {
    value: "talk",
    label: "Outros assuntos"
  },
  {
    value: "ask",
    label: "Pedidos"
  },
  {
    value: "help",
    label: "Ajuda"
  },
  {
    value: "bug",
    label: "Erro ou Bug"
  }
];

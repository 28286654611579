import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmDiag = props => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onConfirm();
      }}
      aria-labelledby="confirmar-acao-titulo"
    >
      <DialogTitle id="confirmar-acao-titulo">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onConfirm()} color="primary">
          {props.declineText || "Cancelar"}
        </Button>
        <Button
          onClick={() => props.onConfirm(true)}
          style={props.isRemove ? { color: "#f44336" } : {}}
          color="primary"
          autoFocus
        >
          {props.agreeText || "Confirmar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDiag;

import { TELEMETRY_UPDATE } from "../actions/types";
export default function (state = "", action) {
  switch (action.type) {
    case TELEMETRY_UPDATE:
      const { lastState } = action.payload;
      if (lastState) {
        return lastState.relay_status || "0000";
      }
      return state;
    default:
      return state;
  }
}

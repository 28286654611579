import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center",
    "& span": {
      display: "block"
    }
  },
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
});

const Notification = props => {
  const { open, messages, type, duration, handleClose, classes } = props;
  const Icon = variantIcon[type];
  const message = messages.map((message, i) => <span key={i}>{message}</span>);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={duration || 6000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        className={classes[type]}
        message={
          <div id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            <div>{message}</div>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Fechar"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
  messages: PropTypes.array.isRequired,
  duration: PropTypes.number,
  handleClose: PropTypes.func.isRequired
};
export default withStyles(styles)(Notification);

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyDVYjSKeCca2cr5OBoZ0TUrh0NluWse4WY",
  authDomain: "tribedev1.firebaseapp.com",
  databaseURL: "https://tribedev1.firebaseio.com",
  projectId: "tribedev1",
  storageBucket: "tribedev1.appspot.com",
  messagingSenderId: "665466963004",
  appId: "1:665466963004:web:69209add942ff4cc"
};
//dev todo setup firebase app based on process.env, separate dbs for prod and dev
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.firestore();

const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

let messaging;
try {
  messaging = firebase.messaging();
} catch (e) {
  messaging = null;
}

export { auth, db, functions, messaging, firebase, storage };

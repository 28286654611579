import React from "react";
//import PropTypes from 'prop-types';
import ViewListIcon from "@material-ui/icons/ViewList";
import { connect } from "react-redux";
import { REMINDERS as REMINDERS_APP } from "../../constants/routes";
import { REMINDERS_APP_TITLE } from "../../constants/views";
import AppCard from "./AppCard";
import PropTypes from "prop-types";
const parseCardContent = (classes, reminders) => {
  if (reminders.length) {
    return (
      <p>{`Você tem ${reminders.length} lembrete${
        reminders.length > 1 ? "s" : ""
      } pendente${reminders.length > 1 ? "s" : ""}.`}</p>
    );
  } else {
    return <p>Você não tem lembretes.</p>;
  }
};
function RemindersAppCard(props) {
  const { classes, reminders, tour } = props;

  return (
    <AppCard
      title={REMINDERS_APP_TITLE}
      icon={<ViewListIcon />}
      content={parseCardContent(classes, reminders)}
      subContent="clique para editar"
      appRoute={REMINDERS_APP}
      tour={tour}
    />
  );
}

RemindersAppCard.propTypes = {
  tour: PropTypes.string
};
function mapStateToProps({ reminders }) {
  return {
    reminders
  };
}
export default connect(mapStateToProps)(RemindersAppCard);

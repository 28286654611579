import React from "react";
import MuiChip from "@material-ui/core/Chip/Chip";
import CheckIcon from "@material-ui/icons/Check";
import FaceIcon from "@material-ui/icons/Face";
import PropTypes from "prop-types";
function Chip(props) {
  const { label } = props;
  return (
    <MuiChip
      icon={<FaceIcon />}
      label={label}
      color="primary"
      deleteIcon={<CheckIcon />}
    />
  );
}
Chip.propTypes = {
  label: PropTypes.string.isRequired
};
export default Chip;

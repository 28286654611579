//skeleton for a component, right now only for analytics, very specific.
import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import * as zoom from "chartjs-plugin-zoom";
const colors = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)"
};

const useStyles = makeStyles(theme => {
  return {
    root: {
      minHeight: "300px",
      alignItems: "center",
      padding: `${theme.spacing(4)}px 0px`
    }
  };
});
function AreaChart(props) {
  const { data, dateFormat, xLabel, yLabel } = props;
  const classes = useStyles();
  React.useEffect(() => {
    // Update the document title using the browser API
    Chart.pluginService.register(zoom);
  });

  function parseData() {
    const newData = {
      labels: data.map(item =>
        format(item.data_hour.toDate(), dateFormat ? dateFormat : "dd/MM HH'h'")
      ),
      datasets: [
        {
          label: "Temperatura",
          fill: "origin",
          borderColor: "#fff",
          backgroundColor: colors.yellow,
          data: data.map(item => item.avg_temp)
        },
        {
          label: "Umidade",
          borderColor: "#fff",
          backgroundColor: colors.blue,
          fill: "origin",
          data: data.map(item => item.avg_hum)
        }
      ]
    };
    return newData;
  }
  return (
    <Grid className={classes.root}>
      <Line
        height={300}
        data={parseData()}
        options={{
          responsive: true,
          title: {
            display: false
          },
          tooltips: {
            mode: "index"
          },
          hover: {
            mode: "index"
          },
          layout: {
            padding: {
              left: 16,
              right: 16
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: xLabel || "Data e hora"
                }
              }
            ],
            yAxes: [
              {
                //stacked: false,
                scaleLabel: {
                  display: true,
                  labelString: yLabel || "Valores"
                },
                ticks: {
                  suggestedMin: 10,
                  suggestedMax: 40
                }
              }
            ]
          },
          plugins: {
            zoom: {
              // Container for pan options
              pan: {
                // Boolean to enable panning
                enabled: true,

                // Panning directions. Remove the appropriate direction to disable
                // Eg. 'y' would only allow panning in the y direction
                // A function that is called as the user is panning and returns the
                // available directions can also be used:
                //   mode: function({ chart }) {
                //     return 'xy';
                //   },
                mode: "xy",

                rangeMin: {
                  // Format of min pan range depends on scale type
                  x: null,
                  y: null
                },
                rangeMax: {
                  // Format of max pan range depends on scale type
                  x: null,
                  y: null
                },

                // Function called while the user is panning
                onPan: function({ chart }) {},
                // Function called once panning is completed
                onPanComplete: function({ chart }) {}
              },

              // Container for zoom options
              zoom: {
                // Boolean to enable zooming
                enabled: true,

                // Enable drag-to-zoom behavior
                drag: true,

                mode: "xy",

                rangeMin: {
                  // Format of min zoom range depends on scale type
                  x: null,
                  y: null
                },
                rangeMax: {
                  // Format of max zoom range depends on scale type
                  x: null,
                  y: null
                },

                // Speed of zoom via mouse wheel
                // (percentage of zoom on a wheel event)
                speed: 0.1,

                // Function called while the user is zooming
                onZoom: function({ chart }) {},
                // Function called once zooming is completed
                onZoomComplete: function({ chart }) {}
              }
            }
          }
        }}
      />
    </Grid>
  );
}

AreaChart.propTypes = {
  data: PropTypes.array.isRequired,
  dateFormat: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string
};
export default AreaChart;

import React, { Component } from "react";

export default class ExforceMaxLoadingTime extends Component {
  componentDidMount() {
    if (this.props.onTimeout) {
      this.maxTimeTimeout = setTimeout(() => {
        this.props.onTimeout();
      }, this.props.maxTime || 2500);
    }
  }
  componentWillUnmount() {
    if (this.maxTimeTimeout) {
      clearTimeout(this.maxTimeTimeout);
    }
  }
  render() {
    return <React.Fragment />;
  }
}

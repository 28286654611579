import { format } from "date-fns";

export default function(timeRanges) {
  //[{start: jsondate, stop: jsondate}]
  let results = {
    errors: []
  };
  timeRanges.forEach((range, i) => {
    const rangeStart = parseInt(format(new Date(range.start), "HHmmss"), 10);
    const rangeStop = parseInt(format(new Date(range.stop), "HHmmss"), 10);
    timeRanges.forEach((comparingRange, h) => {
      if (h > i) {
        const comparingRangeStart = parseInt(format(new Date(comparingRange.start), "HHmmss"), 10);
        const comparingRangeStop = parseInt(format(new Date(comparingRange.stop), "HHmmss"), 10);
        if (rangeStart < rangeStop) {
          // start stop same day for the 'main' date, the one to be compared against.
          if (comparingRangeStart < comparingRangeStop) {
            // start stop same day for the 'comparing' date
            if (comparingRangeStart > rangeStop || comparingRangeStop < rangeStart) {
              //so if the comparingrange starts and stops in the same day it must either stop before the main range or start after it.
              results.success = true;
            } else {
              results.errors.push(range.id, comparingRange.id);
            }
          } else {
            // start stop other day for the 'comparing' date, there can only be one like this per rule. aka there is only one midnight bro.

            if (comparingRangeStart > rangeStop && comparingRangeStop < rangeStart) {
              //so the comparing gaps the day, it must start, go through midnight and stop sometime tomorrow.
              //for that to happen any other rule must stop before this one starts, and any other rule can only start after this one stops.
              results.success = true;
            } else {
              results.errors.push(range.id, comparingRange.id);
            }
          }
        } else {
          // start stop other day for the 'main' date, there can only be one like this per rule. aka there is only one midnight bro.
          if (comparingRangeStart < comparingRangeStop) {
            //since main rule is day gap,no ther rule can be day gap.
            if (rangeStart > comparingRangeStop && rangeStop < comparingRangeStart) {
              //so the main rule gaps the day, it must start, go through midnight and stop sometime tomorrow.
              //for that to happen any other rule must stop before this one starts, and any other rule can only start after this one stops.
              results.success = true;
            } else {
              results.errors.push(range.id, comparingRange.id);
            }
          } else {
            // so its also day gap, f. off son.
            results.errors.push(range.id, comparingRange.id);
          }
        }
      }
    });
  });
  return results;
}

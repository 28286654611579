import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import uniqid from "uniqid";
const styles = theme => ({
  root: { width: "100%", marginBottom: theme.spacing(2) },
  endAdornment: {
    color: theme.palette.grey[500],
    lineHeight: "2em",
    padding: 0
  },
  helper: {
    marginTop: theme.spacing(1)
  }
});
function TextInput(props) {
  const {
    classes,
    className,
    error,
    onKeyPress,
    inputClasses,
    label,
    type,
    value,
    helper,
    handleChange,
    endAdornment,
    startAdornment
  } = props;
  const inputId = uniqid();
  return (
    <FormControl className={classes.root} error={error}>
      <InputLabel htmlFor={`${inputId}-input-text`}>{label}</InputLabel>
      <FilledInput
        id={`${inputId}-input-text`}
        type={type ? type : "text"}
        value={value}
        onChange={handleChange}
        endAdornment={
          endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : null
        }
        startAdornment={
          startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null
        }
        {...props.InputProps}
        className={className}
        onKeyPress={onKeyPress}
        classes={inputClasses ? { ...inputClasses } : null}
        aria-describedby={inputId + "component-helper-text"}
      />
      {helper ? (
        <FormHelperText
          className={classes.helper}
          id={inputId + "component-helper-text"}
        >
          {helper}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  endAdornment: PropTypes.any,
  startAdornment: PropTypes.any,
  InputProps: PropTypes.object,
  inputClasses: PropTypes.object,
  className: PropTypes.string,
  helper: PropTypes.string
};
export default withStyles(styles)(TextInput);

import autowaterPresetImg from "../assets/preset_autowater.png";
import emersonPresetImg from "../assets/preset_emerson.png";
import customPresetImg from "../assets/preset_custom.png";
import sensorPresetImg from "../assets/sensors.jpg";
import photoperiodPresetImg from "../assets/preset_photoperiod.png";

export const controlPresets = {
  MANUAL: "MANUAL",
  PHOTOPERIOD: "PHOTOPERIOD",
  EMERSON: "EMERSON",
  SENSOR: "SENSOR",
  AUTOWATER: "AUTOWATER",
  CUSTOM: "CUSTOM"
};
export const controlPresetLabels = {
  PHOTOPERIOD_LABEL: "Fotoperíodo",
  EMERSON_LABEL: "Efeito Emerson",
  SENSOR_LABEL: "Controle de Ambiente",
  AUTOWATER_LABEL: "Rega Automática",
  CUSTOM_LABEL: "Customizado"
};
export const controlPresetImgs = {
  PHOTOPERIOD_IMG: photoperiodPresetImg,
  EMERSON_IMG: emersonPresetImg,
  SENSOR_IMG: sensorPresetImg,
  AUTOWATER_IMG: autowaterPresetImg,
  CUSTOM_IMG: customPresetImg
};
export const controlPresetInfo = {
  PHOTOPERIOD_INFO:
    "Configure sua iluminação para agir como Sol, simulando as estações.",
  EMERSON_INFO:
    "Estimule o crescimento das suas plantas oferecendo iluminação especial em horários específicos.",
  SENSOR_INFO: "Crie regras automáticas baseadas na temperatura ou umidade.",
  AUTOWATER_INFO: "Crie regas automáticas utilizando bombas D'Água",
  CUSTOM_INFO:
    "Para os Experts. Crie diversas regras diárias, regras futuras e recorrentes."
};

export const photoperiodPresets = {
  veg: [
    {
      info: "Modo mais comum para plantas no vegetativo e automáticas.",
      label: "18 horas de Sol",
      value: 18,
      recommended: true
    },
    {
      info: "Modo utilizado em alguns casos no vegetativo e automáticas.",
      label: "24 horas de Sol",
      value: 24
    }
  ],
  flower: [
    {
      info: "Modo mais comum e utilizado para plantas na Flora.",
      label: "12 horas de Sol",
      value: 12,
      recommended: true
    }
  ]
};

export const sensorPreset = {
  temp: {
    label: "Temperatura",
    comparator: "Se a temperatura estiver"
  },
  hum: {
    label: "Umidade",
    comparator: "Se a umidade estiver"
  },
  comparators: {
    more_than: {
      value: "more_than",
      label: "Acima de"
    },
    less_than: {
      value: "less_than",
      label: "Abaixo de"
    }
  }
};

export const timeFormatString = "yyyy-MM-dd'T'HH:mm:ss";

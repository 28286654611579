import React from "react";
import { DateTimePicker } from "material-ui-pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        height: "auto",
        padding: "8px 16px",
        paddingBottom: 0
      }
    },
    MuiPickersClock: {
      container: {
        margin: `24px 0 8px`
      }
    }
  },
  palette: {
    primary: {
      main: "#43a047"
    },
    secondary: {
      main: "#8bc34a"
    },
    background: {
      default: "#eee"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.80)"
    },
    common: {
      white: "#fafafa"
    }
  }
});
const DatePicker = props => {
  return (
    <MuiThemeProvider theme={datePickerTheme}>
      <DateTimePicker {...props} />
    </MuiThemeProvider>
  );
};
export default DatePicker;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const styles = theme => {
  return {
    title: {
      fontSize: "1.1rem",
      marginBottom: theme.spacing(1),
      fontWeight: 500
    },
    content: {
      "& span": {
        fontWeight: 500
      },
      "& p": {
        marginBottom: theme.spacing(0.5)
      }
    }
  };
};
const StepContent = props => {
  const { title, classes, children } = props;
  return (
    <div className={classes.root}>
      <Typography component="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography component="div" className={classes.content}>
        {children}
      </Typography>
    </div>
  );
};

StepContent.propTypes = {
  title: PropTypes.string
};

export default withStyles(styles)(StepContent);

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
//redux
import { Provider } from "react-redux";
import { createStore } from "redux";
import "./index.css";
import reducers from "./reducers";

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Leafer = () => (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(<Leafer />, document.getElementById("root"));
serviceWorker.register();

import { messaging, firebase } from "./firebase";
import { db } from "./index";
import * as dbNames from "../constants/db";

export const doAskForPermissioToReceiveNotifications = async userTokens => {
  try {
    await messaging.requestPermission();

    const token = await messaging.getToken();

    const hasToken = userTokens.tokens
      ? userTokens.tokens.includes(token)
      : false;

    if (hasToken) {
      return token;
    }

    await db.doUpdateUserDocInCollection(
      {
        tokens: firebase.firestore.FieldValue.arrayUnion(token),
        email: messaging.app.firebase_.auth().currentUser.email
      },
      dbNames.messagingTokensDb
    );
  } catch (error) {
    return error;
  }
};

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { diaryAppTours } from "../../../../constants/tours";

import handleViewport from "react-in-viewport";
import { DateTime } from "luxon";

const styles = theme => {
  return {
    root: {
      background: theme.palette.common.white
    },
    images: {
      "& img": {
        maxWidth: "100%"
      }
    },
    content: {
      padding: `${theme.spacing(2)}px`,
      marginBottom: 0
    },
    head: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: `${theme.spacing(0.5)}px 0px`,
      marginBottom: theme.spacing(1) + theme.spacing(0.25)
    },
    swipableContainer: {
      position: "relative"
    },
    entryDate: {
      textAlign: "left",
      lineHeight: "1.4em",
      textTransform: "uppercase",
      fontSize: ".666rem",
      fontWeight: 500
    },
    timeAgo: {
      fontSize: ".7rem",
      lineHeight: "1em"
    },
    noImageEntry: {
      background: theme.palette.primary.light,
      height: 60,
      width: "100%"
    },
    entryImageContainer: {},
    entryImage: {
      width: "100%",
      height: 400,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center"
    },
    editButton: {
      padding: theme.spacing(0.25)
    },
    sub: {
      justifyContent: "space-between",
      alignItems: "center",
      width: "auto"
    },
    subText: {
      marginRight: theme.spacing(0.5),
      lineHeight: "1em",
      fontSize: 12,
      textTransform: "uppercase"
    },
    subIcon: {
      fontSize: 16
    },
    iconContainer: {
      maxHeight: 16
    },
    note: {
      padding: `${theme.spacing(1)}px 0px`
    },
    "@media (min-width: 300px) and (max-width: 359px)": {
      entryImage: {
        height: 300
      }
    },
    "@media (min-width: 481px) and (max-width: 600px)": {
      entryImage: {
        height: 500
      }
    },
    "@media (min-width: 601px)": {
      entryImage: {
        height: 700
      }
    }
  };
};

class DiaryEntry extends Component {
  state = { activeStep: 0 };
  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };
  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  //dev todo show some love and move this to its own file for reuse
  timeAgo = date => {
    const now = DateTime.local();
    const past = DateTime.fromJSDate(date);
    const diffTime = now.diff(past, [
      "seconds",
      "minutes",
      "hours",
      "days",
      "months"
    ]);

    const { months, days, minutes, hours } = diffTime.values;
    if (hours > 24) {
      //longer than a day so measure in days
      if (days > 30) {
        //longer than a month

        if (months >= 12) {
          //longer than a year
          const years = months / 12;
          return `a ${years} ${years > 1 ? "anos" : "ano"} atrás`;
        }
        return `a ${months} ${months > 1 ? "meses" : "mês"} atrás`;
      }
      return `a ${days} ${days > 2 ? "dias" : "dia"} atrás`;
    } else {
      //not longer than a day so measure in hours
      if (hours >= 1) {
        return `a ${hours} ${hours > 2 ? "horas" : "hora"} atrás`;
      }
      if (minutes >= 1) {
        //less than a minute
        return `a ${minutes} ${minutes > 2 ? "minutos" : "minuto"} atrás`;
      }
      return "a alguns segundos atrás";
    }
  };
  render() {
    const {
      classes,
      entry,
      onSelect,
      elevation,
      square,
      withTour,
      inViewport
    } = this.props;
    const { activeStep } = this.state;
    let images;
    if (entry.images.length === 0) {
      images = [<div className={classes.noImageEntry} />];
    } else {
      images = entry.images.map(image => {
        return <img src={image} alt="Nota feita no Diário" />;
      });
    }
    return (
      <Paper square={square} className={classes.root} elevation={elevation}>
        <Grid container>
          <Grid
            data-tour={withTour ? diaryAppTours.DIARY_ENTRY_IMAGE : null}
            className={classes.images}
            item
            xs={12}
          >
            <SwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {entry.images.map((img, index) => (
                <div key={index} className={classes.swipableContainer}>
                  <div className={classes.entryImageContainer}>
                    {Math.abs(activeStep - index) <= 1 && inViewport ? (
                      <div
                        className={classes.entryImage}
                        style={{ backgroundImage: `url("${img}")` }}
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </SwipeableViews>
            {images.length < 2 ? null : (
              <MobileStepper
                steps={images.length}
                position="static"
                activeStep={activeStep}
                className={classes.mobileStepper}
                nextButton={
                  <Button
                    size="small"
                    onClick={this.handleNext}
                    disabled={activeStep === images.length - 1}
                  >
                    Próxima
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={this.handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    Anterior
                  </Button>
                }
              />
            )}
          </Grid>
          <Grid container item xs={12} className={classes.content}>
            <Grid className={classes.head} container>
              {entry.createdAt ? (
                <Grid item className={classes.date}>
                  <Typography className={classes.entryDate}>
                    {DateTime.fromJSDate(entry.createdAt.toDate())
                      .setLocale("pt-BR")
                      .toLocaleString(DateTime.DATE_HUGE)}
                  </Typography>

                  <Typography className={classes.timeAgo}>
                    {this.timeAgo(entry.createdAt.toDate())}
                  </Typography>
                </Grid>
              ) : null}

              <ButtonBase
                onClick={onSelect}
                data-tour={withTour ? diaryAppTours.DIARY_EDIT_BTN : null}
                className={classes.editButton}
              >
                <Grid container className={classes.sub}>
                  <Grid item>
                    <Typography className={classes.subText}>editar</Typography>
                  </Grid>
                  <Grid className={classes.iconContainer} item>
                    <EditIcon className={classes.subIcon} />
                  </Grid>
                </Grid>
              </ButtonBase>
            </Grid>
            <Grid item xs={12} className={classes.note}>
              <Typography>{entry.note}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

DiaryEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  withTour: PropTypes.bool
};
export default handleViewport(withStyles(styles)(DiaryEntry));

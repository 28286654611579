import React from "react";

import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import uniqid from "uniqid";

const styles = theme => ({
  formControl: {
    flex: 1
  }
});
const renderOptions = options =>
  options.map(option => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  });

const Select = props => {
  const {
    classes,
    error,
    label,
    onChange,
    defaultValue,
    options,
    tooltip,
    className,
    endAdornment,
    tour
  } = props;
  const selectId = uniqid() + "-select-label";

  return (
    <FormControl
      className={classNames(classes.formControl, className ? className : null)}
      error={error ? true : false}
      data-tour={tour}
    >
      <InputLabel htmlFor={selectId}>{label}</InputLabel>
      <NativeSelect
        onChange={onChange}
        defaultValue={defaultValue}
        input={
          <Input
            id={selectId}
            endAdornment={endAdornment ? endAdornment : null}
          />
        }
      >
        <option value="" />
        {renderOptions(options)}
      </NativeSelect>
      <FormHelperText>{tooltip}</FormHelperText>
    </FormControl>
  );
};
export default withStyles(styles)(Select);

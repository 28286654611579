import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiSwitch from "@material-ui/core/Switch";
const useStyles = makeStyles(theme => {
  return {
    formLabelRoot: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      flexBasis: "100%",
      margin: 0
    },
    formHelperText: {
      margin: 0
    }
  };
});

function Switch(props) {
  const { onChange, label, tooltip, value } = props;
  const classes = useStyles();
  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.formLabelRoot}
        control={
          <MuiSwitch
            checked={value || false}
            onChange={e => onChange(e.target.checked)}
            color="primary"
          />
        }
        label={label}
      />
      <FormHelperText className={classes.formHelperText}>
        {tooltip}
      </FormHelperText>
    </FormGroup>
  );
}

Switch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltop: PropTypes.string
};
export default Switch;

//dev todo deprecate this file and make separte files for all kinds of forms we use in this app

import React from "react";
import { withStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Tags from "react-tagging-input";
import purple from "@material-ui/core/colors/purple";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import "../Forms/TagBox/style.css";
import { TimePicker } from "material-ui-pickers";
import DateTimePicker from "../Forms/Pickers/DateTime";
import uniqid from "uniqid";
const styles = theme => {
  return {
    formRoot: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(4)
    },
    formListContainerRoot: {
      padding: 0
    },
    defaultFormControlLabel: {
      marginLeft: 0,
      marginRight: 0,
      justifyContent: "flex-end",
      flex: 1,
      "& > span:nth-child(2)": {
        flex: 1
      }
    },
    defaultFormSwitch: {
      order: 1
    },
    defaultTextInput: {},
    defaultTextInputFormControl: {
      width: "100%"
    },
    multiListItemRoot: {
      padding: 0,
      marginBottom: theme.spacing(2)
    },
    listItemRoot: {
      padding: 0,
      marginBottom: theme.spacing(2)
    },
    multiListContainerRoot: {
      padding: 0,
      marginBottom: theme.spacing(2)
    },
    multiListItemTextRoot: {
      paddingRight: 0
    },
    multiTextGridItemRoot: {
      marginBottom: theme.spacing(2),
      "&:not(:last-child)": {
        paddingRight: theme.spacing(1)
      }
    },
    singleSwitchTooltip: {
      flexBasis: "100%",
      marginTop: theme.spacing(1) * -1
    },
    colorSwitchBasePurple: {
      color: orange[500],
      "&$colorChecked": {
        color: purple[500],
        "& + $colorBarPurple": {
          backgroundColor: purple[500]
        }
      }
    },
    colorSwitchBaseGreen: {},
    colorSwitchBaseBlueOrange: {
      color: orange[500],
      "&$colorChecked": {
        color: blue[500],
        "& + $colorBarBlueOrange": {
          backgroundColor: blue[500]
        }
      }
    },
    colorBarPurple: { backgroundColor: orange[500] },

    colorBarBlueOrange: { backgroundColor: orange[500] },
    colorChecked: {}
  };
};
function generateListItems(fields, classes) {
  return fields.map(field => {
    const totalGridCols = 12;

    switch (field.formType) {
      case "switch":
        if (field.multiple) {
          const gridItems = field.name.map((name, i) => {
            return (
              <Grid
                className={classes.multiTextGridItemRoot}
                item
                key={name + field.dataProp}
                xs={totalGridCols / field.totalInstances}
              >
                <ListItem
                  className={classes.multiListItemRoot}
                  key={name + field.dataProp}
                >
                  <ListItemText
                    className={classes.multiListItemTextRoot}
                    primary={
                      <FormGroup row>
                        <FormControlLabel
                          className={classes.defaultFormControlLabel}
                          control={
                            <Switch
                              className={classes.defaultFormSwitch}
                              checked={field.initialState[i]}
                              onChange={field.onChange}
                              inputProps={{ [field.dataProp]: name }}
                              classes={
                                field.extraClasses
                                  ? {
                                      switchBase:
                                        classes[
                                          field.extraClasses[i].switchBase
                                        ],
                                      checked: classes.colorChecked,
                                      bar: classes[field.extraClasses[i].bar]
                                    }
                                  : {}
                              }
                            />
                          }
                          label={field.label[i]}
                        />
                        <FormHelperText
                          style={field.tooltipStyle ? field.tooltipStyle : null}
                          className={classes.singleSwitchTooltip}
                        >
                          {field.tooltip ? field.tooltip[i] : null}
                        </FormHelperText>
                      </FormGroup>
                    }
                  />
                </ListItem>
              </Grid>
            );
          });
          return (
            <Grid
              data-tour={field.tour}
              className={classes.multiListContainerRoot}
              key={`multiInputContainer-${field.name}`}
              container
            >
              {gridItems}
            </Grid>
          );
        }
        return (
          <ListItem
            className={classes.listItemRoot}
            data-tour={field.tour}
            key={field.name}
          >
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <FormGroup row>
                  <FormControlLabel
                    className={classes.defaultFormControlLabel}
                    control={
                      <Switch
                        className={classes.defaultFormSwitch}
                        classes={
                          field.extraClasses
                            ? {
                                switchBase:
                                  classes[field.extraClasses.switchBase],
                                checked: classes.colorChecked,
                                bar: classes[field.extraClasses.bar]
                              }
                            : {}
                        }
                        checked={field.initialState}
                        onChange={field.onChange}
                        inputProps={{ [field.dataProp]: field.name }}
                      />
                    }
                    label={field.label}
                  />
                  <FormHelperText className={classes.singleSwitchTooltip}>
                    {field.tooltip}
                  </FormHelperText>
                </FormGroup>
              }
            />
          </ListItem>
        );
      case "text":
        if (field.multiple) {
          const gridItems = field.name.names.map((name, i) => {
            return (
              <Grid
                className={classes.multiTextGridItemRoot}
                item
                key={name + field.dataProp}
                xs={totalGridCols / field.totalInstances}
              >
                <ListItem className={classes.multiListItemRoot} key={name + i}>
                  <ListItemText
                    className={classes.multiListItemTextRoot}
                    primary={
                      <FormControl
                        className={classes.defaultTextInputFormControl}
                      >
                        <InputLabel htmlFor={field.idForLabel + name}>
                          {field.label[i]}
                        </InputLabel>
                        {
                          //dev todo fix hardcoded prop, maybe fix how data is passed between form input and state keepers or simply adopt the multi data-paramenter method.
                        }
                        <Input
                          className={classes.defaultTextInput}
                          id={field.idForLabel + name}
                          type={field.type ? field.type : "text"}
                          value={field.initialState[i]}
                          fullWidth={true}
                          onChange={field.onChange}
                          inputProps={{
                            [field.dataProp]: field.name.root,
                            "data-setupgrowdimensao": name
                          }}
                        />
                        <FormHelperText id={`${name}-helper-text`}>
                          {field.tooltip[i]}
                        </FormHelperText>
                      </FormControl>
                    }
                  />
                </ListItem>
              </Grid>
            );
          });
          return (
            <Grid
              className={classes.multiListContainerRoot}
              key={`multiInputContainer-${field.name.root}`}
              container
            >
              {gridItems}
            </Grid>
          );
        }
        return (
          <ListItem className={classes.listItemRoot} key={field.name}>
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <FormControl
                  className={classes.defaultTextInputFormControl}
                  error={field.error ? true : false}
                >
                  <InputLabel htmlFor={field.idForLabel}>
                    {field.label}
                  </InputLabel>
                  <Input
                    className={classes.defaultTextInput}
                    id={field.idForLabel}
                    type={field.type ? field.type : "text"}
                    value={field.initialState}
                    fullWidth={true}
                    onChange={field.onChange}
                    inputProps={
                      field.inputExtraProps
                        ? {
                            [field.dataProp]: field.name,
                            ...field.inputExtraProps
                          }
                        : {
                            [field.dataProp]: field.name
                          }
                    }
                  />
                  <FormHelperText id={`${field.name}-helper-text`}>
                    {field.tooltip}
                  </FormHelperText>
                </FormControl>
              }
            />
          </ListItem>
        );
      case "text:datetime":
        return (
          <ListItem key={field.name} className={classes.multiListItemRoot}>
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <DateTimePicker
                  ampm={false}
                  onChange={field.onChange}
                  label={field.label}
                  value={field.initialState}
                  {...field.extraProps}
                />
              }
            />
          </ListItem>
        );
      case "text:time":
        return (
          <ListItem key={field.name} className={classes.multiListItemRoot}>
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <TimePicker
                  ampm={false}
                  label={field.label}
                  value={field.initialState}
                  onChange={field.onChange}
                  {...field.extraProps}
                />
              }
            />
          </ListItem>
        );
      case "text:multiline":
        return (
          <ListItem
            data-tour={field.tour}
            key={field.name}
            className={classes.multiListItemRoot}
          >
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <FormControl
                  error={field.error ? true : false}
                  className={classes.defaultTextInputFormControl}
                >
                  <TextField
                    label={field.label}
                    multiline
                    rowsMax={field.rowsMax}
                    rows={field.rows}
                    value={field.initialState}
                    fullWidth={true}
                    onChange={field.onChange}
                    error={field.error ? true : false}
                    inputProps={
                      field.inputExtraProps
                        ? {
                            [field.dataProp]: field.name,
                            ...field.inputExtraProps
                          }
                        : {
                            [field.dataProp]: field.name
                          }
                    }
                    margin="none"
                  />
                  <FormHelperText>{field.tooltip}</FormHelperText>
                </FormControl>
              }
            />
          </ListItem>
        );
      case "select":
        //dev todo not options but MenuItems?
        if (field.multiple) {
          const gridItems = field.fieldList.map((newfield, i) => {
            const options = newfield.options.map(option => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            });
            return (
              <Grid
                className={classes.multiTextGridItemRoot}
                item
                key={newfield.name + newfield.dataProp}
                xs={totalGridCols / field.totalInstances}
              >
                <ListItem className={classes.listItemRoot} key={newfield.name}>
                  <FormControl className={classes.defaultTextInputFormControl}>
                    <InputLabel
                      htmlFor={`${newfield.name}-medium-native-helper`}
                    >
                      {newfield.label}
                    </InputLabel>
                    <NativeSelect
                      onChange={newfield.onChange}
                      defaultValue={newfield.value}
                      input={
                        <Input
                          inputProps={{ [newfield.dataProp]: newfield.name }}
                          id={`${newfield.name}-medium-native-helper`}
                        />
                      }
                    >
                      <option value="" />
                      {options}
                    </NativeSelect>
                    <FormHelperText>{newfield.tooltip}</FormHelperText>
                  </FormControl>
                </ListItem>
              </Grid>
            );
          });
          return (
            <Grid
              data-tour={field.tour}
              className={classes.multiListContainerRoot}
              key={`multiInputContainer-multiselect${field.type}${
                field.totalInstances
              }`}
              container
            >
              {gridItems}
            </Grid>
          );
        } else {
          const options = field.options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          });
          return (
            <ListItem
              data-tour={field.tour}
              className={classes.listItemRoot}
              key={field.name}
            >
              <FormControl
                className={classes.defaultTextInputFormControl}
                error={field.error ? true : false}
              >
                <InputLabel htmlFor="age-native-helper">
                  {field.label}
                </InputLabel>
                <NativeSelect
                  onChange={field.onChange}
                  defaultValue={field.selected ? field.value : ""}
                  input={
                    <Input
                      inputProps={{
                        [field.dataProp]: field.name
                      }}
                      endAdornment={
                        field.endAdornment ? field.endAdornment : null
                      }
                      id={`${field.name}-medium-native-helper`}
                    />
                  }
                >
                  <option value="" />
                  {options}
                </NativeSelect>
                <FormHelperText>{field.tooltip}</FormHelperText>
              </FormControl>
            </ListItem>
          );
        }
      case "tagbox":
        return (
          <ListItem
            data-tour={field.tour}
            className={classes.listItemRoot}
            key={field.name}
          >
            <ListItemText
              className={classes.multiListItemTextRoot}
              primary={
                <FormControl
                  className={classes.defaultTextInputFormControl}
                  error={field.error ? true : false}
                >
                  <Tags
                    tags={field.initialState}
                    placeholder={field.placeholder}
                    onAdded={field.onChange}
                    onRemoved={field.onTagDelete}
                    addKeys={[13, 9, 188]}
                    id={field.name}
                    uniqueTags={true}
                  />
                  <FormHelperText>{field.tooltip}</FormHelperText>
                </FormControl>
              }
            />
          </ListItem>
        );
      case "v2":
        return <React.Fragment key={uniqid()}>{field.content}</React.Fragment>;
      default:
        return <div key="amacing" />;
    }
  });
}
function FormListGenerator(props) {
  const { classes, extraRootClass } = props;
  return (
    <div style={extraRootClass} className={classes.formRoot}>
      <List className={classes.formListContainerRoot} component="nav">
        {generateListItems(props.fields, classes)}
      </List>
    </div>
  );
}
export default withStyles(styles)(FormListGenerator);

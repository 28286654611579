import { functions } from "./firebase";

const helpersUiManualFetchSensor = functions.httpsCallable(
  "helpersUiManualFetchSensor"
);
const authAccountOnCreate = functions.httpsCallable("authAccountOnCreate");
const diaryAppEntryOnAddEdit = functions.httpsCallable(
  "diaryAppEntryOnAddEdit"
);

//UI Controls funcs

const uiControlsAppSaveManualRule = functions.httpsCallable(
  "uiControlsAppSaveManualRule"
);
const uiControlsAppSavePhotoperiodRule = functions.httpsCallable(
  "uiControlsAppSavePhotoperiodRule"
);
const uiControlsAppSaveEmersonRule = functions.httpsCallable(
  "uiControlsAppSaveEmersonRule"
);
const uiControlsAppSaveSensorRule = functions.httpsCallable(
  "uiControlsAppSaveSensorRule"
);
const uiControlsAppSaveAutowaterRule = functions.httpsCallable(
  "uiControlsAppSaveAutowaterRule"
);
const uiControlsAppSaveCustomFreqRule = functions.httpsCallable(
  "uiControlsAppSaveCustomFreqRule"
);
const uiControlsAppUpdatePreset = functions.httpsCallable(
  "uiControlsAppUpdatePreset"
);
const uiControlsAppSaveCustomDailyRule = functions.httpsCallable(
  "uiControlsAppSaveCustomDailyRule"
);
export async function saveManualRule(data) {
  const results = await uiControlsAppSaveManualRule(data);
  return results;
}
export async function savePhotoperiodRule(data) {
  const results = await uiControlsAppSavePhotoperiodRule(data);
  return results;
}
export async function saveEmersonRule(data) {
  const results = await uiControlsAppSaveEmersonRule(data);
  return results;
}
export async function saveSensorRule(data) {
  const results = await uiControlsAppSaveSensorRule(data);
  return results;
}
export async function saveAutowaterRule(data) {
  const results = await uiControlsAppSaveAutowaterRule(data);
  return results;
}
export async function saveCustomFreqRule(data) {
  const results = await uiControlsAppSaveCustomFreqRule(data);
  return results;
}
export async function saveCustomDailyRule(data) {
  const results = await uiControlsAppSaveCustomDailyRule(data);
  return results;
}
export async function updateControlPreset(data) {
  const results = await uiControlsAppUpdatePreset(data);
  return results;
}
// end UI controls save funcs
//pendingDevices
const uiDashboardManagePendingDevices = functions.httpsCallable(
  "uiDashboardManagePendingDevices"
);
export async function managePendingDevices(data) {
  const results = await uiDashboardManagePendingDevices(data);
  return results;
}
//end pending devices
//pending accesories
const uiDashboardManagePendingAccessories = functions.httpsCallable(
  "uiDashboardManagePendingAccessories"
);
export async function managePendingAccessories(data) {
  const results = await uiDashboardManagePendingAccessories(data);
  return results;
}
//end pending accesories
export const getSensorUpdate = (deviceId) => {
  helpersUiManualFetchSensor({ deviceId })
    .then(function (result) {
      return true;
    })
    .catch((error) => {
      return false;
    });
};
export const onAccountCreation = async (data) => authAccountOnCreate(data);

export const onAddEditDiaryEntry = (entry) => {
  return diaryAppEntryOnAddEdit(entry)
    .then((results) => {
      return results;
    })
    .catch((error) => {
      return error;
    });
};
